import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useAuthToken } from "../../hooks/useAuthToken";
import { editUsers } from "../../api/users";
import { useFlowInfo } from "../../hooks/useFlowInfo";

interface UpdateUserSummaryProps {
  // query key to refetch after mutation is completed
  refetchKey: string[];

  // flow id
  flowId: string;
}

export const useUpdateUserSummary = ({
  flowId,
  refetchKey,
}: UpdateUserSummaryProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const flowInfo = useFlowInfo(flowId);
  const { getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["update", ...refetchKey],
    mutationFn: async ({
      sessionId,
      data,
    }: {
      sessionId: string;

      //   data to update
      data: { [attributeKey: string]: any };
    }) => {
      const token = localStorage.getItem('token')

      const parseData = {};

      Object.keys(data).forEach((key) => {
        if (flowInfo.attributes[key])
          parseData[flowInfo.attributes[key]] = data[key];
        else parseData[key] = data[key];
      });

      const response = await editUsers({
        flowId,
        token,
        data: parseData,
        action: "update",
        filters: [{ key: "_id", operation: "eq", value: sessionId }],
      });

      if (response) {
        toast({ title: "User data updated successfully", status: "success" });
      }

      return response;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to update user",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
