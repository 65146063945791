import React, { forwardRef } from "react";
import { BoxProps } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";

interface WhatsappMessageProps extends BoxProps {
  text: string;
}

const Paragraph = {
  P: styled('p')(({ theme }) => ({
    whiteSpace: 'pre-wrap',
  })),
};

export const WhatsappMessageText = forwardRef<
  HTMLParagraphElement,
  WhatsappMessageProps
>(({ text, ...props }, _) => (
  <ReactMarkdown
    {...props}
    components={{
      p({ className, children, ...props }) {
        return <Paragraph.P className={className}>{children}</Paragraph.P>;
      },
    }}
  >
    {text.replace(/\*([^*]+)\*/g, "**$1**")}
  </ReactMarkdown>
));
