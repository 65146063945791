import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Stack,
  Text,
  Wrap,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import StatusTag from "./Status";
import { CloseIcon } from "../constants/icons";

export interface TagViewProps {
  children?: React.ReactNode;
  tags?: string | string[] | any[]; // default tags
  options?: string[]; // options to select from
  multiple?: boolean;
  allowEditing?: boolean;
  allowNewTag?: boolean;
  placeholder?: string;
  closeOnSelect?: boolean;
  onNewTag?: (newTag: string) => void;
  onChange?: (tags: string | string[]) => void;
}

export const TagGroup = forwardRef<HTMLDivElement, TagViewProps>(
  (
    {
      tags: defaultSelected = [],
      options = [],
      multiple = false,
      allowEditing = false,
      allowNewTag = true,
      closeOnSelect = true,
      onNewTag,
      onChange,
      placeholder,
      children,
    },
    _
  ) => {
    const inputRef = useRef();

    // keeps track of selected options
    const [selectedTags, setSelectedTags] = useState<string[]>(
      typeof defaultSelected === "string" ? [defaultSelected] : defaultSelected
    );

    // all the options to select from
    const [tags, setTags] = useState<string[]>(options);

    // input query to search for options
    const [query, setQuery] = useState<string>("");

    // search results based on query
    const queryResults =
      query.length !== 0
        ? tags.filter((tag) =>
            tag.toLocaleLowerCase().includes(query.toLocaleLowerCase())
          )
        : tags;

    /**
     * handles keyboard events
     * @param key - key pressed
     */
    const handleKeyboard = (key: string, onClose?: () => void) => {
      if (key === "Backspace") {
        if (query.length === 0) {
          const previousTags = selectedTags.filter(
            (_, i) => i !== selectedTags.length - 1
          );
          setSelectedTags(previousTags);
          onChange?.(previousTags);
        }
      }

      if (key === "Enter") {
        if (query.length !== 0) {
          if (queryResults.length === 0) addNewTag(() => onClose?.());
          else selectTag(queryResults[0], () => onClose?.());
        }
      }
    };

    /**
     * filters out selected tag from options and adds it to selected tags
     * also calls onChange callback with selected tags
     * @param selectedTag - tag to select
     */
    const selectTag = (selectedTag: string, onClose?: () => void) => {
      // appends the selected tag to the end of the list
      if (multiple) {
        let previousTags = selectedTags.filter((tag) => tag !== selectedTag);
        previousTags.push(selectedTag);

        setSelectedTags(previousTags);
        onChange?.(previousTags);
      }
      // replaces the selected tag with the new one
      else {
        setSelectedTags([selectedTag]);
        onChange?.(selectedTag);
      }

      if (closeOnSelect) {
        onClose?.();
      }
      setQuery("");
    };

    const unSelectTag = (selectedTag: string) => {
      // removes the selected tag from the list
      let previousTags = selectedTags.filter((tag) => tag !== selectedTag);
      setSelectedTags(previousTags);
      onChange?.(previousTags);
    };

    /**
     * adds a new tag to the list of tags
     */
    const addNewTag = (onClose?: () => void) => {
      onNewTag?.(query);
      setTags((tags) => [...tags, query]);
      selectTag(query);
      if (closeOnSelect) onClose?.();
    };

    useEffect(() => {
      setSelectedTags(
        typeof defaultSelected === "string"
          ? [defaultSelected]
          : defaultSelected
      );

      return () => {
        setSelectedTags([]);
      };
    }, [defaultSelected]);

    useEffect(() => {
      setTags(options);

      return () => {
        setTags([]);
      };
    }, [options]);

    return (
      <Popover
        initialFocusRef={inputRef}
        trigger="click"
        placement="bottom-start"
      >
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Stack>
                {children ? (
                  <Stack spacing={0}>{children}</Stack>
                ) : (
                  <Wrap
                    minW="-webkit-max-content"
                    {...(allowEditing ? { as: "button" } : {})}
                  >
                    {children}
                    {!children && selectedTags.length === 0 && placeholder && (
                      <Text color="GrayText">{placeholder}</Text>
                    )}
                    {!children &&
                      selectedTags.map((v, index) => (
                        <StatusTag showTooltip={false} tag={v} key={index} />
                      ))}
                  </Wrap>
                )}
              </Stack>
            </PopoverTrigger>
            <Portal>
              <PopoverContent border="none" zIndex="9999">
                <PopoverBody
                  as={Stack}
                  className="border border-[#F2F4F7] rounded-md p-0"
                  boxShadow="0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)"
                >
                  <Wrap
                    bg="gray.50"
                    borderRadius="md"
                    p={2}
                    cursor="text"
                    direction="row"
                  >
                    {selectedTags.map((tag, idx) => (
                      <StatusTag
                        key={`selected_tag_${tag}_${idx}`}
                        showTooltip={false}
                        tag={tag}
                        onClick={() => unSelectTag(tag)}
                        rightIcon={
                          <CloseIcon
                            onClick={() => unSelectTag(tag)}
                            size="24"
                          />
                        }
                      />
                    ))}
                    <Input
                      w={"fit-content"}
                      bg="transparent !important"
                      placeholder="Search..."
                      value={query}
                      size="sm"
                      ref={inputRef}
                      onChange={(e) => setQuery(e.currentTarget.value)}
                      onKeyDown={(event) =>
                        handleKeyboard(event.key, () => onClose())
                      }
                      variant="unstyled"
                    />
                  </Wrap>
                  <Box className={`h-64 bg-white overflow-y-scroll`}>
                    <Box>
                      {queryResults.map((tag, idx) => (
                        <Box
                          px={2}
                          py={1}
                          key={`choose_tag_${tag}_${idx}`}
                          onClick={() => selectTag(tag, () => onClose())}
                          className="rounded hover:bg-[#F2F4F7] hover:cursor-pointer"
                        >
                          <StatusTag tag={tag} />
                        </Box>
                      ))}
                    </Box>

                    {/* if search query doesn't match */}

                    {allowNewTag && query && !tags.includes(query) && (
                      <Stack
                        onClick={() => addNewTag(() => onClose())}
                        borderRadius="md"
                        p={1}
                        _hover={{ bg: "gray.100" }}
                        direction="row"
                      >
                        <Text>create</Text> <StatusTag tag={query} />
                      </Stack>
                    )}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  }
);
