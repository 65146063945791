import React, { useState, useEffect, forwardRef } from "react";
import { Stack } from "@chakra-ui/react";
import { SelectionTag, ActiveTag, TagOperation, TagStackProps } from "./index";

import { FilterQuery } from "../Filter/models";
import { CloseIcon } from "../../constants/icons";
import { Button } from "../Button";

export const TagStack = forwardRef<HTMLDivElement, TagStackProps>(
  (
    {
      filterKey = "data.tags",
      variant = "tag",
      behaviour = "sequence",
      tags = {},
      onTagChange,
      ...props
    },
    ref
  ) => {
    const [tagFilters, setTagFilters] = useState<{
      [tag: string]: TagOperation;
    }>({});

    const addTagFilter = (tag: string, type: TagOperation | "x") => {
      if (type === "+") {
        return setTagFilters((prevFilters) => ({ ...prevFilters, [tag]: "+" }));
      }

      if (type === "-") {
        return setTagFilters((prevFilters) => ({ ...prevFilters, [tag]: "-" }));
      }

      return setTagFilters((prevFilters) => {
        const copy = { ...prevFilters };

        delete copy[tag];

        return copy;
      });
    };

    useEffect(() => {
      const generateFilterQueries = (tags: {
        [tag: string]: TagOperation;
      }): FilterQuery[] => {
        if (Object.keys(tags).length === 0) return [];
        let includes = [];
        let excludes = [];

        Object.keys(tags).map((tag, index) => {
          if (tags[tag] === "+") includes.push(tag);
          if (tags[tag] === "-") excludes.push(tag);
        });

        let result: FilterQuery[] = [];
        if (includes.length !== 0)
          result.push({
            key: filterKey,
            operation: "in",
            value: includes,
          });

        if (excludes.length !== 0)
          result.push({
            key: filterKey,
            operation: "nin",
            value: excludes,
          });

        return result;
      };

      onTagChange?.(generateFilterQueries(tagFilters));
    }, [tagFilters]);

    return (
      <Stack
        ref={ref}
        my={2}
        w="full"
        direction="row"
        transition="all 0.5s cubic-bezier(0.01, 1, 0.85, 1) 0s"
        align="stretch"
        justify="stretch"
        spacing={0}
        // hidden={Object.keys({...tags, ...tagFilters}).length === 0}
        {...props}
      >
        {/* Selected Filters */}
        <Stack
          direction="row"
          spacing={1}
          {...props}
          // spacing="-28" role="group"
        >
          {Object.keys(tagFilters).map((tag, index) => (
            <ActiveTag
              key={`active-${index}`}
              tag={tag}
              count={tags[tag]}
              operation={tagFilters[tag]}
              onInclude={() => addTagFilter(tag, "+")}
              onExclude={() => addTagFilter(tag, "-")}
              onRemove={() => addTagFilter(tag, "x")}
              variant={variant}
              _groupHover={{ marginInlineStart: ".25rem" }}
            />
          ))}
        </Stack>
        {/* Available Filters */}
        <Stack
          spacing={1}
          direction="row"
          position="relative"
          overflowX="scroll"
          className="hidden-scrollbar"
          {...props}
        >
          {Object.keys(tags).map((tag, index) => (
            <SelectionTag
              key={`select-${index}`}
              tag={tag}
              count={tags[tag]}
              variant={variant}
              onInclude={() => addTagFilter(tag, "+")}
              // onExclude={() => addTagFilter(tag, "-")}
              hidden={Object.keys(tagFilters).includes(tag)}
            />
          ))}
        </Stack>

        {/* Clear All */}
        <Stack hidden={Object.keys(tagFilters).length === 0} flex={1}>
          <Button
            alignSelf="self-end"
            variant="tertiary"
            rightIcon={<CloseIcon />}
            onClick={() => setTagFilters({})}
          >
            Clear All
          </Button>
        </Stack>
      </Stack>
    );
  }
);
