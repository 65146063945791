import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Switch,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  SliderMark,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  getKnowledgebaseFlags,
  updateAgentCopy,
  updateMediaTransfer,
  updateOutOfOfficeCopy,
  updateOutOfOfficeHours,
  updateThreshold,
} from "../../../../api/engine";
import { Button } from "../../../../components/Button";
import ForeverProgress from "../../../../components/Feedback/Progress";

class APIError extends Error {}

export const General = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [mediaTransferEnabled, setMediaTransferEnabled] =
    useState<boolean>(false);
  const [agentCopy, setAgentCopy] = useState<string | null>(null);
  const [outOfOfficeHoursCopy, setOutOfOfficeHoursCopy] = useState<string>("");
  const [outOfOfficeHours, setOutOfOfficeHours] = useState<string[]>([]);
  const [isInitialUpdate, setIsInitialUpdate] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [threshold, setThreshold] = useState<number>(0);

  const handleOutOfOfficeHours = (index: number, value: string) => {
    const newOutOfOfficeHours = [...outOfOfficeHours];
    newOutOfOfficeHours[index] = value;
    setOutOfOfficeHours(newOutOfOfficeHours);
  };

  const setMediaTransfer = async (): Promise<boolean> => {
    try {
      const update = await updateMediaTransfer(
        flowId,
        mediaTransferEnabled
      ).catch((_) => {
        console.log("error in setting media transfer");
        throw new APIError();
      });
      if (update) return true;
    } catch (error) {
      console.log(error);
      throw new APIError();
    }
  };

  const setAgentMessage = async (): Promise<boolean> => {
    try {
      const update = await updateAgentCopy(flowId, agentCopy).catch((_) => {
        console.log("error in setting agent message");
        throw new APIError();
      });
      if (update) return true;
    } catch (error) {
      console.log(error);
      throw new APIError();
    }
  };

  const setflowThreshold = async (): Promise<boolean> => {
    try {
      const update = await updateThreshold(flowId, threshold).catch((_) => {
        console.log("error in setting agent message");
        throw new APIError();
      });
      if (update) return true;
    } catch (error) {
      console.log(error);
      throw new APIError();
    }
  };

  const setOutOfficeTimings = async (): Promise<boolean> => {
    try {
      const update = await updateOutOfOfficeHours(
        flowId,
        outOfOfficeHours
      ).catch((_) => {
        console.log("error in setting agent message");
        throw new APIError();
      });
      if (update) return true;
    } catch (error) {
      console.log(error);
      throw new APIError();
    }
  };

  const setOutOfficeCopy = async (): Promise<boolean> => {
    try {
      const update = await updateOutOfOfficeCopy(
        flowId,
        outOfOfficeHoursCopy
      ).catch((_) => {
        console.log("error in setting out of office message");
        throw new APIError();
      });
      if (update) return true;
    } catch (error) {
      console.log(error);
      throw new APIError();
    }
  };

  const saveChanges = async () => {
    try {
      let result = await Promise.all([
        setAgentMessage(),
        setMediaTransfer(),
        setOutOfficeTimings(),
        setOutOfficeCopy(),
        setflowThreshold(),
      ]);
      if (result) {
        console.log(result);
        getLatestFlags();
        toast({
          title: "Changes saved.",
          description: "may take up to 60 seconds to reflect",
          status: "success",
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed to save changes",
        status: "error",
      });
    }
  };

  const getLatestFlags = async () => {
    const handleErrorFlags = () => {
      toast({
        title: "Error fetching flags",
        status: "error",
      });
    };

    try {
      setLoading(true);
      const flags = await getKnowledgebaseFlags(flowId).catch((_) =>
        handleErrorFlags()
      );

      if (flags) {
        setMediaTransferEnabled(flags.enable_transfer_for_media_messages);
        setAgentCopy(flags.static_agent_transfer_override_message);
        setOutOfOfficeHours(flags.out_of_office_hours);
        setOutOfOfficeHoursCopy(
          flags.out_of_office_hours_custom_agent_transfer_message
        );
        setThreshold(flags.search_threshold ?? 0.5);
      }
    } catch (error) {
      handleErrorFlags();
    }

    setLoading(false);
    return setIsInitialUpdate(true);
  };

  /**
   * fetch the flags
   */
  useEffect(() => {
    getLatestFlags();
  }, []);

  useEffect(() => {
    if (isInitialUpdate) setHasChanges(true);
    else setHasChanges(false);
  }, [mediaTransferEnabled, agentCopy, outOfOfficeHoursCopy, outOfOfficeHours]);

  return (
    <Stack className="px-8 py-4 m-0 h-screen w-full">
      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="md">
            Support
          </Heading>
          <Text>Manage your support preferences</Text>
        </Box>
        <Button isDisabled={!hasChanges} onClick={() => saveChanges()}>
          Save Changes
        </Button>
      </HStack>
      <Box>
        <Divider />
        {loading && <ForeverProgress size="sm" />}
      </Box>
      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="sm">
            Media Transfer
          </Heading>
          <Text>Assign agent when user sends a media</Text>
        </Box>
        <Switch
          isChecked={mediaTransferEnabled}
          onChange={(event) =>
            setMediaTransferEnabled(event.currentTarget.checked)
          }
        />
      </HStack>

      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="sm">
            Agent transfer copy
          </Heading>
          <Text>
            Customise the message to be sent when a user requests to speak to an
            agent
          </Text>
        </Box>
        <Switch
          isChecked={agentCopy !== null}
          onChange={(event) =>
            event.currentTarget.checked ? setAgentCopy("") : setAgentCopy(null)
          }
        />
      </HStack>
      <Stack hidden={agentCopy === null}>
        <Alert status="warning">
          <AlertIcon />
          Note: This will override the default and out of office message.
        </Alert>
        <Input
          value={agentCopy}
          placeholder="Enter the message"
          onChange={(event) => setAgentCopy(event.currentTarget.value)}
        />
      </Stack>

      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="sm">
            Out of office hours
          </Heading>
          <Text>
            Manage your out of office hours and set up an auto response
          </Text>
        </Box>
        <Switch
          isChecked={outOfOfficeHours.length === 2}
          onChange={(event) =>
            event.currentTarget.checked
              ? setOutOfOfficeHours(["", ""])
              : setOutOfOfficeHours([])
          }
        />
      </HStack>

      <Stack hidden={outOfOfficeHours.length !== 2}>
        <HStack w="full">
          <InputGroup>
            <InputLeftAddon children="From" />
            <Input
              type={"time"}
              flex={1}
              value={outOfOfficeHours[0]}
              placeholder="Enter the message"
              borderRadius="0 !important"
              borderRightRadius="0.375rem !important"
              onChange={(event) =>
                handleOutOfOfficeHours(0, event.currentTarget.value)
              }
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="To" />
            <Input
              type={"time"}
              flex={1}
              value={outOfOfficeHours[1]}
              placeholder="Enter the message"
              borderRadius="0 !important"
              borderRightRadius="0.375rem !important"
              onChange={(event) =>
                handleOutOfOfficeHours(1, event.currentTarget.value)
              }
            />
          </InputGroup>
        </HStack>

        <Input
          value={outOfOfficeHoursCopy}
          placeholder="Enter the message"
          onChange={(event) =>
            setOutOfOfficeHoursCopy(event.currentTarget.value)
          }
        />
      </Stack>
      <Stack>
        <Box py={2}>
          <Heading py={1} size="sm">
            Threshold Value
          </Heading>
          <Text>Set the threshold value between 0 and 1</Text>
        </Box>
        <Slider
          colorScheme="pink"
          value={threshold}
          min={0}
          max={1}
          step={0.1}
          onChange={(value) => setThreshold(value)}
        >
          <SliderMark
            value={threshold}
            borderRadius="md"
            textAlign="center"
            bg="pink.500"
            color="white"
            mt="5"
            ml="-5"
            w="12"
          >
            {threshold}
          </SliderMark>
          <SliderTrack>
            <Box position="relative" right={10} />
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6} />
        </Slider>
      </Stack>
    </Stack>
  );
};
