import React from "react";
import { Box, HStack, Stack, Text, Image, SimpleGrid } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Appbar } from "../../../components/Appbar";
import AddMicroFlow from "./AddMicroFlow";
import AddNumber from "./AddNumber";
import AddTemplate from "./AddTemplate";
import oneIcon from "../../../img/one.svg";
import twoIcon from "../../../img/two.svg";
import threeIcon from "../../../img/three.svg";
import { Button } from "../../../components/Button";

const AddNew = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;

  return (
    <Box className="w-full h-[100vh]" bg="white">
      <Appbar
        px={8}
        title="Add New"
        borderBottomWidth="thin"
        boxShadow="sm"
        trailing={<Button visibility="hidden">New</Button>}
      />
      <Box className="w-full px-4 py-2 ">
        <Box className="p-2 mb-4 w-full">
          <HStack className="p-4 w-full rounded-lg border border-gray-300 bg-gray-50 items-center justify-between">
            <Stack className="flex-col items-start p-2">
              <p className="font-medium text-base text-left">
                Things to consider while adding number to your 360Dialog
                account:
              </p>
              <HStack>
                <Image src={oneIcon} />
                <Text className="text-left text-base text-gray-800">
                  a valid phone number to receive the OTP
                </Text>
              </HStack>
              <HStack>
                <Image src={twoIcon} />
                <Text className="text-left text-base text-gray-800">
                  access to your Meta Business account
                </Text>
              </HStack>
              <HStack>
                <Image src={threeIcon} />
                <Text className="text-left text-base text-gray-800">
                  and of course, some money in your account 💸
                </Text>
              </HStack>
            </Stack>
            <Stack className="flex-col w-[30%] p-4 bg-white rounded-lg items-start border border-gray-300">
              <p className="text-base text-left font-medium">
                Connect 360Dialog Account
              </p>
              <p className="text-base text-left text-gray-500">
                Click to register your 360Dialog account with us
              </p>
              <AddNumber flowId={flowId} />
            </Stack>
          </HStack>
        </Box>
        <SimpleGrid p={2} templateColumns="repeat(2, 1fr)" gridGap="8">
          <Box className="p-4 rounded-lg border border-gray-300" bg="gray.50">
            <AddMicroFlow flowId={flowId} />
          </Box>
          <Box className="p-4 rounded-lg border border-gray-300" bg="gray.50">
            <AddTemplate flowId={flowId} />
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default AddNew;
