import { useContext } from "react";
import { AuthContext, AuthData } from "../store/authContext";

interface AuthToken {
  user?: AuthData;
  getAuthToken: () => Promise<string | undefined>;
}

export const useAuthToken = (): AuthToken => {
  const {
    user,
  } = useContext(AuthContext)

  const fetchToken = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        return token;
      }
    } catch (error) { }
  };

  return { user, getAuthToken: fetchToken };
};
