import React, { forwardRef, useEffect, useRef } from "react";
import {
  useDisclosure,
  Popover,
  PopoverProps,
  PopoverTrigger,
  PopoverContent,
  Box,
  Portal,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
} from "@chakra-ui/react";
import { Button, WHButtonProps } from "../Button";
import EmojiPicker from "emoji-picker-react";

interface EmojiInputProps extends Omit<WHButtonProps, "onChange"> {
  onChange?: (selectedEmoji: string) => void;
  onPopoverClose?: () => void;
  closeOnSelect?: boolean;
  popoverProps?: PopoverProps;
}

export const EmojiInput = forwardRef<HTMLDivElement, EmojiInputProps>(
  (
    { onChange, onPopoverClose, closeOnSelect = true, popoverProps, ...props },
    ref
  ) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const boxRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          onClose();
          onPopoverClose?.();
        }}
        placement="bottom-start"
        isLazy
        {...popoverProps}
      >
        <PopoverTrigger>
          <Box ref={ref} maxW="min-content">
            <Button {...props}></Button>
          </Box>
        </PopoverTrigger>

        <Portal>
          <PopoverContent boxShadow="lg" border="none" zIndex="9999">
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Choose an emoji
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton ref={buttonRef} />
            <Box ref={boxRef} borderTopWidth="thin">
              <EmojiPicker
                onEmojiClick={(event, emoji) => {
                  onChange?.(emoji.emoji);
                  boxRef?.current.blur();
                  buttonRef?.current?.focus();
                  if (closeOnSelect) onClose();
                }}
                pickerStyle={{
                  width: "100%",
                  boxShadow: "none",
                  border: "none",
                }}
              />
            </Box>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
