import React, { forwardRef, useState } from "react";
import { Button, Input, Stack, StackProps } from "@chakra-ui/react";
import { useUpdateUserAttributes } from "../../mutations/users/useUpdateUserAttributes";

interface AddNewAttributeProps extends StackProps {
  // flow id
  flowId: string;

  // conversation id
  conversationId: string;

  // onClose callback
  onClose?: () => void;
}

export const AddNewAttribute: React.FC<AddNewAttributeProps> = forwardRef<
  HTMLDivElement,
  AddNewAttributeProps
>(({ flowId, conversationId, onClose, ...props }, ref) => {
  const [attributeKey, setAttributeKey] = useState<string>("");
  const [attributeValue, setAttributeValue] = useState<any>(null);

  const updateUserDetails = useUpdateUserAttributes({
    data: {
      // replacing all special characters from key and value
      [attributeKey.replace(/[^a-zA-Z0-9]/g, "")]: attributeValue,
    },
    flowId,
    conversationId,
    refetchKey: ["user_attributes", flowId, conversationId],
    mutationSuccessMessage: "Attribute added successfully",
    onSuccess: (_) => {
      setAttributeKey("");
      setAttributeValue("");
    },
  });

  return (
    <Stack ref={ref} {...props}>
      <Input
        size="sm"
        value={attributeKey}
        onChange={(event) => setAttributeKey(event.currentTarget.value)}
        placeholder="Enter key"
      />
      <Input
        size="sm"
        value={attributeValue}
        onChange={(event) => setAttributeValue(event.currentTarget.value)}
        placeholder="Enter value"
      />
      <Stack direction="row-reverse">
        <Button
          isLoading={updateUserDetails.isLoading}
          isDisabled={attributeKey === ""}
          colorScheme="pink"
          size="sm"
          onClick={() => {
            updateUserDetails.mutate();
            onClose?.();
          }}
        >
          Add New
        </Button>
        <Button
          size="sm"
          onClick={() => {
            setAttributeKey("");
            setAttributeValue("");
            onClose?.();
          }}
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
});
