import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export interface DashboardFlowConfig {
  bot_id: string;
  bot_name: string;
  bot_source: string;
  show_transcript: boolean;
  jmespath: boolean;
  dashboard: {
    table: {};
    summary: {};
    detailed: {};
  };
  replier_version: string;
  template_source: string;
}

export const useDashboardFlowConfig = (flowId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchFlowConfig = async () => {
    const res = await fetchWithAuth<DashboardFlowConfig>({
      url: `${config.url}/dashboard_flow/${flowId}`,
    });

    return res;
  };

  return useQuery(["flows", "config", flowId], fetchFlowConfig, {
    keepPreviousData: true,
    refetchInterval: 1000 * 30, // 30 seconds
  });
};
