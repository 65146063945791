import React, { forwardRef } from "react";
import {
  Box,
  BoxProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  HStack,
  Text,
} from "@chakra-ui/react";

import { MdInsertDriveFile } from "react-icons/md";

interface DocumentChatMessageProps extends BoxProps {
  message: UserMediaMessage;
}

export const DocumentChatMessage = forwardRef<
  HTMLDivElement,
  DocumentChatMessageProps
>(({ message, ...props }, ref) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      w="72"
      ref={ref}
      _hover={{
        cursor: "pointer",
      }}
      onClick={onOpen}
      {...props}
    >
      <HStack p={2} borderRadius="md" bg="blackAlpha.50">
        <MdInsertDriveFile size={24} />
        <Text fontSize="sm">{message.caption ?? "untitled_document"}</Text>
      </HStack>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.800"
          backdropFilter="auto"
          backdropBlur="6px"
        />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={Stack} pb={6}>
            <embed className="w-full h-[60vh] border-none" src={message.url} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
});
