import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Box, HStack, Stack, Button, ButtonGroup } from "@chakra-ui/react";
import axios from "axios";
import { SelectTemplate } from "../Invite";
import { AddIcon } from "../../../constants/icons";

const initialValues = {
  name: "",
  category: "",
  body: "",
};

const categories = [
  { value: "MARKETING", label: "Marketing" },
  { value: "OTP", label: "OTP" },
  { value: "TRANSACTIONAL", label: "Transactional" },
];

const TemplateForm = ({ handleFormOpen, flowId }) => {
  const onSubmit = async (values) => {
    const header = {
      "D360-API-KEY": process.env.REACT_APP_DIALOG360_API_KEY,
    };

    const template = {
      name: values.name + flowId,
      category: values.category,
      components: [
        {
          type: "BODY",
          text: values.body,
        },
      ],
      language: "en_US",
    };

    const resonse = await axios
      .post("https://waba.360dialog.io/v1/configs/templates", template, {
        headers: header,
      })
      .then((res) => console.log(res));

    return resonse;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema=""
    >
      {({ resetForm, handleSubmit }) => (
        <Form className="flex flex-col gap-4 w-full border border-gray-300 bg-white rounded mb-2 p-2">
          <div className="flex flex-col w-full items-start">
            <label className="font-medium" htmlFor="name">
              Name
            </label>
            <Field
              className="w-full p-2 border-2 rounded"
              type="text"
              id="name"
              name="name"
              placeholder="Whatsapp Template Name"
            />
            <ErrorMessage name="name" />
          </div>

          <div className="flex flex-col items-start">
            <label className="font-medium rounded" htmlFor="category">
              Category
            </label>
            <Field
              className="p-2 border-2 rounded"
              as="select"
              id="category"
              name="category"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </Field>
            <ErrorMessage name="category" />
          </div>

          <Stack className="flex-col items-start">
            <label className="font-medium" htmlFor="body">
              Body
            </label>
            <Field
              className="w-full h-24 p-2 border-2 rounded"
              as="textarea"
              id="body"
              name="body"
            />
            <ErrorMessage name="body" />
          </Stack>
          <ButtonGroup className="self-end gap-2">
            <Button
              onClick={() => {
                handleFormOpen(false);
                resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="purple"
              className="border hover:bg-purple-500 bg-white p-2"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

const AddTemplate = ({ flowId }) => {
  const [formOpen, setFormOpen] = useState(false);

  return (
    <Box className="w-full max-h-full">
      <h1 className="pt-2 mb-2 font-semibold text-lg">📜 WhatsApp Templates</h1>
      <Stack className="text-lg pb-2 flex-col text-center overflow-y-scroll">
        {formOpen ? (
          <TemplateForm flowId={flowId} handleFormOpen={setFormOpen} />
        ) : (
          <HStack
            onClick={() => setFormOpen(true)}
            className="w-full bg-white p-4 rounded border border-gray-300 text mb-2 cursor-pointer flex justify-between"
          >
            <Box className="text-left">
              <p className="text-base font-medium">Add Template</p>
              <p className="text-gray-500 text-sm">
                Create a new template from scratch
              </p>
            </Box>
            <Box className="w-fit">
              <AddIcon
                className="bg-slate-600 rounded-full text-white"
                size={28}
              />
            </Box>
          </HStack>
        )}
        <Stack className="w-full px-4 py-2 bg-white rounded border border-gray-300 max-h-[40vh] overflow-y-scroll">
          <h1 className="mt-2 text-base text-left self-start font-medium mb">
            Saved templates
          </h1>
          <Box className="w-full">
            <SelectTemplate navigation={false} flowId={flowId} />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddTemplate;
