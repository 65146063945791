import React, { forwardRef } from "react";
import { Stack, StackProps, Text, Image, Heading } from "@chakra-ui/react";
import emptyImageSrc from "../img/empty.svg";

interface NotFoundProps extends StackProps {
  // heading
  title?: string;

  // helper text
  subtitle?: string;
}

const NotFound = forwardRef<HTMLDivElement, NotFoundProps>(
  (
    {
      title = "Page not found!",
      subtitle = "The page you're trying to access is invalid",
      ...props
    },
    ref
  ) => {
    return (
      <Stack
        spacing={0}
        h="full"
        justify="center"
        align="center"
        ref={ref}
        {...props}
      >
        <Image src={emptyImageSrc} />
        <Heading size="sm">{title}</Heading>
        <Text className="text-base text-[#1D2939]">{subtitle}</Text>
      </Stack>
    );
  }
);

export default NotFound;
