import React, { forwardRef } from "react";

import { BoxProps, Stack, Text } from "@chakra-ui/react";
import {
  ImageChatMessage,
  DocumentChatMessage,
  AudioChatMessage,
  VideoChatMessage,
  WhatsappMessageText,
} from "../ChatMessage";
import { parseDate } from "../InfiniteTable";

interface UserChatMessageComponentProps extends BoxProps {
  message: UserMessageUnion;

  timestamp: number;

  inverse?: boolean;
}

const UserChatMessage = forwardRef<
  HTMLDivElement,
  UserChatMessageComponentProps
>(({ message, timestamp, inverse = false, ...props }, _) => {
  return (
    <Stack
      px={3}
      py={2}
      borderRadius="md"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.12);"
      alignSelf={inverse ? "flex-start" : "flex-end"}
      bg={inverse ? "white" : "pink.50"}
      {...props}
    >
      {message.type === "text" && (
        <WhatsappMessageText text={message.message} />
      )}
      {message.type === "location" && (
        <WhatsappMessageText text={message.message} />
      )}
      {message.type === "button" && (
        <WhatsappMessageText text={message.title} />
      )}
      {(message.type === "image" || message.type === "sticker") && <ImageChatMessage message={message} />}
      {message.type === "document" && <DocumentChatMessage message={message} />}
      {(message.type === "audio" || message.type === "voice") && (
        <AudioChatMessage message={message} />
      )}
      {message.type === "video" && <VideoChatMessage message={message} />}

      <Stack
        align="center"
        justify="space-between"
        direction={inverse ? "row-reverse" : "row"}
      >
        <Text className="text-xs text-gray-500">
          {parseDate(timestamp, "ddd · dd/mm/yy · hh:MM tt")}
        </Text>
      </Stack>
    </Stack>
  );
});

export default UserChatMessage;
