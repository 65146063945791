import React, { useState } from "react";
import { HStack, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { UserProfile } from "../../../components/UserProfile";
import { Transcript } from "./Transcript";
import { ChatHeader } from "./ChatHeader";

import { colorSystem } from "../../../theme";
import chatImage from "../../../img/chat_bg.png";
import { ChatFooter } from "./ChatFooter";

const { backgroundChat } = colorSystem;

export const SupportUserChat = () => {
  const { id: flowId, sessionId, userId } = useParams();
  const [showUserAttributes, setShowUserAttributes] = useState(true);

  return (
    <HStack
      spacing={0}
      key={`user-chat-${sessionId}`}
      className="p-0 m-0 h-screen w-full"
      style={{ background: `url(${chatImage}) #FAFBFC` }}
    >
      <Stack
        spacing={0}
        flex={1}
        className="relative flex-col-reverse h-screen"
        pos="relative"
      >
        <ChatHeader
          flowId={flowId}
          sessionId={sessionId}
          onDetailsClick={() => setShowUserAttributes((prev) => !prev)}
        />
        <Transcript
          key={`transcript-${sessionId}`}
          flex={1}
          direction="column-reverse"
          className="p-4 w-full rounded-lg overflow-y-scroll"
          flowId={flowId}
          userId={userId}
          sessionId={sessionId}
        />
        <ChatFooter flowId={flowId} sessionId={sessionId} />
      </Stack>
      {showUserAttributes && (
        <UserProfile
          key={`user-profile-${sessionId}`}
          bg={backgroundChat}
          w="20rem"
          h="full"
          borderLeftWidth="thin"
          onClose={() => setShowUserAttributes(false)}
        />
      )}
    </HStack>
  );
};
