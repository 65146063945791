import React from "react";
import { BoxProps, Stack, Text } from "@chakra-ui/react";
import { IntentMessage } from "../IntentMessage";
import { Button } from "../Button";
import { colorSystem } from "../../theme";

interface FlowMessageProps extends BoxProps {
  message: FlowMessageUnion; // message to render
  isCurrentlyEditing?: boolean; // flag to check if it's currently in editing stage
  onSave?: (key: string, message: string) => void; // callback when the `message` is saved
  onEdit?: () => void; // callback when the `message` enters edit state
}

export const FlowMessage = ({
  message,
  isCurrentlyEditing = false,
  ...props
}: FlowMessageProps) => {
  // Rendering plain text message
  if (typeof message === "string") {
    return (
      <IntentMessage
        sender="bot"
        message={message}
        canEdit={true}
        onSave={(message) => props.onSave?.("", message)}
        isEditing={isCurrentlyEditing}
        setIsEditing={(_) => props.onEdit?.()}
      />
    );
  }

  // Rendering interactive message
  if (message.type === "button" || message.type === "list") {
    // TODO: edit buttons
    return (
      <Stack>
        <IntentMessage
          sender="bot"
          message={message.body}
          canEdit={true}
          onSave={(message) => props.onSave?.(".body", message)}
          isEditing={isCurrentlyEditing}
          setIsEditing={(_) => props.onEdit?.()}
        />
        <Stack
          w="min-content"
          px={4}
          direction={message.options.length < 3 ? "row" : "column"}
        >
          {typeof message.options === "string" ? (
            <Text>{message.options}</Text>
          ) : (
            message.options.map((option, idx) =>
              typeof option === "string" ? (
                <Stack
                  px={4}
                  py={2}
                  bg={colorSystem.surfacePurple}
                  w="max-content"
                  key={idx}
                  boxShadow="sm"
                  borderRadius="md"
                >
                  <Text>{option}</Text>
                </Stack>
              ) : (
                <Stack
                  px={4}
                  py={2}
                  bg={colorSystem.surfacePurple}
                  w="max-content"
                  key={idx}
                  boxShadow="sm"
                  borderRadius="md"
                >
                  <Text fontSize="md">{option.title}</Text>
                  <Text>{option.description}</Text>
                </Stack>
              )
            )
          )}
        </Stack>
      </Stack>
    );
  }

  //   TODO: Render and Edit Media
  return <></>;
};
