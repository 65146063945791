import { Box, BoxProps, Stack } from "@chakra-ui/react";
import React from "react";

interface ToolbarProps extends BoxProps {
  size?: "md" | "sm" | "lg";
  toolbarActionRef: React.LegacyRef<HTMLDivElement> | undefined;
}

export * from './AddChoices'

export const Toolbar = ({ size = "lg", ...props }: ToolbarProps) => {
  return (
    <>
      <Stack
        direction="row"
        padding="17px"
        justify="center"
        transition="margin-left .3s,margin-right .3s"
      >
        <Stack
          height="10"
          maxWidth="100%"
          direction="row"
        >
          {props.children}
        </Stack>
      </Stack>
      <Box
        ref={props.toolbarActionRef}
        position="fixed"
        transform="translateX(-50%)"
        transition="margin-left .3s,margin-right .3s"
        left="50%"
        bottom="20"
        zIndex="6"
        w={size === "lg" ? "4xl" : size === "md" ? "3xl" : "xl"}
        bg="white"
        borderRadius="md"
        boxShadow="md"
      ></Box>
    </>
  );
};
