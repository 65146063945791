import axios from "axios";
import config from "../config";
import { FilterGroupQuery } from "../components/Filter/models";
import { RepeatTiming } from "./invite";
import { IFlowRequest, APIRequest } from "../models";

const apiPrefix = `${config.url}/v2/cron`;

export interface CronEvent {
  _id: string;
  event_id?: string;
  status: "active" | "inactive";
  name: string;
  flow_id: string;
  user_segment: FilterGroupQuery;
  pipeline: any[];
  timing: RepeatTiming;
}

interface ICronRequest extends APIRequest {
  cronId: string;
}

export const getCronEvents = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get<CronEvent[]>(`${apiPrefix}/${flowId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const deleteCronEvent = async ({ cronId, token }: ICronRequest) => {
  const response = await axios.delete<{ status: "success" | "failed" }>(
    `${apiPrefix}/remove/${cronId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
