import React from "react";
import { BiTime } from "react-icons/bi";
import { FiGitBranch } from "react-icons/fi";
import { MediaInput } from "../models/intent";
import { DashboardTemplate } from "../models/property";
import { PromptMessage } from "../pages/Flow";
import { AskIcon, MediaIcon, SayIcon } from "./icons";

export const KB_PLAYGROUND_FLOW = "kb_testing";
export const SOCKET_URL = "https://socket.workhack.io";
export const SOCKET_USER = "642cd3df9e607db150718281";
export const SOCKET_BOT = "64311d4ae20427445cc0cc3c";
export const SOCKET_SOURCE = "whtest";
export const MICRO_FLOW_START_TEXT = "Start the micro flow";

export const inviteSteps = [
  { title: "Select Users" },
  { title: "Choose Template" },
  { title: "Edit Content" },
  { title: "Conversation" },
  { title: "Review" },
];

export const defaultProductPitches = [
  {
    product:
      "We are a health company that sells wearables and health trackers. We help people track their health.",
    how: "Mellinials",
  },
  {
    product:
      "We are an edtech company that helps Low-income earning Indians get a better paying job with better status with English.",
    how: "Low-income earning Indians",
  },
  {
    product:
      "We are a Fitness company that help busy professionals get fitter and healthier with ready-made plans and accountability.",
    how: "21-25 aged salaried busy professionals",
  },
  {
    product: "We are digital clinic for men and we help them live confidently",
    how: "Millennials",
  },
];

export const toolbarActions = [
  {
    title: "Media",
    placeholder: "Add a caption...",
    key: "media",
    label: "Attach images, videos, documents",
    icon: <MediaIcon size={18} />,
  },
  {
    title: "Statement",
    placeholder: "Eg. the weather is pleasant today!",
    key: "statement",
    label: "Hmm! say that",
    icon: <SayIcon size={18} />,
  },
  {
    title: "Question",
    placeholder: "Eg. how's the weather today?",
    key: "question",
    label: "Got a curious question?",
    icon: <AskIcon size={18} />,
  },
  // {
  //   title: "Collect",
  //   placeholder: "what sort of data do you want to collect? ",
  //   key: "collect",
  //   label: "define your data",
  //   icon: <BiNavigation size={18} />,
  // },
  {
    title: "Branch",
    placeholder: "Eg. Do you drink coffee?",
    key: "branch",
    label: "Got a curious question?",
    icon: <FiGitBranch size={18} />,
  },
  {
    title: "Delay",
    placeholder: "Eg. How much delay?",
    key: "delay",
    label: "",
    icon: <BiTime size={18} />,
  },
];

export const suggestedFollowups = [
  "explore deeper insights",
  "show curiosity and empathy",
  "interrogate ruthlessly",
];

export const suggestedDatapoints = [
  "age",
  "email",
  "phone",
  "address",
  "PAN",
  "Aadhar",
  "city",
  "height",
  "weight",
];

export const projectAdjectives = [
  "spiritual",
  "amused",
  "obscene",
  "willing",
  "smelly",
  "bright",
  "colossal",
  "abstracted",
  "understood",
  "whispering",
  "ignorant",
  "momentous",
  "black",
  "agonizing",
  "fluffy",
  "regular",
  "drunk",
  "harsh",
  "open",
  "closed",
  "entertaining",
  "jobless",
  "periodic",
  "filthy",
  "sick",
  "nebulous",
  "possible",
  "complex",
  "expensive",
  "fat",
  "psychotic",
  "descriptive",
  "safe",
  "past",
  "nasty",
  "curvy",
  "puny",
  "five",
  "obese",
  "windy",
  "dangerous",
  "lovely",
  "knowledgeable",
  "magical",
  "unique",
  "outrageous",
  "demonic",
  "unhealthy",
  "futuristic",
  "whole",
  "functional",
  "heavy",
  "damaging",
  "silent",
  "thirsty",
  "absurd",
  "tiny",
  "dusty",
  "chemical",
  "married",
  "plastic",
  "juicy",
  "gentle",
  "lethal",
  "general",
  "careful",
  "spiky",
  "super",
  "common",
  "poor",
  "unbiased",
  "exotic",
  "possessive",
  "hilarious",
  "fragile",
  "exciting",
  "deep",
  "helpful",
  "psychedelic",
  "calm",
  "flashy",
  "teeny-tiny",
  "fertile",
  "thankful",
  "fearless",
  "lonely",
  "adorable",
  "wet",
  "crowded",
  "unused",
  "tasteful",
  "unbreakable",
  "breakable",
  "youthful",
  "beautiful",
  "fast",
  "quick",
  "strong",
  "typed",
  "simple",
  "cuddly",
  "sunny",
];

export const projectNouns = [
  "order",
  "butter",
  "ticket",
  "smoke",
  "birds",
  "rock",
  "wing",
  "fog",
  "sky",
  "water",
  "cream",
  "bikes",
  "shoes",
  "vegetable",
  "cow",
  "fork",
  "haircut",
  "waves",
  "dinosaurs",
  "blood",
  "servant",
  "structure",
  "party",
  "clown",
  "pen",
  "throat",
  "frogs",
  "earthq,uake",
  "card",
  "toothpaste",
  "spy",
  "worm",
  "activity",
  "truck",
  "potato",
  "office",
  "island",
  "bottle",
  "fairies",
  "maid",
  "needle",
  "squirrel",
  "cherry",
  "stone",
  "advertisement",
  "bells",
  "kittens",
  "deer",
  "hobbies",
  "river",
  "bubble",
  "soup",
  "cemetery",
  "coal",
  "crime",
  "sugar",
  "thunder",
  "grandfather",
  "ants",
  "steam",
  "aftermath",
  "hair",
  "respect",
  "wound",
  "ground",
  "door",
  "gold",
  "wish",
  "fire",
  "ring",
  "dogs",
  "theory",
  "watch",
  "machine",
  "soda",
  "star",
  "locket",
  "name",
  "berry",
  "castle",
  "reward",
  "pollution",
  "talk",
  "school",
  "reaction",
  "celery",
  "canvas",
  "car",
  "airplane",
  "pet",
  "cakes",
  "grass",
  "breath",
  "hand",
  "snake",
  "dolphin",
  "home",
  "directory",
  "bed",
  "desktop",
  "partner",
  "glass",
  "earth",
  "flower",
  "rose",
  "tree",
  "maze",
  "piano",
  "ball",
  "music",
  "food",
  "shower",
  "window",
  "umbrella",
  "fork",
  "plant",
  "towel",
];

export const defaultMedia: MediaInput = {
  type: "image",
  url: "https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg",
  key: "",
  bucket: "",
};

export type TemplateComponentVariant = "preview" | "edit";

export const defaultEmailTemplate: DashboardTemplate = {
  name: "Email Template",
  text: "Subject:\n{subject}\n\nHi {name},\n\n{body}\n\n{signature}",
  values: {
    subject: {
      info: "Line 1",
      value: "Your invite is here!",
    },
    body: {
      info: "Line 2",
      value: "You have been shortlisted for a job",
    },
    signature: {
      info: "Line 2",
      value: "Copyright © 2022\nCompany",
    },
  },
};

export const defaultWhatsappTemplate: DashboardTemplate = {
  name: "Whatsapp Template",
  text: "",
  values: {},
};

export const defaultTableColumns = [
  {
    title: "ID",
    key: "ID",
    dataIndex: "ID",
  },
  {
    title: "Name",
    key: "Name",
    dataIndex: "Name",
  },
  {
    title: "Phone Number",
    key: "Phonenumber",
    dataIndex: "Phone Number",
  },
  {
    title: "Email",
    key: "E-mail",
    dataIndex: "Email",
  },
  {
    title: "City",
    key: "City",
    dataIndex: "City",
  },
  {
    title: "Company",
    key: "Company",
    dataIndex: "Company",
  },
];

/**
 * Default example turns for the assistant
 * ---
 * assistant says:
 * user replies:
 * assistant thinks
 * assistant says:
 */
export const defaultExampleTurns: PromptMessage[] = [
  { type: "message", role: "assistant", content: "" },
  { type: "message", role: "user", content: "" },
  { type: "thoughts", role: "assistant", content: "" },
];

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export * from "./feature_flags"