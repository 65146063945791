import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export interface TopQueryStat {
  question: string;
  answer: string;
  count: number;
}

export const useTopQueries = (flowId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchTopQueries = async () => {
    const res = await fetchWithAuth<{ top_queries: TopQueryStat[] }>({
      url: `${config.url}/v2/analytics/radio_mirchi/queries/`,
    });

    return res;
  };

  return useQuery(["topQueries", flowId], fetchTopQueries, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // 30 mins
  });
};
