import React, { useState } from "react";
import {
  Stack,
  Heading,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Box,
  BoxProps,
  Text,
} from "@chakra-ui/react";
import { BaseInviteLayout, InviteViewProps } from "./BaseLayout";
import { useStoreActions } from "../../../hooks/store";
import { Nudge } from "../../Nudge";

interface NudgeCardProps extends BoxProps {
  active?: boolean;
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  titleTrailing?: React.ReactNode;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

export const NudgeCard = (props: NudgeCardProps) => {
  return (
    <Stack
      className={`${
        props.active ? " border-purple-500" : ""
      } cursor-pointer hover:border-purple-500 p-4 mb-2 w-full border-2 rounded-lg`}
      {...props}
    >
      <Box>
        <Heading size="md">{props.title}</Heading>
        {props.subtitle && <Text fontSize="md">{props.subtitle}</Text>}
      </Box>
    </Stack>
  );
};

export const SelectConversation = ({ flowId, ...props }: InviteViewProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState<"flowSelection" | "microFlowSelection">(
    "flowSelection"
  );
  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );

  const [conversationType, setConversationType] = useState<
    "default" | "notification" | "conversation"
  >("default");

  const handleNextPage = () => {
    if (page === "flowSelection") {
      if (conversationType === "conversation") {
        onOpen();
        return setPage("microFlowSelection");
      }
    }

    return props.onNext?.();
  };

  const handlePreviousPage = () => {
    if (page === "microFlowSelection") {
      return setPage("flowSelection");
    }

    return props.onBack?.();
  };

  return (
    <BaseInviteLayout
      backButton={{ onClick: () => handlePreviousPage() }}
      nextButton={{
        onClick: () => handleNextPage(),
      }}
    >
      <Stack>
        <Heading>what?</Heading>
        <p className="mb-2 text-lg">
          choose type of conversation you want to send
        </p>
        <NudgeCard
          onClick={() => {
            setConversationType("default");
            storeCampaignData({ key: "micro_flow", value: "" });
          }}
          active={conversationType === "default"}
          title="📮 Default Flow"
          subtitle="Default conversation flow of this bot."
        />
        <NudgeCard
          onClick={() => setConversationType("conversation")}
          active={conversationType === "conversation"}
          title="🤝 Conversation"
          subtitle="Custom conversation flow you can create."
        />
        <NudgeCard
          onClick={() => {
            setConversationType("notification");
            storeCampaignData({ key: "micro_flow", value: "" });
          }}
          active={conversationType === "notification"}
          title="📢 Notification"
          subtitle="Ping your users to resume conversations"
        />
      </Stack>
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody py={16} as={Stack} spacing={8}>
            <Nudge flowId={flowId} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </BaseInviteLayout>
  );
};
