import React, { useState } from "react";
import { Stack, StackProps, Button } from "@chakra-ui/react";
import { TextField } from "../TextField";
import { useNewNote } from "../../mutations/users/useNewNote";

interface NewUserNoteProps extends StackProps {
  // flow id
  flowId: string;

  // user id
  userId: string;

  // onClose callback
  onClose?: () => void;
}

export const NewUserNote = ({
  flowId,
  userId,
  onClose,
  ...props
}: NewUserNoteProps) => {
  const [text, setText] = useState("");
  const newNote = useNewNote({
    flowId,
    refetchKey: ["user", "notes"],
  });

  const addNewNote = () => {
    newNote.mutate({ text, userId });
    setText("");
    onClose?.();
  };

  // handle enter key press
  const _handleKeyDown = (key: string, allowNewLine: boolean) => {
    // when shift key is pressed allow new line
    if (allowNewLine) return;
    if (key === "Enter" && !newNote.isLoading) addNewNote();
  };

  return (
    <Stack {...props}>
      <TextField
        size="sm"
        minH={24}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(event) => _handleKeyDown(event.key, event.shiftKey)}
        placeholder="add a note"
      />
      <Stack direction="row-reverse">
        <Button
          colorScheme="pink"
          isLoading={newNote.isLoading}
          isDisabled={text === ""}
          size="sm"
          onClick={() => addNewNote()}
        >
          Add New
        </Button>
        <Button
          size="sm"
          onClick={() => {
            setText("");
            onClose?.();
          }}
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};
