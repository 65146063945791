import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { defaultChartOptions } from "../../utils/constants";
import { Skeleton } from "@chakra-ui/react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels
);

const BarChart = ({
  data,
  type = "vertical",
  options = {},
  borderWidth = 2,
  loading = false,
}) => {
  return (
    <Skeleton isLoaded={!loading}>
      <Bar
        options={{
          ...defaultChartOptions,
          indexAxis: type === "vertical" ? "y" : "x",
          elements: {
            bar: {
              borderWidth,
            },
          },
          scales: {
            x: {
              alignToPixels: true
            },
          },
          plugins: {
            ...defaultChartOptions["plugins"],
          },
          ...options,
        }}
        data={data}
      />
    </Skeleton>
  );
};

export default BarChart;
