import React from "react";

import { Stack, ButtonGroup, IconButton, HStack, Box } from "@chakra-ui/react";
import { MICRO_FLOW_START_TEXT } from "../../../constants";
import { MdThumbDown, MdThumbUp } from "react-icons/md";
import { useMessageFeedback } from "../../../mutations/support/useMessageFeedback";
import UserChatMessage from "../../../components/Conversation/UserChatMessage";
import BotChatMessage from "../../../components/Conversation/BotChatMessage";

interface MessageProps {
  message: MessageInDb;
  inverse?: boolean;
  fontSize?: string;
}

export const SupportMessage = ({ message, inverse = false }: MessageProps) => {
  const feedbackMutation = useMessageFeedback({
    flowId: message.flow_id,
    refetchKey: [],
  });

  const feedback = message.data?.["feedback"] ?? null;

  const getSender = () => {
    return message.data?.["manual_message_data"]?.["sender"] ?? message.sender;
  };

  /**
   * If the message is a microflow start message, don't show it
   */
  if (
    message.sender === "user" &&
    message.message.type === "text" &&
    message.message.message === MICRO_FLOW_START_TEXT
  )
    return <></>;

  return (
    <Stack
      role="group"
      align="center"
      direction={message.sender === "bot" ? "row-reverse" : "row"}
      width="100%"
    >
      {message.sender === "user" ? (
        <UserChatMessage
          maxW="60%"
          inverse={inverse}
          message={message.message}
          timestamp={message.timestamp}
        />
      ) : (
        <BotChatMessage maxW="60%" inverse={inverse} message={message} />
      )}
      {message.sender === "bot" && getSender() === "bot" && (
        <HStack>
          <ButtonGroup
            visibility="hidden"
            size="xs"
            _groupHover={{ visibility: "visible" }}
          >
            <IconButton
              isActive={feedback === "vote_up"}
              aria-label={"feedback_up"}
              icon={<MdThumbUp />}
              onClick={() =>
                feedbackMutation.mutate({ message, feedback: "vote_up" })
              }
            />
            <IconButton
              isActive={feedback === "vote_down"}
              aria-label={"feedback_down"}
              icon={<MdThumbDown />}
              onClick={() =>
                feedbackMutation.mutate({ message, feedback: "vote_down" })
              }
            />
          </ButtonGroup>
          <Box _groupHover={{ display: "none" }}>
            {feedback ? (
              <IconButton
                size="xs"
                isActive
                icon={feedback === "vote_up" ? <MdThumbUp /> : <MdThumbDown />}
                aria-label={feedback}
              />
            ) : (
              <></>
            )}
          </Box>
        </HStack>
      )}
    </Stack>
  );
};
