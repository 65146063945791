import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";
import { DashboardEvent } from "../../api/events";

export const useDashboardEvents = (sessionId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchEvents = async () => {
    const res = await fetchWithAuth<DashboardEvent[]>({
      url: `${config.url}/v2/events/dashboard?conversation_ids=${sessionId}`,
    });

    return res;
  };

  // TODO: how not to get unneccessary events
  return useQuery(["dashboard_events", sessionId], fetchEvents, {
    keepPreviousData: true,
    refetchInterval: 1000, // 1 second
  });
};
