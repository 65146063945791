import React from "react";
import { Stack, ButtonGroup, Circle, Spacer } from "@chakra-ui/react";

import { UserIcon } from "../constants/icons";
import { AvatarTile } from "./AvatarTile";
import { FaMagic } from "react-icons/fa";
import { IntentMessage } from "./IntentMessage";
import { BiTime } from "react-icons/bi";
import { colorSystem } from "../theme";

interface ConversationTurnProps {
  question: string;
  questionButtons?: string[];
  allowQuestionEditing?: boolean;
  isQuestionEditing?: boolean;
  onQuestionEdit?: (flag: boolean) => void;
  onQuestionButtonTap?: (question: string) => void;
  onQuestionSave?: (question: string) => void;

  reply?: string;
  allowReplyEditing?: boolean;
  isEditing?: boolean;
  onReplySave?: (question: string) => void;
  onReplyEdit?: (flag: boolean) => void;

  acknowledgement?: string;
  isAcknowledgmentLoading?: boolean;

  questionActions?: React.ReactNode;
  acknowledgmentActions?: React.ReactNode;
  hasDelay?: boolean;
}

export const ConversationTurn = ({
  question,
  questionButtons = [],

  reply = "",
  allowReplyEditing = true,

  acknowledgement = "",
  isAcknowledgmentLoading = false,

  hasDelay = false,

  ...rest
}: ConversationTurnProps) => {
  return (
    <Stack spacing={2}>
      {/* Question */}
      <Stack spacing={4} direction="row">
        <Stack
          cursor="grab"
          spacing={0}
          visibility="hidden"
          align="center"
          _groupHover={{ visibility: "visible" }}
          transition="visibility 30ms ease-in 0s;"
        >
          {rest.questionActions}
        </Stack>
        {hasDelay ? <DelayIndication /> : <DashboardUserIndicator />}
        <IntentMessage
          sender="bot"
          showEmptyMessage
          message={question}
          buttons={questionButtons}
          onButtonClick={(button) => rest.onQuestionButtonTap?.(button)}
          canEdit={rest.allowQuestionEditing}
          onSave={(message) => rest.onQuestionSave?.(message)}
          isEditing={rest.isQuestionEditing}
          setIsEditing={(isEditing) => rest.onQuestionEdit?.(isEditing)}
        />
      </Stack>

      {/* User Reply */}
      <Stack
        hidden={reply === ""}
        spacing={4}
        direction="row-reverse"
        align="center"
      >
        <UserIndicator />
        <IntentMessage
          message={reply}
          sender="user"
          canEdit={allowReplyEditing}
          onSave={(message) => rest.onReplySave?.(message)}
          isEditing={rest.isEditing}
          setIsEditing={(isEditing) => rest.onReplyEdit?.(isEditing)}
        />
      </Stack>

      {/* Acknowledgment */}
      <Stack
        hidden={acknowledgement === ""}
        spacing={4}
        align="center"
        direction="row"
      >
        <ButtonGroup
          isAttached
          cursor="grab"
          visibility="hidden"
          _groupHover={{ visibility: "visible" }}
          transition="visibility 30ms ease-in 0s;"
        >
          {/* Acknowledgment Actions */}
          {rest.acknowledgmentActions ?? <Spacer px={6} />}
        </ButtonGroup>
        <MagicIndicator />
        <IntentMessage
          message={acknowledgement}
          sender="bot"
          canEdit={false}
          isLoading={isAcknowledgmentLoading}
          isAIMessage
        />
      </Stack>
    </Stack>
  );
};

const UserIndicator = () => (
  <Circle p={2} bg="black">
    <UserIcon color="white" />
  </Circle>
);

const DelayIndication = () => (
  <Circle p={2} bg={colorSystem["textPurple"]} maxHeight={8}>
    <BiTime color="white" />
  </Circle>
);

export const MagicIndicator = () => (
  <Circle maxHeight={8} p={2} bg="black">
    <FaMagic color="pink" />
  </Circle>
);

const DashboardUserIndicator = () => (
  <AvatarTile size={30} showTooltip={false} name="magic ai" showText={false} />
);
