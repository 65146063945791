import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const useTotalQueriesAsked = (flowId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchTopQueries = async () => {
    const res = await fetchWithAuth<{ total_queries_asked: number }>({
      url: `${config.url}/v2/analytics/radio_mirchi/total_queries_asked/`,
    });

    return res;
  };

  return useQuery(["totalQueries", flowId], fetchTopQueries, {
    keepPreviousData: true,
    staleTime: 1000 * 60, // 1 min
  });
};
