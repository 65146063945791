import React, { useState, useEffect } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Stack, Text, Input, StackProps } from "@chakra-ui/react";

import { WidgetMessageInDb } from "./index";
import { ChatBubble } from "../ChatBubble";
import { sendEngineMessage } from "../../api/engine";
import { SOCKET_URL, SOCKET_USER } from "../../constants";
import chatImage from "../../img/chat_bg.png";
import { colorSystem } from "../../theme";

class RetriableError extends Error {}
class FatalError extends Error {}

interface ChatWidgetProps extends StackProps {
  // callback for when the chat widget is started
  onStarted?: () => void;

  // callback for when the chat widget is closed
  onClosed?: () => void;

  // callback for when a message is sent
  onMessageSent?: () => void;

  // callback for when a message is received
  onMessageReceived?: (message: WidgetMessageInDb) => void;
}

export const ChatWidget = ({
  children,
  onStarted,
  onClosed,
  onMessageSent,
  onMessageReceived,
  ...props
}: ChatWidgetProps) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<WidgetMessageInDb[]>([]);
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    // sends message as test-user-rec to test-user-app
    if (message.length === 0) return;

    const body = await sendEngineMessage(message);

    if (body) {
      setMessages((messages) => [body, ...messages]);
      setMessage("");
      onMessageSent?.();
    }
  };

  const _handleKeyDown = (key: string) => {
    if (key === "Enter") sendMessage();
  };

  useEffect(() => {
    // listen messages of test-user-rec
    const getMessages = async () => {
      const eventSource = await fetchEventSource(
        `${SOCKET_URL}/messages/stream`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SOCKET_USER}`,
          },
          // TIL: understood how important this flag is
          openWhenHidden: true,
          onopen: async (response) => {
            if (response.ok) {
              onStarted?.();
              console.log("started listening to messages");
              setMessages([]);
              setLoading(true);
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              // client-side errors are usually non-retriable:
              console.log("client-side errors are usually non-retriable:");
              setLoading(false);
              setMessages([]);
              throw new FatalError();
            } else {
              console.log("errors that are usually retriable");
              setLoading(false);
              setMessages([]);
              throw new RetriableError();
            }
          },

          onmessage: (eventSourceMessage) => {
            console.log(eventSourceMessage);

            try {
              const { id, data, event } = eventSourceMessage;

              if (data && event === "message") {
                const messageObj: WidgetMessageInDb = JSON.parse(data);
                setMessages((messages) => [messageObj, ...messages]);
                onMessageReceived?.(messageObj);
              }
            } catch (error) {
              console.log(error);
            }
          },

          onclose: () => {
            console.log("socket connection closed");
            onClosed?.();
          },
        }
      );

      return eventSource;
    };

    getMessages();
  }, []);

  // update status
  useEffect(() => {
    const updateStatus = async () => {
      if (messages.length === 0) return;

      const lastMessage: WidgetMessageInDb = messages[messages.length - 1];

      if (!lastMessage) return;

      const result = await fetch(
        `${SOCKET_URL}/messages/${lastMessage.id}/status`,
        {
          method: "PATCH",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_SOCKET_USER}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify("delivered"),
        }
      );
      //TODO: store events in db
      console.log(result.status);
    };

    updateStatus();
  }, [messages.length]);

  // send messages
  return (
    <Stack
      pos="relative"
      direction="column-reverse"
      h="100vh"
      style={{
        background: `url(${chatImage}) ${colorSystem["surfaceGrey"]}`,
      }}
      {...props}
    >
      <Stack px={4} py={2}>
        <Input
          value={message}
          onChange={(event) => setMessage(event.currentTarget.value)}
          onKeyDown={(event) => _handleKeyDown(event.key)}
          placeholder="Type your message here..."
        />
        <Text alignSelf="flex-end" fontSize="xs" color="gray.600">
          ↩ To send
        </Text>
      </Stack>
      <Stack
        direction="column-reverse"
        w="full"
        borderRadius="lg"
        flex={1}
        overflowY="scroll"
        px={4}
      >
        {messages.map((message, idx) => (
          <ChatBubble
            sender={message.message.from_id === SOCKET_USER ? "user" : "bot"}
            shape="bubble"
            key={idx}
          >
            {message && message.message.type === "text" && (
              <Text>{message.message.message}</Text>
            )}
          </ChatBubble>
        ))}
      </Stack>
      {children}
    </Stack>
  );
};
