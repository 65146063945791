import React from "react";
import { Stack, Text, Circle } from "@chakra-ui/react";
import { SelectionTagProps } from "./props";

export const SelectionTag = ({
  variant = "tag",
  onInclude,
  ...props
}: SelectionTagProps) => {
  return (
    <Stack
      align="center"
      justify="space-between"
      direction="row"
      px={2}
      py={1}
      bg="gray.100"
      borderWidth="thin"
      borderRadius={variant === "tag" ? "sm" : "full"}
      role="group"
      cursor="pointer"
      {...props}
      onClick={() => onInclude?.()}
      _hover={{
        bg: "pink.50",
        borderColor: "pink.100",
      }}
    >
      {variant === "status" && <Circle size="2" bg="blackAlpha.400" />}
      <Text w="max-content">{props.tag}</Text>
      <Text
        bg="gray.300"
        borderWidth="thin"
        _groupHover={{
          bg: "pink.50",
          borderColor: "pink.100",
        }}
        px={2}
        borderRadius="full"
      >
        {props.count}
      </Text>
    </Stack>
  );
};
