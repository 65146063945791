import React, { forwardRef } from "react";
import { Stack, StackProps, Text, Heading } from "@chakra-ui/react";

import { useAttributeKeys } from "../../data/flow/useAttributeKeys";
import ForeverProgress from "../Feedback/Progress";
import { useDashboardFlowConfig } from "../../data/flow/useDashboardFlow";

interface SelectAttributeProps extends StackProps {
  flowId: string;
  query?: string;
  onAttributeSelect?: (attributeKey: string, isExternal: boolean) => void;
}

export const SelectAttributes = forwardRef<
  HTMLDivElement,
  SelectAttributeProps
>(({ flowId, title, query = "", onAttributeSelect, ...props }, ref) => {
  const { data: attributeKeys, isLoading } = useAttributeKeys(flowId);
  const { data: flowConfig } = useDashboardFlowConfig(flowId);

  if (isLoading) {
    return (
      <Stack ref={ref} {...props}>
        <ForeverProgress size="sm" />
      </Stack>
    );
  }

  return (
    <Stack ref={ref} {...props}>
      {title && <Heading size="sm">{title}</Heading>}
      {Object.keys(flowConfig?.dashboard?.summary ?? {})
        .filter((attributeKey) =>
          attributeKey.toLowerCase().startsWith(query.toLowerCase())
        )
        .map((attributeKey, index) => (
          <Text
            px={2}
            key={`${index}-${attributeKey}-summary`}
            borderRadius="sm"
            _hover={{ bg: "gray.50", cursor: "pointer" }}
            onClick={() =>
              onAttributeSelect?.(
                flowConfig?.dashboard?.summary?.[attributeKey] ?? attributeKey,
                false
              )
            }
          >
            {attributeKey}
          </Text>
        ))}
      {attributeKeys &&
        attributeKeys
          .filter((attributeKey) =>
            attributeKey.toLowerCase().startsWith(query.toLowerCase())
          )
          .map((attributeKey, index) => (
            <Text
              px={2}
              key={`${index}-${attributeKey}-ext_attribute`}
              borderRadius="sm"
              _hover={{ bg: "gray.50", cursor: "pointer" }}
              onClick={() => onAttributeSelect?.(attributeKey, true)}
            >
              {attributeKey}
            </Text>
          ))}
    </Stack>
  );
});
