import React from "react";
import { VStack, Box } from "@chakra-ui/react";

interface RenderOptionsProps {
  title?: string;
  options: string[];
  query: string;
  yAxis?: "top" | "bottom";
  xAxis?: "left" | "right";
  onClick?: (option: string) => void;
}

export const RenderOptions = ({
  title = "Select",
  query = "",
  options = [],
  yAxis = "top",
  xAxis = "right",
  ...props
}: RenderOptionsProps) => {
  return (
    <VStack
      className={`h-[35vh] z-10 absolute rounded-md ${yAxis}-[80%] ${xAxis}-4/3 p-2 bg-white shadow-lg w-2/5 overflow-y-scroll`}
    >
      <p className="p-2 self-start text-gray-500">{title}</p>
      {options
        .filter((attribute) => attribute.includes(query))
        .map((attribute) => (
          <Box
            className="w-full p-2 hover:bg-gray-100 text-lg rounded-lg cursor-pointer self-start"
            onClick={() => {
              props.onClick?.(attribute);
            }}
          >
            {attribute}
          </Box>
        ))}
    </VStack>
  );
};
