import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";
import {
  AnalyticsDataFetchError,
  AnalyticsDataFetchFinish,
  AnalyticsDataFetchEvent,
  AnalyticsDataFetchBegin,
} from ".";

export interface CampaignDataStat extends AnalyticsDataFetchEvent {
  type: "data";
  name: string;
  campaign_id: string;
  count: number;
}

export const useCampaignConfig = (flowId: string) => {
  const queryKey = ["campaign_config", flowId];
  const { getAuthToken } = useAuthToken();
  const queryClient = useQueryClient();

  const fetchMessageStats = async () => {
    const dataFetchBegin: AnalyticsDataFetchBegin = {
      type: "start",
    };

    const token = localStorage.getItem('token')

    const eventSource = new EventSourcePolyfill(
      `${config.url}/v2/analytics/campaign_config/${flowId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    eventSource.onopen = () =>
      console.log("[campaign config]: connection opened");

    eventSource.onmessage = (event) => {
      if (event && event.data && event.data.trim().length > 0) {
        try {
          let analyticsEvent:
            | AnalyticsDataFetchError
            | CampaignDataStat
            | AnalyticsDataFetchFinish = JSON.parse(event.data);

          if (analyticsEvent.type === "finish") {
            console.log(
              "[campaign config]: closing connection as data fetch is finished"
            );
            eventSource.close();
          }

          if (analyticsEvent.type === "finish") {
            console.log(
              "[campaign config]: closing connection as data fetch is finished"
            );
            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                // if query data is not an array, it means that the query data is an error

                return Array.isArray(queryData) ? queryData.reverse() : [];
              }
            });
            eventSource.close();
          }

          if (analyticsEvent.type === "error") {
            // TODO: Handle error
            console.log(analyticsEvent);
          }

          if (analyticsEvent.type === "data") {
            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                if (!Array.isArray(queryData)) {
                  console.log(
                    "[campaign config]: appending first data to query data",
                    queryData
                  );
                  return [analyticsEvent];
                }

                try {
                  const prevQuery: CampaignDataStat[] =
                    queryData as CampaignDataStat[];

                  return [
                    ...prevQuery.filter(
                      (_) =>
                        _.campaign_id !==
                        (analyticsEvent.type === "data"
                          ? analyticsEvent.campaign_id
                          : "")
                    ),
                    analyticsEvent,
                  ];
                } catch (error) {
                  console.log(error);
                  return [];
                }
              }
            });
          }
        } catch (error) {
          console.log("[campaign config]: JSON parsing error", error);
        }
      }
    };

    eventSource.onerror = (error) => {
      console.log("[campaign config]: error", error);
      const dataFetchError: AnalyticsDataFetchError = {
        type: "error",
        error: `${error}}`,
      };
      queryClient.setQueriesData(queryKey, dataFetchError);
    };

    return dataFetchBegin;
  };

  return useQuery<
    AnalyticsDataFetchError | AnalyticsDataFetchBegin | CampaignDataStat[]
  >(queryKey, fetchMessageStats, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // 30 mins
  });
};
