import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const usePing = (flowId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const sendPingRequest = async () => {
    const res = await fetchWithAuth<{ status: "success"; result: number }>({
      url: `${config.url}/v2/support/${flowId}/ping`,
    });

    return res;
  };

  return useQuery(["ping", flowId], sendPingRequest, {
    keepPreviousData: true,
    refetchInterval: 1000 * 2 * 60, // 2 minutes
  });
};
