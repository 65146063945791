import React, { useState, forwardRef } from "react";
import {
  Text,
  Heading,
  Stack,
  StackProps,
  IconButton,
  Skeleton,
  Image,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { Appbar } from "../Appbar";
import { AddIcon, CloseIcon, MinusIcon } from "../../constants/icons";
import { Button } from "../Button";
import { useUserSummary } from "../../data/user/useUserData";
import emptyImageSrc from "../../img/empty.svg";
import { UserProfileHeader } from "./Header";
import { Attribute } from "./Attribute";
import { useUserAttributes } from "../../data/user/useUserAttributes";
import { AddNewAttribute } from "./NewAttribute";
import { useParams } from "react-router-dom";
import { BiNote, BiUser } from "react-icons/bi";
import { NewUserNote, UserNoteList } from "../Notes";
import { useUserNotesCount } from "../../data/user/useUserNotesCount";

interface UserProfileProps extends StackProps {
  onClose?: () => void;
}

const UserProfileLoadingSkeleton = () => {
  return (
    <Stack p={2}>
      <Stack key={`user_details_skeleton`}>
        <Skeleton key={`user_name_skeleton`} w={24} height={6} />
        <Skeleton key={`user_phone_skeleton`} w={32} height={4} />
        <Skeleton key={`user_action_skeleton`} height={8} />
      </Stack>
      <Heading py={2} size="sm">
        Attributes
      </Heading>
      {[0, 1].map((key, index) => (
        <Stack
          key={`user_attributes_${index}`}
          py={2}
          borderBottomStyle="dashed"
          borderBottomWidth="thin"
        >
          <Skeleton key={`${index}_user_attribute_key`} w={32} height={3} />
          <Skeleton key={`${index}_user_attribute_val`} height={6} />
        </Stack>
      ))}
    </Stack>
  );
};

export const UserProfile = forwardRef<HTMLDivElement, UserProfileProps>(
  ({ onClose, ...props }, ref) => {
    const [accordionIndex, setAccordionIndex] = useState(-1);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const { id: flowId, sessionId, userId } = useParams();

    const {
      data: userProfile,
      isLoading,
      error,
      refetch,
    } = useUserSummary({ flowId, sessionId });

    const { data: userNotesCount } = useUserNotesCount(flowId, userId);

    const { data: userAttributes } = useUserAttributes(flowId, userId);

    const renderAttributes = (
      type: "summary" | "metadata",
      attributes?: { [attributeKey: string]: any }
    ) => {
      if (!attributes) return <></>;

      return Object.keys(attributes).map((attributeKey, index) => {
        const attributeValue = attributes?.[attributeKey] ?? "-";
        return (
          <Attribute
            key={`${attributeKey}-${index}`}
            // style
            px={4}
            py={1}
            spacing={0}
            justify="space-between"
            // data
            type={type}
            flowId={flowId}
            conversationId={userId}
            sessionId={sessionId}
            attributeKey={attributeKey}
            attributeValue={
              ["string", "boolean", "number"].includes(typeof attributeValue)
                ? `${attributeValue}` === ""
                  ? "-"
                  : `${attributeValue}`
                : Array.isArray(attributeValue)
                ? `${attributeValue}`
                : typeof attributeValue === "object"
                ? JSON.stringify(attributeValue)
                : "-"
            }
          />
        );
      });
    };

    if (isLoading || error) {
      return (
        <Stack ref={ref} {...props}>
          <Appbar
            className="overflow-y-scroll static w-full"
            boxShadow="sm"
            borderBottomWidth="thin"
            variant="sticky"
            title="Details"
            trailing={
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                aria-label={"close-icon"}
                onClick={() => onClose?.()}
              />
            }
          />
          {error ? (
            <Stack
              hidden={!error}
              spacing={0}
              className="p-4 border border-dashed m-2 justify-center items-center"
            >
              <Image w="12" src={emptyImageSrc} />
              <Text>Something went wrong</Text>
              <Button variant="tertiary" onClick={() => refetch()}>
                Retry
              </Button>
            </Stack>
          ) : (
            <UserProfileLoadingSkeleton />
          )}
        </Stack>
      );
    }

    return (
      <Stack spacing={0} maxH="100vh" overflowY="hidden" ref={ref} {...props}>
        <Appbar
          className="overflow-y-scroll static w-full"
          boxShadow="sm"
          borderBottomWidth="thin"
          variant="sticky"
          boxProps={{ zIndex: 0 }}
          title="Details"
          trailing={
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              aria-label={"close-icon"}
              onClick={() => onClose?.()}
            />
          }
        />
        {/* profile header - shows name, number and block action */}
        <UserProfileHeader
          profile={userProfile}
          flowId={flowId}
          userId={userId}
          sessionId={sessionId}
          pb={4}
          bg="white"
        />

        {/* shows main area - tabs */}
        <Tabs
          isLazy
          bg="white"
          pos="relative"
          flex={1}
          overflowY="scroll"
          colorScheme="pink"
          index={activeTabIndex}
          onChange={(index) => setActiveTabIndex(index)}
        >
          <TabList bg="white" position="sticky" top={0} left={0} right={0}>
            <Tab>
              <HStack spacing={1}>
                <BiUser />
                <Text>Attributes</Text>
              </HStack>
            </Tab>
            <Tab>
              <HStack spacing={1}>
                <BiNote />
                <Text>
                  Notes ({userNotesCount ? userNotesCount.count ?? 0 : 0})
                </Text>
              </HStack>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {renderAttributes("summary", userProfile?.summary)}
              {userAttributes &&
                renderAttributes("metadata", userAttributes?.user_data)}
            </TabPanel>
            <TabPanel>
              <UserNoteList flowId={flowId} userId={userId} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* footer action */}
        <Accordion
          allowToggle
          bg="white"
          borderTopWidth="thin"
          index={accordionIndex}
          onChange={(e) => setAccordionIndex(e as number)}
        >
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton as={HStack} justify="space-between" py={4}>
                  <Heading size="sm">
                    {activeTabIndex === 0
                      ? "Add new attribute"
                      : "Add new note"}
                  </Heading>
                  {isExpanded ? <MinusIcon /> : <AddIcon />}
                </AccordionButton>

                <AccordionPanel>
                  {activeTabIndex === 0 ? (
                    <AddNewAttribute
                      flowId={flowId}
                      conversationId={userId}
                      onClose={() => setAccordionIndex(-1)}
                    />
                  ) : (
                    <NewUserNote
                      flowId={flowId}
                      userId={userId}
                      onClose={() => setAccordionIndex(-1)}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Stack>
    );
  }
);
