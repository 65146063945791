import React, { useEffect, useState } from "react";
import {
  Button, Box, VStack, Image, Flex, FormControl, FormErrorMessage,
  Stack, Input, InputGroup, InputRightElement, Text
} from "@chakra-ui/react";
import AssesshubLogo from '../img/assesshub_logo.png';
import LoginImage from '../img/login_background.jpg'
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { loginUser } from "../api/auth";
import { AuthContext } from "../store/authContext";
import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";

const LoginInfoSchema = Yup.object().shape({
  email: Yup.string().max(100, "Too long!").email("Not a valid email!").required('Required!'),
  password: Yup.string().required('Required!'),
  redirect: Yup.boolean()
})

export const Login = () => {
  // context
  const { user, login, isAuthenticated } = React.useContext(AuthContext);
  const [show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate();


  const handleShowClick = () => setShow(!show)

  const handleTokenLogin = (token: string, id: string, email: string, username: string, role: string) => {
    // store data into localstorage
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('user', JSON.stringify({ id: id, email: email, username: username, role: role, redirect: true }))

    // navigate to dashboard
    navigate('/')
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminLoginToken = params.get('token');
    const id = params.get('id')
    const email = params.get('email')
    const username = params.get('username')
    const role = params.get('role')

    if (adminLoginToken) {
      // function to  complete next login process
      handleTokenLogin(adminLoginToken, id, email, username, role)
    }
    console.log("[Auth] checking login status in login page");

    const token = localStorage.getItem('token')
    const userObject = localStorage.getItem("user")
    const userData = JSON.parse(userObject)

    if (userData && userData.email !== "") {
      login(userData)
    }

    if (token && isAuthenticated) {
      console.log(`[Auth] found already login user ${user}`);
    }
  },
    // [isAuthenticated, user]
    [isAuthenticated]
  );

  const handleSignIn = async (email: string, password: string, redirect: boolean) => {

    let data = await loginUser({ email, password, redirect }).catch(error => {
      console.log(error)
    })

    if (data) {
      if (data.success) {
        // store token and credentials into localstorage
        localStorage.setItem("token", JSON.stringify(data.token))
        localStorage.setItem("user", JSON.stringify(data.user))

        // set context data
        login(data.user)
        navigate('/')
      } else {
        setErrorMessage(data.error)
      }
    }
  }

  return (
    <Flex height="100vh" align="center" justify="center">
      <Box display={{ base: 'none', lg: 'block' }} width="75%" height="100vh">
        <Image src={LoginImage} alt="Background Image" objectFit="cover" height="100%" width="100%" />
      </Box>
      <VStack spacing={8} width={{ base: '100%', md: '50%' }} p={8}>
        <Box mb={8}>
          <Image src={AssesshubLogo} alt="Background Image" objectFit="contain" w="80" />
          <br />

          <Formik
            initialValues={{ email: "", password: "", redirect: false }}
            onSubmit={async (values) => {
              handleSignIn(values.email, values.password, values.redirect);
            }}
            validationSchema={LoginInfoSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Stack w="full" spacing={3}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <Input
                          {...field}
                          placeholder="Email address"
                          borderColor="pink.500"
                          maxLength={100}
                          minLength={5}
                        />
                        <FormErrorMessage>
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.password && form.touched.password}
                      >
                        <InputGroup size='md'>
                          <Input
                            {...field}
                            borderColor="pink.500"
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            placeholder='Password'
                          />
                          <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleShowClick}>
                              {show ? <IoMdEyeOff size="80%" /> : <IoMdEye size="80%" />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    type="submit"
                    colorScheme="pink" variant="solid" width="full" mt={2}
                    isDisabled={isSubmitting}
                  >
                    Login
                  </Button>
                  {errorMessage ? (
                    <Box bg="white" border="1px solid tomato" borderRadius="md">
                      <Flex align="center">
                        <Box px={2}>
                          <MdErrorOutline color="red" size={22} />
                        </Box>
                        <Text fontSize="sm" color="red" m={2}>
                          {errorMessage}</Text>
                      </Flex>
                    </Box>
                  ) : null}
                </Stack>
              </form>
            )}
          </Formik>

        </Box>
      </VStack>
    </Flex >
  );
};
