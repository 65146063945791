import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { colorSystem } from "../theme";

interface ChatBubbleProps extends BoxProps {
  sender?: "user" | "bot";
  shape?: "bubble" | "dialog";
  maskColor?: string;
  inverse?: boolean;
}

export const ChatBubble = ({
  maskColor = "#fff",
  shape = "dialog",
  inverse = false,
  sender = "user",
  ...props
}: ChatBubbleProps) => {
  const { textPrimary, surfacePurple } = colorSystem;

  const variant = inverse ? (sender === "bot" ? "user" : "bot") : sender;

  const bgColor =
    props.bg == undefined
      ? variant === "bot"
        ? "white"
        : surfacePurple
      : props.bg;

  const userMessage = {
    _before: {
      bottom: "-0.1rem",
      content: `""`,
      height: "1rem",
      position: "absolute",
      borderBottomLeftRadius: "0.8rem 0.7rem",
      borderRight: `1rem solid`,
      borderRightColor: bgColor,
      right: "-0.35rem",
      transform: "translate(0, -0.1rem)",
      width: "10px",
    },
    _after: {
      bottom: "-0.1rem",
      content: `""`,
      height: "1rem",
      position: "absolute",
      backgroundColor: maskColor,
      borderBottomLeftRadius: "0.5rem",
      right: "-40px",
      transform: "translate(-30px, -2px)",
      width: "10px",
    },
  };

  const botMessage = {
    _before: {
      bottom: "-0.1rem",
      content: `""`,
      height: "1rem",
      position: "absolute",
      borderBottomRightRadius: "0.8rem 0.7rem",
      borderLeft: `1rem solid`,
      borderLeftColor: bgColor,
      left: "-0.35rem",
      transform: "translate(0, -0.1rem)",
      width: "10px",
    },
    _after: {
      bottom: "-0.1rem",
      content: `""`,
      height: "1rem",
      position: "absolute",
      backgroundColor: maskColor,
      borderBottomRightRadius: "0.5rem",
      left: "20px",
      transform: "translate(-30px, -2px)",
      width: "10px",
    },
  };

  return (
    <Box
      px={4}
      py={2}
      maxW="75%"
      borderRadius="lg"
      alignSelf={variant === "bot" ? "flex-start" : "flex-end"}
      color={textPrimary}
      position="relative"
      wordBreak="break-word"
      _before={
        shape === "dialog"
          ? variant === "user"
            ? userMessage._before
            : botMessage._before
          : {}
      }
      _after={
        shape === "dialog"
          ? variant === "user"
            ? userMessage._after
            : botMessage._after
          : {}
      }
      {...props}
      bg={bgColor}
    >
      {props.children}
    </Box>
  );
};
