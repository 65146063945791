import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { DashboardEvent } from "../api/events";

interface DashboardEventBubbleProps extends TextProps {
  event: DashboardEvent;
}

export const DashboardEventBubble = ({
  event,
  ...props
}: DashboardEventBubbleProps) => {
  if (event.event === "AGENT_ASSIGNED") {
    const team = event.data?.["team"] ?? "";
    const teamText = team ? ` (${team} team) ` : " ";
    return (
      <Text {...props}>
        This chat was assigned to &nbsp;
        <b>
          {event.data?.["agent"]?.["name"] ?? "agent"}
          {teamText}
        </b>
        by &nbsp;
        <b>{event.data?.["assigned_by"] ?? "bot"}</b>
      </Text>
    );
  }

  if (event.event === "QUERY_CLOSED") {
    return (
      <Text {...props}>
        This chat was resolved by &nbsp;
        <b>{event.data?.["agent"]?.["name"] ?? "agent"}</b>
      </Text>
    );
  }

  if (event.event === "MICROFLOW_TRIGGERED") {
    return (
      <Text {...props}>
        Micro conversation <b>{event.data?.["name"] ?? ""}</b> started
      </Text>
    );
  }

  return <div>DashboardEventBubble</div>;
};
