import countryTelephoneData from "country-telephone-data";
import emojiFlags from "emoji-flags";
import { PhoneNumberUtil } from "google-libphonenumber";

let dialCodeToCountryMap = {};

countryTelephoneData.allCountries.map(
  (o) => (dialCodeToCountryMap[o.dialCode] = o)
);

const parseDialCodeFromPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(
    /(?:\+|00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/
  )?.[1];
};

export const getCountryFromPhoneNumber = (phoneNumber: string) => {
  let dialCode = parseDialCodeFromPhoneNumber(phoneNumber);
  if (!dialCode) return null;

  let country = dialCodeToCountryMap[dialCode];
  if (!country) return null;

  let emoji = emojiFlags.countryCode(country.iso2);
  if (!emoji) return country;

  return {
    ...country,
    ...emoji,
  };
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parse(`+${phoneNumber}`, "ZZ");
    return phoneUtil.isValidNumber(number);
  } catch (e) {
    return false;
  }
};
