import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";

import { SupportConversation } from "../../models/support";
import config from "../../config";

export const useSupportTicket = (sessionId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchSupportTicket = async () => {
    const res = await fetchWithAuth<SupportConversation>({
      url: `${config.url}/v2/support/user/${sessionId}`,
    });

    return res;
  };

  return useQuery(["support_ticket", sessionId], fetchSupportTicket, {
    keepPreviousData: true,
    refetchInterval: 1000 * 30, // 30 seconds
  });
};
