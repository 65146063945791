import React, { forwardRef } from "react";
import { BoxProps, Text } from "@chakra-ui/react";

import { useMessageTemplates } from "../../data/flow/useMessageTemplates";
import { DashboardTemplate } from "../../models";
import { WhatsAppTemplate } from "../WhatsAppTemplate";

interface TemplateChatMessageProps extends BoxProps {
  flowId: string;
  templateId: string;
  sourceId: string;
  params: string[];
  onTemplateFetch?: (template: DashboardTemplate) => void;
}

export const TemplateChatMessage = forwardRef<
  HTMLDivElement,
  TemplateChatMessageProps
>(({ flowId, sourceId, templateId, params, ...props }, _) => {
  // getting all templates to perform search
  const { isLoading, data } = useMessageTemplates({
    flowId,
    sourceId,
    limit: 10000,
  });

  const generateValuesFromParams = () => {
    let values: {
      [field: string]: {
        info: string;
        value: string;
      };
    } = {};

    params.map((param, idx) => {
      if (idx === 0) {
        values["name"] = {
          info: "name",
          value: param,
        };
      } else {
        values[idx + 1] = {
          info: `${idx + 1}`,
          value: param,
        };
      }
    });

    return values;
  };

  const getTemplate = () => {
    let template: DashboardTemplate | null = null;
    if (data && data.templates) {
      if (data.templates[templateId]) {
        template = data.templates[templateId];
      }
    }
    if (template) props.onTemplateFetch?.(template);

    return template;
  };

  if (isLoading) return <>{templateId}</>;

  if (data) {
    const template = getTemplate();

    if (template)
      return (
        <WhatsAppTemplate
          showTemplateStatus={false}
          showButtons={false}
          flowId={flowId}
          template={{ ...template, values: generateValuesFromParams() }}
          {...props}
        />
      );

    return <Text>{templateId}</Text>;
  }

  return <Text>{templateId}</Text>;
});
