import React, { useState } from "react";
import { WhatsAppTemplate } from "../../../components/WhatsAppTemplate";
import { useStoreActions, useStoreState } from "../../../hooks/store";
import { DashboardTemplate } from "../../../models";
import { colorSystem } from "../../../theme";
import { BaseInviteLayout, InviteViewProps } from "./BaseLayout";

export const EditTemplate = ({
  flowId,
  navigation,
  ...props
}: InviteViewProps) => {
  const cachedTemplate = useStoreState((state) => state.template);
  const storeTemplate = useStoreActions((actions) => actions.cacheTemplate);

  const [selectedTemplate, setSelectedTemplate] =
    useState<DashboardTemplate>(cachedTemplate);

  const handleTemplateEdit = (key: string, value: string) => {
    setSelectedTemplate((state) => {
      const newState = {
        ...state.values,
      };

      if (newState[key]) {
        let newStateValue = value.replace(/[!\n]/, "");
        newStateValue = newStateValue;
        newState[key].value = newStateValue;
      }

      return { ...state, values: newState };
    });
  };

  return (
    <>
      {navigation ? (
        <BaseInviteLayout
          className=""
          backButton={{ onClick: () => props.onBack?.() }}
          nextButton={{
            onClick: () => {
              storeTemplate(selectedTemplate);
              props.onNext?.();
            },
          }}
        >
          <WhatsAppTemplate
            className="bg-gray-50 self-center p-2 h-fit border rounded-lg cursor-pointer w-full"
            variant="edit"
            flowId={flowId}
            template={selectedTemplate}
            onValueChange={(key, value) => handleTemplateEdit(key, value)}
          />
        </BaseInviteLayout>
      ) : (
        <WhatsAppTemplate
          className="bg-gray-50 self-center p-2 h-fit rounded-lg cursor-pointer w-full"
          variant="edit"
          flowId={flowId}
          template={selectedTemplate}
          onValueChange={(key, value) => handleTemplateEdit(key, value)}
          border={`1px solid ${colorSystem["textPurple"]}`}
        />
      )}
    </>
  );
};
