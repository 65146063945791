import React from "react";
import { Stat, StatLabel, Text } from "@chakra-ui/react";
import { Card, LineChart } from "@tremor/react";

import { AnalyticsDataLoading } from ".";
import { FlowDropTurn } from "../../api/analytics";
import { useDropOff } from "../../data/analytics/useDropOff";

const parseDropOff = (
  flowDropRate?: FlowDropTurn[]
): { Turns: number; Users: number }[] => {
  if (!flowDropRate) return [];

  if (flowDropRate.length === 0) return [];

  return flowDropRate
    .filter((dropRate) => dropRate._id <= 20)
    .map((dropRate) => ({
      Turns: dropRate._id,
      Users: dropRate.user,
    }));
};

interface DropoffProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  dateRange: { start_date: number; end_date: number };
}

export const DropOff = ({ flowId, dateRange, ...props }: DropoffProps) => {
  const { refetch, isLoading, data } = useDropOff(flowId, dateRange);

  if (isLoading) {
    return <AnalyticsDataLoading title="Drop Off Graph" />;
  }

  //   if (data && !Array.isArray(data)) {
  //     if (data.type === "error") {
  //       return (
  //         <AnalyticsDataFailed
  //           title="Live Users"
  //           errorMessage={"Something went wrong while fetching live users data"}
  //           onRetry={() => refetch()}
  //         />
  //       );
  //     }

  //     return <AnalyticsDataLoading title="Live Users" />;
  //   }

  if (data && Array.isArray(data))
    return (
      <Card {...props}>
        <Stat>
          <StatLabel>Drop Off Graph</StatLabel>
        </Stat>
        <LineChart
          className="mt-6 w-full h-64"
          data={parseDropOff(data)}
          index="Turns"
          colors={["purple"]}
          categories={["Users"]}
          noDataText={
            isLoading
              ? "Please wait while we load user retention"
              : "No data available"
          }
        />
        <Text className="text-gray-400 text-center self-center">Turns</Text>
      </Card>
    );
};
