import { ConversationComponent } from "../pages/Flow";

interface Intent {
  type: string;
  body: string;

  /**
   * where to store the user response
   * TODO: interface to edit answer field
   */
  answer_field?: string;
  userResponse?: string;
  acknowledgement?: string;
}

interface Ask extends Intent {
  type: "ask";
  answer_field?: "";
}

interface Say extends Intent {
  type: "say";
}

interface Delay extends Intent {
  type: "delay";
  // "Delay for the next step",
  delay: string;
}

export interface BranchButton {
  title: string;
  response: {
    type: "say";
    body: string;
  };
}

export interface AskOptions extends Intent {
  type: "ask_in_options";
  options?: BranchButton[];
  option_type?: "button" | "list";
  intents?: {
    [optionName: string]: IntentUnion[];
  };
}

export interface Branch extends Intent {
  type: "branch";
  branches?: string[];
  intents?: {
    [branchName: string]: IntentUnion[];
  };
}

export type MediaType = "image" | "video" | "audio";

export interface Media extends Intent {
  type: "media";
  media: string;
  media_type?: MediaType;
  path?: string;
}

export interface MediaInput {
  type: MediaType;
  url: string;
  key: string
  bucket: string
}

export interface AITurn {
  question: string; // Question that AI asks to interact with user
  reply: string; //User reply to the question that AI asked"
  hallucination: string; // Thoughts of AI to the given user reply
  acknowledgement: string; // AI showing that it heard user and understand
}

export interface AIAsk extends Intent {
  type: "ai_ask";
  as_: "ask" | "probe";
  persona: string; // "AI Persona"
  tone: string; // Tone the bot should address
  context: string; // Drive the conversation towards the given context
  turns: AITurn[]; //Turns of the conversation
}

export interface PromptChain extends Intent {
  type: "prompt_chain";
  goal: string; // "Global Goal of the conversation")
  constraints: string; // "Global Constraints of the conversation"
  components: ConversationComponent[]; // List of Conversation Components
}

interface DefaultIntent extends Intent {
  type: "ask_and_acknowledge";
}

export type IntentUnion =
  | AIAsk
  | AskOptions
  | Media
  | Ask
  | Say
  | Branch
  | Delay
  | PromptChain
  | DefaultIntent;

export const ask = (body: string, userResponse: string = "") => {
  const ask_intent: Ask = {
    type: "ask",
    body: body,
  };

  ask_intent.userResponse = userResponse;
  return ask_intent;
};

export const say = (body: string) => {
  const say_intent: Say = {
    type: "say",
    body: body,
  };

  return say_intent;
};

export const branch = (body: string, options: string[] = []) => {
  const ask_intent: Branch = {
    type: "branch",
    body: body,
    branches: options,
  };

  if (options.length !== 0) {
    ask_intent.userResponse = options[0];
    ask_intent.intents = {};
    options.map((option) => (ask_intent.intents[option] = []));
  }

  return ask_intent;
};

export const ask_and_acknowledge = (
  body: string,
  persona: string,
  tone: string,
  context: string = "",
  default_acknowledge: string = ""
) => {
  const ask_intent: AIAsk = {
    type: "ai_ask",
    body: body,
    context: context,
    as_: "ask",
    persona: persona,
    tone: tone,
    turns: [],
  };

  ask_intent.acknowledgement = default_acknowledge;
  return ask_intent;
};

export const ask_like = (
  body: string,
  persona: string,
  tone: string,
  context: string
) => {
  const ask_intent: AIAsk = {
    type: "ai_ask",
    body: body,
    context: context,
    persona: persona,
    tone: tone,
    as_: "probe",
    turns: [],
  };

  return ask_intent;
};

export const time_delay = (delay: string, body: string) => {
  const delay_intent: Delay = {
    type: "delay",
    body,
    delay,
  };

  return delay_intent;
};

export interface MicroFlow {
  name: string;
  intents: IntentUnion[];
  data?: {};
  default?: boolean;
}
