import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import { DashboardTemplate } from "../../models";
import config from "../../config";

interface MessagingTemplateProps {
  flowId: string;
  sourceId?: string;
  limit?: number;
}

export const useMessageTemplates = ({
  flowId,
  sourceId,
  limit = 1000,
}: MessagingTemplateProps) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchMessageTemplates = async () => {
    const response = await fetchWithAuth<{
      status: "success" | "failed";
      templates: { [templateId: string]: DashboardTemplate };
      last_document_index?: number;
    }>({
      url: `${config.url}/v2/flow/templates/messages/${flowId}`,
      config: {
        params: {
          limit,
          offset: 0,
          source_id: sourceId,
        },
      },
    });

    return response;
  };

  return useQuery(
    ["message_templates", flowId, sourceId],
    fetchMessageTemplates,
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 60 * 8, // 8 hours
    }
  );
};
