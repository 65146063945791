import React, { forwardRef } from "react";
import { Stack, StackProps, Image, Text, Tooltip } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { colorSystem } from "../../theme";

interface AppRouteProps extends StackProps {
  title: string;
  route: string;
  icon?: string;
  variant?: "dock" | "default";
  size?: "sm" | "md";
}

const { textPrimary, selectedGrey, hoverGrey } = colorSystem;

export const AppRoute = forwardRef<HTMLDivElement, AppRouteProps>(
  ({ title, icon, variant = "default", size = "md", route, ...props }, ref) => {
    const sizeProps = size === "sm" ? { py: 1 } : { py: 2 };

    return (
      <Tooltip
        ref={ref}
        label={variant === "dock" && title}
        hasArrow
        placement="right"
      >
        <Stack
          as={NavLink}
          direction="row"
          transition="background 20ms ease-in 0s"
          color={textPrimary}
          _hover={{
            bg: "gray.300",
          }}
          px={3}
          to={route}
          replace
          _activeLink={{
            bg: "gray.300",
          }}
          align="center"
          borderRadius="md"
          {...sizeProps}
          {...props}
        >
          {icon && <Image w="1.5rem" src={icon} alt="Dan Abramov" />}
          {variant === "default" && (
            <Text fontWeight="600" fontSize=".875rem">
              {title}
            </Text>
          )}
        </Stack>
      </Tooltip>
    );
  }
);
