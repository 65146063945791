import React from "react";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Card, AreaChart } from "@tremor/react";

import { AnalyticsDataFailed, AnalyticsDataLoading, formatDate } from ".";
import { ReplyRateStat, useReplyRate } from "../../data";

const parseReplyRate = (
  readReceipts: ReplyRateStat[]
): ReplyRateStat[] => {
  if (!readReceipts) return [];

  if (readReceipts.length === 0) return [];

  return readReceipts.map(({ ReplyRate, Timestamp }) => ({
    type: "data",
    ReplyRate: ReplyRate,
    Timestamp: formatDate(Timestamp),
  }));
};

interface ReplyRateProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  campaignFilter: string;
}

export const ReplyRate = ({
  flowId,
  campaignFilter,
  ...props
}: ReplyRateProps) => {
  const { refetch, isLoading, data } = useReplyRate(flowId, campaignFilter);

  if (!campaignFilter || campaignFilter == "") {
    return (
      <AnalyticsDataFailed
        title="Reply %"
        errorMessage={"Select a campaign to see the reply %"}
      />
    );
  }

  if (isLoading) {
    return <AnalyticsDataLoading title="Reply %" />;
  }

  if (data && !Array.isArray(data)) {
    if (data.type === "error") {
      return (
        <AnalyticsDataFailed
          title="Reply %"
          errorMessage={"Something went wrong while getting reply %"}
          onRetry={() => refetch()}
        />
      );
    }

    return <AnalyticsDataLoading title="Reply %" />;
  }

  if (data && Array.isArray(data)) {

    let mostRecentValueStr;
    let mostRecentValue = data?.[data.length - 1]?.ReplyRate ?? 0;
    if (mostRecentValue == 0) {
      mostRecentValueStr = Intl.NumberFormat(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 5 }).format(mostRecentValue);
    } else {
      mostRecentValueStr = mostRecentValue.toFixed();
    }

    return (
      <Card {...props}>
        <Stat>
          <StatLabel>Reply %</StatLabel>
          <StatNumber>
            {mostRecentValueStr}
          </StatNumber>
        </Stat>
        <AreaChart
          className="h-48 mt-4"
          data={parseReplyRate(data)}
          index="Timestamp"
          categories={["ReplyRate"]}
          colors={["purple"]}
          noDataText={
            isLoading
              ? "Please wait while we load reply %"
              : "No replies in this time range"
          }
        />
      </Card>
    );
  }
};
