import React from "react";
import { Heading, Stack } from "@chakra-ui/react";
import { Button } from "../../components/Button";
import { AddIcon } from "../../constants/icons";

type Props = {};

export const Whatsapp = (props: Props) => {
  return (
    <Stack spacing={8} p={4} m={0}>
      <Stack direction="row" align="center" justify="space-between">
        <Heading size="md">Whatsapp</Heading>
        <Button leftIcon={<AddIcon />}>New Template</Button>
      </Stack>
      <Stack
        justify="center"
        align="center"
        borderRadius="md"
        boxShadow="sm"
        bg="pink.100"
        h="lg"
      >
        <Heading textAlign="center" py={2} w="full" size="md">
          🚧 Under Construction
        </Heading>
      </Stack>
    </Stack>
  );
};
