import { Box, Input } from "@chakra-ui/react";
import React from "react";
import { WHButtonProps, Button } from "../Button";

interface Props extends WHButtonProps {
  fileType?: string;
  onFileUpload?: (file?: File) => void;
}

export const FileInputButton = ({
  fileType = "image/png, image/jpeg",
  onFileUpload,
  ...props
}: Props) => {
  return (
    <Box
      pos="relative"
      _hover={{ bg: "pink.50", cursor: "pointer", borderRadius: "md" }}
    >
      <Button aria-label="add image" {...props} />
      <Input
        pos="absolute"
        top={0}
        left={0}
        type="file"
        aria-hidden="true"
        accept={fileType}
        size="sm"
        opacity={0}
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            return onFileUpload?.(file);
          }
        }}
      />
    </Box>
  );
};
