import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ResetUsers = () => {
  const {id: flowId} = useParams()
  const navigate = useNavigate()

  useEffect(() => navigate(`/dashboard/${flowId}/users/`, {replace: true}), [])

  return <div>reset_users</div>;
};
