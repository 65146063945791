import React from "react";
import { HiSpeakerphone } from "react-icons/hi";

import {
  CampaignDataStat,
  useCampaignConfig,
} from "../../data/analytics/useConfig";
import { Button } from "../Button";
import { TagGroup } from "../TagGroup";
interface SelectCampaignProps {
  flowId: string;
  isActive?: boolean;
  onChange?: (campaignId: string) => void;
  selectedCampaign: string;
  setSelectedCampaign: (string) => void;
}

export const SelectCampaign = ({
  flowId,
  isActive = false,
  selectedCampaign,
  setSelectedCampaign,
  ...props
}: SelectCampaignProps) => {
  const campaignPicker = (
    campaigns: CampaignDataStat[],
    campaignName: string
  ) => {
    console.log(
      `campaigns ${campaigns.map(
        (campaign) => `${campaign.name} ${campaign.campaign_id}`
      )}`
    );
    console.log(`selected campaign ${campaignName}`);

    const filteredCampaign = campaigns.filter(
      (campaign) => campaign.name === campaignName
    );

    console.log(filteredCampaign);

    return filteredCampaign.length === 0 ? "" : filteredCampaign[0].campaign_id;
  };

  const { isLoading, data } = useCampaignConfig(flowId);

  const getCampaigns = () => {
    if (Array.isArray(data)) {
      return data;
    }

    return [];
  };

  return (
    <TagGroup
      placeholder="Select Value"
      tags={selectedCampaign}
      options={getCampaigns().map((data) => data.name)}
      onChange={(selectedOptions) =>
        typeof selectedOptions === "string"
          ? setSelectedCampaign(selectedOptions)
          : setSelectedCampaign("")
      }
      allowNewTag={false}
    >
      <Button
        leftIcon={<HiSpeakerphone />}
        variant={isActive ? "primary" : "secondary"}
      >
        Campaign Filters
      </Button>
    </TagGroup>
  );
};
