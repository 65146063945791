import React, { forwardRef } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Stack,
  useDisclosure,
  ModalHeader,
  IconButton,
  BoxProps,
  Box,
  TextProps,
} from "@chakra-ui/react";
import Plyr from "plyr-react";
import { isUrl, getUrlType } from "../utils/helpers";
import { ViewIcon, CheckIcon, ExportIcon } from "../constants/icons";

interface CandidateSummaryTextProps extends BoxProps {
  text: string;

  textProps?: TextProps;
}

const CandidateSummaryText = forwardRef<
  HTMLDivElement,
  CandidateSummaryTextProps
>(({ text, textProps, ...props }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalContentType = getUrlType(text);

  return (
    <Box ref={ref} {...props}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={Stack} direction="row" justify="space-between">
            <Text>
              {modalContentType === "video" ? "Watch video" : "View document"}
            </Text>
            <IconButton
              onClick={() => window.open(text)}
              size="sm"
              aria-label={"download"}
            >
              <ExportIcon size={12} />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            {modalContentType === "video" ? (
              <Plyr
                source={{
                  type: "video",
                  sources: [
                    {
                      src: text,
                      type: "video/mp4",
                    },
                  ],
                }}
                options={{
                  ratio: "4:3",
                }}
              />
            ) : (
              <embed className="w-full h-96 border-none" src={text} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isUrl(text) ? (
        <Button
          onClick={() => {
            console.log(`detected url: ${text}`);
            let type = getUrlType(text);
            console.log(type);
            type === "social" ? window.open(text) : onOpen();
          }}
          variant="link"
          size="sm"
          color="pink.600"
        >
          <ViewIcon />
        </Button>
      ) : (
        <Text
          style={{
            whiteSpace: "break-spaces",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            textAlign: "left",
          }}
          {...textProps}
        >
          {text === "Yes" ? <CheckIcon color="green" /> : text}
        </Text>
      )}
    </Box>
  );
});

export default CandidateSummaryText;
