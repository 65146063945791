import React, { forwardRef } from "react";
import {
  Stack,
  HStack,
  Text,
  Tag,
  TagLabel,
  StackProps,
} from "@chakra-ui/react";
import { UserSummary } from "../../data/user/useUserData";
import { Button } from "../Button";
import { useBlockUser } from "../../mutations/support/useBlockUser";
import { useUpdateUserSummary } from "../../mutations/users/useUpdateUserSummary";
import { useFeatureFlags } from "../../data/flags/useFeatureFlags";
import { ORDER_CREATED_ACTION } from "../../constants";

interface UserProfileHeaderProps extends StackProps {
  flowId: string;
  userId: string;
  sessionId: string;
  profile?: UserSummary;
}

export const UserProfileHeader = forwardRef<
  HTMLDivElement,
  UserProfileHeaderProps
>(({ flowId, userId, sessionId, profile, ...props }, ref) => {
  const queryKey = ["users", flowId, sessionId];

  const { data: featureFlags } = useFeatureFlags(flowId);

  const blockMutation = useBlockUser({ flowId, refetchKey: queryKey });
  const updateUserDetails = useUpdateUserSummary({
    flowId,
    refetchKey: ["users", flowId, sessionId],
  });

  const isFeatureEnabled = (feature: string) => {
    console.log(featureFlags);
    return (featureFlags?.flags ?? []).includes(feature);
  };

  return (
    <Stack ref={ref} px={4} spacing={0} {...props}>
      <p className="h-2" />
      <HStack>
        <Text maxW="32" fontSize="lg" fontWeight="medium">
          {profile?.table?.["Name"] ?? ""}
        </Text>
        {isFeatureEnabled(ORDER_CREATED_ACTION) ? (
          <Tag
            size="sm"
            borderRadius="full"
            variant="solid"
            colorScheme={
              (profile?.table?.["Status"] ?? "New Lead") === "Order Created"
                ? "green"
                : "gray"
            }
          >
            <TagLabel>{profile?.table?.["Status"] ?? "New Lead"}</TagLabel>
          </Tag>
        ) : (
          <Tag
            hidden={!(profile?.blocked ?? true)}
            size="sm"
            borderRadius="full"
            variant="solid"
            colorScheme="red"
          >
            <TagLabel>Blocked</TagLabel>
          </Tag>
        )}
      </HStack>
      <Text className="text-gray-400" fontSize="md">
        +{profile.table?.["Phone Number"] ?? ""}
      </Text>
      <p className="h-5" />
      {isFeatureEnabled(ORDER_CREATED_ACTION) ? (
        <Button
          isLoading={updateUserDetails.isLoading}
          onClick={() =>
            updateUserDetails.mutate({
              data: {
                dashboard_status: "Order Created",
                // dashboard_status: profile?.blocked ?? true ? "New Lead" : "Order Created",
              },
              sessionId,
            })
          }
        >
          Mark as order created
        </Button>
      ) : (
        <Button
          isLoading={blockMutation.isLoading}
          action="delete"
          variant="danger"
          onClick={() => blockMutation.mutate(sessionId)}
        >
          {profile.blocked ? "Unblock" : "Block"}
          <Text px={1} maxW="28" isTruncated>
            {profile?.table?.["Name"] ?? ""}
          </Text>
        </Button>
      )}
    </Stack>
  );
});
