import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const useAttributeKeys = (flowId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchAttributeKeys = async () => {
    const res = await fetchWithAuth<string[]>({
      url: `${config.url}/v2/attributes/attribute_keys/${flowId}`,
    });

    return res;
  };

  return useQuery(["flow", "user_attribute_keys", flowId], fetchAttributeKeys, {
    refetchInterval: 1000 * 30, // 30 seconds
  });
};
