import React, { useContext } from "react";
import { Box, HStack, Stack } from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";

import { productBackgroundNeutral } from "../../../theme";
import { Appbar } from "../../../components/Appbar";
import { Button } from "../../../components/Button";
import { AppRoute } from "../../../components/Navigation/Route";
import { PageContext } from "../../../router/layout";

export const Settings = () => {
  const { botId, id } = useParams();
  const isPageDocked = useContext(PageContext);
  const flowId = botId ?? id;
  
  return (
    <HStack
      h="100vh"
      spacing={0}
      bg={productBackgroundNeutral}
      maxW={`calc(100vw - ${isPageDocked ? "5rem" : "15rem"})`}
      overflowX="scroll"
    >
      <Stack
        className="max-h-full overflow-y-scroll"
        spacing={0}
        h="100%"
        bg={productBackgroundNeutral}
        borderRightWidth="thin"
        minW="16rem"
      >
        <Appbar
          className="overflow-y-scroll static w-full"
          boxShadow="sm"
          borderBottomWidth="thin"
          variant="sticky"
          title="Settings"
          trailing={
            <Button visibility="hidden" size="sm">
              Assign
            </Button>
          }
        />
        <Box p={4}>
          <AppRoute
            title="General"
            route={`/dashboard/${flowId}/settings/`}
            size="sm"
          />
          <AppRoute
            title="Members"
            route={`/dashboard/${flowId}/settings/support/teams`}
            size="sm"
          />
          <AppRoute
            title="Quick Replies"
            route={`/dashboard/${flowId}/settings/support/quick_replies`}
            size="sm"
          />
        </Box>
      </Stack>
      <Outlet />
    </HStack>
  );
};
