import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  SimpleGrid,
  Heading,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Appbar } from "../../../components/Appbar";
import { TextField } from "../../../components/TextField";
import { Button } from "../../../components/Button";
import { AddIcon } from "../../../constants/icons";
import { RenderConversationComponent } from "./Component";
import { ConversationComponent } from ".";
import { ChatWidget } from "../../../components/ChatWidget";
import {
  defaultExampleTurns,
  SOCKET_USER,
  SOCKET_SOURCE,
} from "../../../constants";
import { saveMicroFlow } from "../../../api/flow";
import {
  PostEngineSessionRequest,
  createNewSession,
} from "../../../api/engine";
import { useStoreActions, useStoreState } from "../../../hooks/store";
import {
  getFlowPlayground,
  generateContextFromComponent,
} from "../../../api/playground";
import { useAuthToken } from "../../../hooks/useAuthToken";

const addBotPrefix = (
  components: ConversationComponent[],
  prefix: string = "You ask: "
) => {
  // add bot prefix to all user turns
  return components.map((component) => ({
    ...component,
    examples: component.examples.map((example) => ({
      ...example,
      turns: example.turns.map((turn) => {
        if (turn.type === "message") {
          if (turn.role === "assistant") {
            return { ...turn, content: `${prefix}${turn.content}` };
          }
        }

        return turn;
      }),
    })),
  }));
};

export const Playground = () => {
  const { id: flowId } = useParams();
  const message = useToast();
  const { getAuthToken } = useAuthToken();

  const cachedPlayground = useStoreState((state) => state.playground[flowId]);
  const cachePlayground = useStoreActions((actions) => actions.cachePlayground);

  const [goal, setGoal] = useState<string>("");
  const [constraints, setConstraints] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [components, setComponents] = useState<ConversationComponent[]>([]);

  const startTestingComponents = async () => {
    // TODO: option to test all components or just one
    const token = localStorage.getItem('token')

    const response = await saveMicroFlow({
      flowId,
      token,
      flow: {
        name: `prompt_chain_${flowId}`,
        intents: [
          {
            type: "prompt_chain",
            body: `testing ${flowId} prompt chaining`,
            goal,
            constraints,
            components: addBotPrefix(components),
          },
        ],
      },
    });

    if (response) {
      const startNewSession = async () => {
        const token = localStorage.getItem('token')
        let request: PostEngineSessionRequest = {
          flowId,
          token,
          source_id: SOCKET_SOURCE,
          user_id: SOCKET_USER,
          current_flow_step: response.start,
          current_state: "INTENT_AWAITING_RESPONSE",
        };

        const _ = await createNewSession(request);

        if (_) {
          return message({
            title: "You can now start testing",
            status: "success",
            position: "bottom-right",
          });
        }
      };

      startNewSession();
    }
  };

  const addNewComponent = async () => {
    const newComponent: ConversationComponent = {
      goal: "",
      name: "",
      examples: [
        {
          turns: [...defaultExampleTurns],
        },
        {
          turns: [...defaultExampleTurns],
        },
        {
          turns: [...defaultExampleTurns],
        },
      ],
    };

    if (components.length === 0) setComponents([...components, newComponent]);
    else extractContextFromPreviousComponent(newComponent);
  };

  const extractContextFromPreviousComponent = async (
    newComponent: ConversationComponent
  ) => {
    const previousComponent = components[components.length - 1];

    const handleError = () => {
      setLoading(false);
      setComponents([...components, newComponent]);
    };

    try {
      setLoading(true);
      const context = await generateContextFromComponent(previousComponent);
      setLoading(false);

      if (context) {
        // adding context to the example
        newComponent.examples.map((example, index) => {
          example.context = context[index] ?? "";
        });

        return setComponents([...components, newComponent]);
      }

      handleError();
    } catch (error) {
      console.log(error);
      handleError();
    }
  };

  useEffect(() => {
    cachePlayground({
      flowId,
      components,
      goal,
      constraints,
    });
  }, [components, goal, constraints]);

  useEffect(() => {
    const loadPlayground = async () => {
      const response = await getFlowPlayground(flowId);

      if (response) {
        setGoal(response.goal);
        setConstraints(response.constraints);
        setComponents(response.components);
      }
    };

    // load cached playground
    if (cachedPlayground) {
      if (cachedPlayground.components.length === 0) {
        loadPlayground();
        return;
      }

      setGoal(cachedPlayground.goal);
      setConstraints(cachedPlayground.constraints);
      setComponents(cachedPlayground.components);
    } else {
      // load from server
      loadPlayground();
    }
  }, []);

  return (
    <SimpleGrid bg="white" gridTemplateColumns="minmax(0,3fr) minmax(0,1.3fr)">
      <Stack h="100vh" spacing={0} borderRightWidth="thin">
        <Appbar
          title="Playground"
          boxShadow="sm"
          borderBottomWidth="thin"
          variant="sticky"
          trailing={
            <Button visibility="hidden" size="sm">
              Assign
            </Button>
          }
        />

        {/* Global goal and constraints  */}
        <Box py={8} px={16}>
          <HStack
            bg="#8778F7"
            align="start"
            boxShadow="md"
            borderRadius="md"
            px={8}
            spacing={8}
          >
            <Stack py={8} flex={1} borderRadius="md">
              <Heading size="md" color="black">
                goals
              </Heading>
              <TextField
                bg={`white !important`}
                borderColor="blackAlpha.300"
                boxShadow="md"
                rows={2}
                value={goal}
                onChange={(event) => setGoal(event.target.value)}
                placeholder="Eg. Selling products from creators "
              />
            </Stack>
            <Stack py={8} flex={1} borderRadius="md">
              <Heading size="md" color="black">
                rules
              </Heading>
              <TextField
                bg={`white !important`}
                borderColor="blackAlpha.300"
                boxShadow="md"
                rows={2}
                value={constraints}
                onChange={(event) => setConstraints(event.target.value)}
                placeholder="Eg. Generate replies that are no more than 30 words "
              />
            </Stack>
          </HStack>
        </Box>

        {/* Components */}
        <Stack flex={1} p={6} overflowY="scroll" spacing="8">
          {components.map((component, idx) => (
            <RenderConversationComponent
              key={idx}
              component={component}
              onChange={(updatedComponent) =>
                setComponents((prevState) =>
                  prevState.map((c, i) => (i === idx ? updatedComponent : c))
                )
              }
              onRemove={() =>
                setComponents((prevState) =>
                  prevState.filter((c, i) => i !== idx)
                )
              }
            />
          ))}
        </Stack>

        <Appbar
          variant="sticky"
          placement="bottom"
          showHeader={false}
          h="full"
          _after={{
            content: `""`,
            background: "linear-gradient(to bottom,transparent,#fafafa 70%)",
            zIndex: -100,
            width: "100%",
            left: "-10",
            height: "200px",
            bottom: "0",
            pointerEvents: "none",
            position: "absolute",
          }}
        >
          <Stack py="4" w="full">
            <Button
              alignSelf="center"
              leftIcon={<AddIcon />}
              isLoading={loading}
              onClick={() => addNewComponent()}
            >
              component
            </Button>
          </Stack>
        </Appbar>
      </Stack>

      <ChatWidget>
        <Appbar
          title="Testing"
          boxShadow="sm"
          borderBottomWidth="thin"
          variant="sticky"
          trailing={
            <Button
              size="sm"
              disabled={components.length === 0}
              onClick={() => startTestingComponents()}
            >
              Test
            </Button>
          }
        />
      </ChatWidget>
    </SimpleGrid>
  );
};
