import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const useAttributeValues = (
  flowId: string,
  attributeKey: string,
  isExternal: boolean = true
) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchAttributeValues = async () => {
    const res = await fetchWithAuth<string[]>({
      url: `${config.url}/v2/attributes/attribute_values/${flowId}/${attributeKey}`,
      config: {
        params: {
          external: isExternal,
        },
      },
    });

    return res;
  };

  return useQuery(
    ["flow", "user_attribute_values", flowId, attributeKey],
    fetchAttributeValues,
    {
      refetchInterval: 1000 * 30, // 30 seconds
    }
  );
};
