import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

export interface AuthData {
  id: string;
  username: string;
  email: string;
  role: string;
  redirect: boolean;
}

export type AuthContextType = {
  user: AuthData;
  setUser: (user: AuthData | null) => void;
  login: (user: AuthData) => void;
  logout: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
};

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthData>({
    id: '',
    username: '',
    email: '',
    role: '',
    redirect: false,
  });

  const login = (userData: AuthData) => {
    const newUser: AuthData = {
      id: userData.id,
      email: userData.email,
      username: userData.username,
      role: userData.role,
      redirect: userData.redirect ? userData.redirect : false,
    };

    setUser(newUser);
    setIsAuthenticated(true);
  };

  const logout = () => {
    setUser({ id: '', email: '', username: '', role: '', redirect: false });
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
