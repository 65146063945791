import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Skeleton } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, position = "bottom", loading = false }) => {
  return (
    <Skeleton isLoaded={!loading}>
      <Pie
        options={{
          plugins: {
            legend: {
              position: position,
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
              },
            },
          },
        }}
        data={data}
      />
    </Skeleton>
  );
};

export default PieChart;
