import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const useUserNotesCount = (flowId: string, userId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchNotes = async () => {
    const res = await fetchWithAuth<{
      count: number;
    }>({
      url: `${config.url}/v2/support/${flowId}/user_notes_count/${userId}`,
    });

    return res;
  };

  return useQuery(["user", "notes", "count", flowId, userId], fetchNotes, {
    keepPreviousData: true,
    refetchInterval: 1000 * 60, // 1 minute
  });
};
