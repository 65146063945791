import React from "react";
import { IconButton, Stack, StackProps, Text, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { BackIcon } from "../constants/icons";
import { colorSystem } from "../theme";
import { FlowInfo } from "../hooks/useFlowInfo";

interface FlowSwitcherProps extends StackProps {
  flowInfo: FlowInfo;
  variant?: "default" | "dock";
}

export const FlowSwitcher = ({
  flowInfo,
  variant = "default",
  ...props
}: FlowSwitcherProps) => {
  const navigate = useNavigate();
  const { textPrimary, borderGrey } = colorSystem;

  return (
    <Stack
      p={4}
      direction="row"
      align="center"
      spacing={4}
      bg="transparent"
      {...props}
    >
      <IconButton
        size="sm"
        onClick={() => navigate(`/`)}
        icon={<BackIcon />}
        aria-label={"backicon"}
        variant="outline"
        borderColor={borderGrey}
      />
      <Stack hidden={variant === "dock"} align="center" spacing={0}>
        <Text
          maxW="full"
          textOverflow="ellipsis"
          color={textPrimary}
          fontWeight="700"
          fontSize="1.125rem"
          lineHeight="140%"
        >
          {flowInfo.name}
        </Text>
        {/* <Text color={textSubdue}>{flowInfo.count} users</Text> */}
      </Stack>
      {/* TODO: Switch */}
      {/* <IconButton aria-label={""} /> */}
    </Stack>
  );
};
