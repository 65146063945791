import React from "react";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Card, BarChart } from "@tremor/react";

import { AnalyticsDataFailed, AnalyticsDataLoading, formatDate } from ".";
import { LinkClickStat, useHypdLinkClicks } from "../../data";

const parseLinkClicks = (linkClicks: LinkClickStat[]): LinkClickStat[] => {
  if (!linkClicks) return [];

  if (linkClicks.length === 0) return [];

  return linkClicks.map(({ ClickCount, Timestamp }) => ({
    type: "data",
    ClickCount,
    Timestamp: formatDate(Timestamp),
  }));
};

interface LinkClicksProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  range: { start_date: number; end_date: number } | string;
}

export const HypdLinkClicks = ({ flowId, range, ...props }: LinkClicksProps) => {
  const { refetch, isLoading, data } = useHypdLinkClicks(flowId, range);

  if (isLoading) {
    return <AnalyticsDataLoading title="Total Link clicks" />;
  }

  if (data && !Array.isArray(data)) {
    if (data.type === "error") {
      return (
        <AnalyticsDataFailed
          title="Total Link clicks"
          errorMessage={"Something went wrong while getting link clicks"}
          onRetry={() => refetch()}
        />
      );
    }

    return <AnalyticsDataLoading title="Total Link clicks" />;
  }

  if (data && Array.isArray(data))
    return (
      <Card {...props}>
        <Stat>
          <StatLabel>Total Link clicks</StatLabel>
          <StatNumber>
            {(data?.reduce((sum, a) => sum + a.ClickCount, 0) ?? 0).toLocaleString()}
          </StatNumber>
        </Stat>
        <BarChart
          className="h-48 mt-4"
          data={parseLinkClicks(data)}
          index="Timestamp"
          categories={["ClickCount"]}
          colors={["purple"]}
          noDataText={
            isLoading
              ? "Please wait while we load link clicks"
              : "No link clicks in this time range"
          }
        />
      </Card>
    );
};
