import React, { useEffect, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

interface SortUpDownProps {
  color?: string;
  activeColor?: string;
  onSort?: (order: "asc" | "desc") => void;
}

export const SortUpDown = ({
  color = "#000",
  activeColor = "#e57bd8",
  ...props
}: SortUpDownProps) => {
  const [order, setOrder] = useState<"asc" | "desc">();

  useEffect(() => {
    props.onSort?.(order);
  }, [order]);

  return (
    <Stack m={0} justify="center" spacing={0}>
      <Box h="6px">
        <BiChevronUp
          cursor="pointer"
          color={order === "asc" ? activeColor : color}
          onClick={() => setOrder("asc")}
        />
      </Box>
      <Box>
        <BiChevronDown
          cursor="pointer"
          color={order === "desc" ? activeColor : color}
          onClick={() => setOrder("desc")}
        />
      </Box>
    </Stack>
  );
};
