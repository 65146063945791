import React, { forwardRef } from "react";
import { HStack, Stack, StackProps, Text } from "@chakra-ui/react";
import { FilterConditionComponent } from "../Filter/condition";
import { useAttributeValues } from "../../data/flow/useAttributeValues";
import { FilterQuery } from "../Filter/models";

export interface AttributeFilterQuery extends FilterQuery {
  isExternal?: boolean;
}

interface SelectAttributeValueProps extends Omit<StackProps, "onChange"> {
  flowId: string;

  attributeKey: string;

  attributeTitle?: string;

  isExternal?: boolean;

  onRemove?: () => void;

  onChange?: (query: AttributeFilterQuery) => void;
}

export const SelectAttributeValue = forwardRef<
  HTMLDivElement,
  SelectAttributeValueProps
>(
  (
    {
      flowId,
      attributeKey,
      attributeTitle,
      isExternal = true,
      onRemove,
      onChange,
      ...props
    },
    ref
  ) => {
    const { data, isLoading } = useAttributeValues(
      flowId,
      attributeKey,
      isExternal
    );

    if (isLoading) {
      return (
        <HStack>
          <Text px={4}>{attributeTitle}</Text>
        </HStack>
      );
    }

    return (
      <Stack ref={ref} {...props}>
        <FilterConditionComponent
          flex={1}
          setDefault={false}
          condition={{
            key: attributeKey,
            name: attributeTitle ?? attributeKey,
            render_as: "tags",
            type: "array",
            options: data,
          }}
          onRemove={onRemove}
          onChange={(field, operation, value) =>
            onChange?.({
              key: field.key,
              operation: operation === "is" ? "in" : "nin",
              value,
              isExternal,
            })
          }
        />
      </Stack>
    );
  }
);
