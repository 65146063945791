import React from "react";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";
import { Card, AreaChart } from "@tremor/react";

import { AnalyticsDataFailed, AnalyticsDataLoading, formatDate } from ".";
import { MessageDeliveryStat, useMessageDelivery } from "../../data";

type MessageDeliverySummaryStat = {
  DeliveryRate: number;
  Timestamp: string;
};

const getAggregateCount = (
  data: MessageDeliveryStat[],
  status: "Delivered" | "Sent" | "Failed"
) => {
  if (!data) return 0;
  
  return data
    .reduce(
      (total, { Summary }) =>
        total +
        (status === "Delivered"
          ? Summary?.Delivered ?? 0
          : status === "Sent"
          ? Summary?.Sent ?? 0
          : Summary?.Failed ?? 0),
      0
    )
    .toLocaleString();
};

const parseMessageDeliveryStats = (
  deliveryStats?: MessageDeliveryStat[]
): MessageDeliverySummaryStat[] => {
  if (!deliveryStats) return [];

  if (deliveryStats.length === 0) return [];

  return deliveryStats
    .map(
      ({ Timestamp, Summary }): MessageDeliverySummaryStat => ({
        DeliveryRate:
          (Summary.Delivered ?? 0) === 0
            ? 0
            : parseFloat((Summary.Delivered / Summary.Sent).toFixed(2)),
        Timestamp: formatDate(Timestamp),
      })
    )
    .reverse();
};

interface MessageDeliveryProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  range: { start_date: number; end_date: number } | string;
}

export const MessageDelivery = ({
  flowId,
  range,
  ...props
}: MessageDeliveryProps) => {
  const { refetch, isLoading, data } = useMessageDelivery(flowId, range);

  if (isLoading) return <AnalyticsDataLoading title="Total Invites Sent" />;

  if (data && !Array.isArray(data)) {
    if (data.type === "error")
      return (
        <AnalyticsDataFailed
          title="Total Invites Sent"
          errorMessage="Failed to fetch message delivery stats"
          onRetry={() => refetch()}
        />
      );

    return <AnalyticsDataLoading title="Total Invites Sent" />;
  }

  if (data && Array.isArray(data))
    return (
      <Card {...props}>
        <StatGroup>
          <Stat>
            <StatLabel>Total Invites Sent</StatLabel>
            <StatNumber>{getAggregateCount(data, "Sent")}</StatNumber>
          </Stat>
        </StatGroup>
        <AreaChart
          className="h-48 mt-4"
          data={parseMessageDeliveryStats(data)}
          index="Timestamp"
          categories={["DeliveryRate"]}
          colors={["purple"]}
          noDataText={
            isLoading
              ? "Please wait while we load read receipts"
              : "No read receipts in this time range"
          }
        />
      </Card>
    );
};
