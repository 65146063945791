import React, { useEffect, useState } from "react";
import {
  StackProps,
  VStack,
  Text,
  HStack,
  Box,
  Button,
  Tooltip,
  Circle,
  TextProps,
} from "@chakra-ui/react";

import { DashboardTemplate } from "../models/property";
import { SelectableImage } from "./SelectableImage";
import { TemplateComponentVariant } from "../constants";
import { TextField } from "./TextField";
import { useUploadFile } from "../mutations/root/useUploadFile";
import { SelectAttributes } from "./Input";

interface WhatsAppTemplateProps extends StackProps {
  flowId: string;
  template: DashboardTemplate;
  variant?: TemplateComponentVariant;
  onValueChange?: (key: string, value: string) => void;
  showTemplateName?: boolean
  showTemplateStatus?: boolean;
  showButtons?: boolean;
  textProps?: TextProps;
}

type WhatsAppTemplateHeaderFormat = "IMAGE" | "TEXT" | "DOCUMENT" | "VIDEO";

export const WhatsAppTemplate = ({
  template,
  variant = "preview",
  showTemplateStatus = true,
  showButtons = true,
  showTemplateName = true,
  ...props
}: WhatsAppTemplateProps) => {
  const uploadFileMutation = useUploadFile();

  const [header, setHeader] = useState<{
    info: WhatsAppTemplateHeaderFormat;
    value: string;
  } | null>(null);

  useEffect(() => {
    const updateHeaderFromTemplate = () => {
      if (Object.keys(template.values).includes("header")) {
        const templateHeader = template.values["header"];
        setHeader({
          info: templateHeader.info as WhatsAppTemplateHeaderFormat,
          value: templateHeader.value,
        });
      }
    };

    updateHeaderFromTemplate();
  }, [template]);

  const updateTemplateHeader = (headerURL: string) => {
    const updateHeader = (
      template: DashboardTemplate,
      updatedURL: string
    ): DashboardTemplate => {
      if (Object.keys(template.values).includes("header")) {
        const templateHeader = template.values["header"];
        templateHeader.value = updatedURL;
        return {
          ...template,
          values: { ...template.values, header: templateHeader },
        };
      }

      return template;
    };

    template = updateHeader(template, headerURL);
    setHeader((header) => ({ info: header.info, value: headerURL }));
    props.onValueChange?.("header", headerURL);
  };

  const uploadImage = async (file?: File) => {
    if (!file) return;

    let response = await uploadFileMutation.mutateAsync(file);

    if (response) return updateTemplateHeader(response.url);

    updateTemplateHeader(URL.createObjectURL(file));
  };

  return (
    <VStack
      className="hover:cursor-pointer rounded-lg"
      maxH="fit-content"
      maxW="full"
      align="start"
      {...props}
    >
      <HStack className="bg-inherit" w="full" justify="space-between">
        <Tooltip
          label={
            template.data?.["rejected_reason"] !== "NONE" &&
            template.data?.["rejected_reason"]
          }
        >
          <HStack
            hidden={!showTemplateStatus}
            spacing={1}
            borderRadius="full"
            bg={
              template.data?.["status"] === "approved" ? "green.50" : "red.50"
            }
            px={2}
          >
            <Circle
              size={2}
              bg={template.data?.["status"] === "approved" ? "green" : "red"}
            />
            <Text>
              {template.data?.["status"] === "approved" ? "live" : "disabled"}
            </Text>
          </HStack>
        </Tooltip>
        <Text hidden={!showTemplateName} px={2}>
          {template.name}
        </Text>
      </HStack>
      <VStack className="w-full p-2 rounded-md">
        <Box w="full">
          {header !== null && header.info === "IMAGE" && (
            <SelectableImage
              src={header.value}
              hidden={false}
              ratio={18 / 9}
              variant={variant}
              loading={uploadFileMutation.isLoading}
              onMediaUpload={async (mediaType, mediaBlob) =>
                uploadImage(mediaBlob)
              }
              borderRadius="md"
            />
          )}
        </Box>
        <VStack className="p-2" flex={1} align="start" w="full">
          {template.text &&
            template.text.split("\n").map((eachLine, lineIdx) => (
              <Text w="full" key={lineIdx} {...props.textProps}>
                {eachLine.split("}").map((line, paraIdx) => {
                  let [text, key] = line.split("{");
                  let fieldValue =
                    key in template.values
                      ? template.values[key].value
                      : // : key in extra
                        // ? extra[key]
                        `{${key}}`;
                  return (
                    <span key={paraIdx}>
                      {text}
                      {key &&
                        (["name"].includes(key) || variant === "preview" ? (
                          <b dangerouslySetInnerHTML={{ __html: fieldValue }} />
                        ) : (
                          <>
                            <TextField
                              key={key}
                              resize="none"
                              placeholder={`Enter ${key}`}
                              value={fieldValue}
                              onChange={(event) => {
                                props.onValueChange?.(
                                  key,
                                  event.currentTarget.value
                                );
                              }}
                            />
                            {fieldValue.includes("@") && (
                              <SelectAttributes
                                className={`h-[30vh] z-10 absolute rounded-md p-2 bg-white shadow-lg w-2/5 overflow-y-scroll`}
                                flowId={props.flowId}
                                query={fieldValue.slice(
                                  fieldValue.indexOf("@") + 1
                                )}
                                onAttributeSelect={(attribute, isExternal) => {
                                  let value =
                                    fieldValue.substring(
                                      0,
                                      fieldValue.indexOf("@")
                                    ) +
                                    `{${
                                      isExternal
                                        ? `attributes.${attribute}`
                                        : attribute
                                    }}`;
                                  props.onValueChange?.(key, value);
                                }}
                                title="Choose attribute"
                              />
                            )}
                          </>
                          // <VariableInput
                          //   key={key}
                          //   point={key}
                          //   placeholder={`Enter ${key}`}
                          //   value={fieldValue}
                          //   onChange={(value) =>
                          //     props.onValueChange?.(key, value)
                          //   }
                          // />
                        ))}
                    </span>
                  );
                })}
              </Text>
            ))}
        </VStack>
      </VStack>
      <HStack w="full" px={2}>
        {showButtons &&
          template.buttons?.map((button) => (
            <Button
              borderWidth="thin"
              borderRadius="xl"
              bg="white"
              boxShadow="sm"
              size="sm"
              color="pink.500"
              flex={1}
            >
              {button}
            </Button>
          ))}
      </HStack>
    </VStack>
  );
};
