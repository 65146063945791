import React from "react";
import {
  StackProps,
  HStack,
  Heading,
  VStack,
  Text,
  Stack,
  Circle,
  Image,
} from "@chakra-ui/react";

import InProgressIcon from "../img/status_inprogress.svg";
import { SupportConversation } from "../models/support";
import { colorSystem } from "../theme";
import { getTimeAgo } from "./InfiniteTable";
import { isUserInSession } from "../utils";

interface SupportTileProps extends StackProps {
  user: SupportConversation;
}

export const SupportTile = ({ user, ...props }: SupportTileProps) => {
  return (
    <HStack
      key={user.conversation_id}
      w="full"
      cursor="pointer"
      {...props}
      borderBottomWidth="thin"
      borderColor="gray.200"
    >
      <Stack position="relative" zIndex={0}>
        <Circle bg={colorSystem["accentPurple"]} size="2.4rem">
          <Text fontWeight="medium">
            {`${user.name || user.conversation_id}`[0].toUpperCase()}
          </Text>
        </Circle>
        {isUserInSession(user.last_user_message) && (
          <Circle
            position="absolute"
            size=".8em"
            bg={colorSystem["success"]}
            bottom={0}
            right={0}
            borderWidth="thin"
            borderColor="white"
          />
        )}
      </Stack>
      <VStack flex={1} spacing={0} align="start">
        <Heading maxW={36} fontWeight={600} fontSize=".875rem" isTruncated>
          {user.name || user.conversation_id}
        </Heading>
        <Text
          color={colorSystem["textSubdue"]}
          fontSize=".8rem"
          maxW="36"
          isTruncated
        >
          {user.last_message_text ?? ""}
        </Text>
      </VStack>
      <VStack h="full" spacing={0} justify="end" align="end">
        {user.status && user.status === "open" && (
          <Circle
            size="1em"
            borderWidth=".1rem"
            borderColor={colorSystem["textPurple"]}
          >
            <Circle size=".6em" bg={colorSystem["accentPurple"]} />
          </Circle>
        )}
        {user.status && user.status === "pending" && (
          <Image w="1em" src={InProgressIcon} />
        )}
        <Text color={colorSystem["textSubdue"]} fontSize="0.825rem">
          {getTimeAgo(user.last_message, true)}
        </Text>
      </VStack>
    </HStack>
  );
};
