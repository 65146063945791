import React, { useState, useEffect } from "react";
import { Stack, HStack, Input } from "@chakra-ui/react";

import { Button } from "../Button";
import { useStoreActions, useStoreState } from "../../hooks/store";

interface AddChoicesProps {
  onChange?: (choices: string[]) => void;
}

export const AddChoices = (props: AddChoicesProps) => {
  const choices = useStoreState((state) =>
    state.choices.length === 0 ? [{ choice: "", id: 0 }] : state.choices
  );

  const setChoices = useStoreActions((actions) => actions.cacheChoices);

  const editChange = (update: string, selectedId: number) => {
    const updatedChoices = choices.map((value) =>
      value.id === selectedId
        ? { id: selectedId, choice: update.replace(".", "") }
        : value
    );
    setChoices(updatedChoices);
    props.onChange?.(updatedChoices.map((value) => value.choice));
  };

  const removeChoice = (id: number) => {
    setChoices(choices.filter((value) => value.id !== id));
  };

  const addChoice = () => {
    setChoices([...choices, { choice: "", id: Date.now() }]);
  };

  return (
    <Stack>
      {choices.map((choice, idx) => (
        <HStack key={choice.id}>
          <Input
            size="sm"
            value={choice.choice}
            onChange={(event) =>
              editChange(event.currentTarget.value, choice.id)
            }
            placeholder={`Option ${idx + 1}`}
          />
          <Button onClick={() => removeChoice(choice.id)}>Delete</Button>
        </HStack>
      ))}
      <Button onClick={() => addChoice()}>Add</Button>
    </Stack>
  );
};
