import { useInfiniteQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export interface UserNote {
  _id: string;
  flow_id: string;
  user_id: string;
  session_id?: string;
  author: string;
  text: string;
  timestamp: number;
}

export const useUserNotes = (
  flowId: string,
  userId: string,
  limit: number = 20
) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchNotes = async ({ pageParam = 0 }) => {
    const res = await fetchWithAuth<{
      count?: number;
      notes: UserNote[];
      last_document_index?: number;
    }>({
      url: `${config.url}/v2/support/${flowId}/user_notes/${userId}`,
      config: {
        params: {
          pagination: JSON.stringify({
            skip: pageParam,
            size: limit,
          }),
        },
      },
    });

    return res;
  };

  return useInfiniteQuery(["user", "notes", flowId, userId], fetchNotes, {
    getNextPageParam: (lastPage) => lastPage.last_document_index ?? undefined,
    keepPreviousData: true,
    refetchInterval: 1000 * 60, // 1 minute
    // refetchIntervalInBackground: false,
    // refetchOnWindowFocus: false,
  });
};
