import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";

export const useUpdateNote = (refetchKey: string[] = []) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { user, getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["edit", "user_note"],
    mutationFn: async ({
      flowId,
      noteId,
      updatedText,
    }: {
      flowId: string;
      noteId: string;
      updatedText: string;
    }) => {
      const token = localStorage.getItem('token')

      const response = await axios.patch<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/user_notes/${noteId}`,
        {},
        {
          params: {
            updated_text: updatedText,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Email": user.email,
            "dashboard-user-id": user.email,
            "user-id": user.email,
          },
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({ title: "Note updated", status: "success" });
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to update note, please try again",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
