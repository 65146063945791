import React, { useState, useEffect } from "react";
import {
  HStack,
  Heading,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Portal,
  Textarea,
  Stack,
  ModalFooter,
} from "@chakra-ui/react";
import { useNewQuickReply } from "../../../../../mutations/support/useNewQuickReply";
import { QuickReply } from "../../../../../data/support/useQuickReplies";
import { Button } from "../../../../../components/Button";
import { SelectableImage } from "../../../../../components/SelectableImage";
import { useUploadFile } from "../../../../../mutations/root/useUploadFile";
import { MediaInput } from "../../../../../models";
import { defaultMedia } from "../../../../../constants";
import { SelectAttributes } from "../../../../../components/Input";

interface AddNewQuickReplyProps {
  flowId: string;

  isOpen: boolean;

  quickReply?: QuickReply;
  //
  onClose: () => void;
}

export const AddNewQuickReply = ({
  flowId,
  isOpen,
  onClose,
  quickReply,
}: AddNewQuickReplyProps) => {
  const [content, setContent] = useState<string>("");
  const [triggerText, setTriggerText] = useState<string>("");
  const [media, setMedia] = useState<MediaInput>(defaultMedia);

  const uploadFileMutation = useUploadFile();
  const addNewReply = useNewQuickReply({
    flowId,
    refetchKey: ["quick_replies", flowId],
  });

  const clear = () => {
    onClose?.();
    setContent("");
    setTriggerText("");
    setMedia(defaultMedia);
  };

  useEffect(() => {
    if (quickReply) {
      setContent(quickReply.text);
      setTriggerText(quickReply.trigger_text);
      if (quickReply.media.length > 0) {
        if (quickReply.media[0].path) {
          setMedia({
            type: quickReply.media[0].type,
            url: quickReply.media[0].link,
            bucket: quickReply.media[0].path.split("|")[0],
            key: quickReply.media[0].path.split("|")[1],
          });
        }
      }
    }

    return () => {
      setContent("");
      setTriggerText("");
      setMedia(defaultMedia);
    };
  }, [quickReply]);

  return (
    <Portal>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="thin" boxShadow="sm">
            <Heading size="sm">
              {quickReply ? "Editing" : "New"} QuickReply
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={HStack} py={4} align="start" spacing={6}>
            <Stack>
              <SelectableImage
                w={48}
                src={media.url}
                loading={uploadFileMutation.isLoading}
                variant="edit"
                onMediaUpload={async (mediaType, file) => {
                  const response = await uploadFileMutation.mutateAsync(file);
                  if (response) setMedia({ type: mediaType, ...response });
                }}
                buttonProps={{
                  hidden: true,
                }}
              />
              {media.url !== defaultMedia.url && (
                <Button
                  variant="tertiary"
                  size="xs"
                  onClick={() => setMedia(defaultMedia)}
                >
                  Remove Image
                </Button>
              )}
            </Stack>
            <Stack flex={1}>
              <Text>Trigger</Text>
              <Input
                isDisabled={!!quickReply}
                size="sm"
                placeholder="Eg: brb"
                value={triggerText}
                onChange={(event) => setTriggerText(event.currentTarget.value)}
              />
              <Text>Content</Text>
              <Textarea
                rows={6}
                size="sm"
                resize="none"
                placeholder="Be Right Back"
                value={content}
                onChange={(event) => setContent(event.currentTarget.value)}
              />
              {content.includes("@") && !content.includes("@ ") && (
                <SelectAttributes
                shadow="dark-lg"
                  className={`h-[30vh] z-10 text-sm absolute left-4 bottom-16 rounded-md p-2 bg-white drop-shadow-xl w-56 overflow-y-scroll`}
                  flowId={flowId}
                  query={content.slice(content.indexOf("@") + 1)}
                  onAttributeSelect={(attribute, isExternal) =>
                    setContent(
                      (intentBody) =>
                        intentBody.substring(0, intentBody.indexOf("@")) +
                        `{${
                          isExternal ? `attributes.${attribute}` : attribute
                        }}`
                    )
                  }
                  title="Choose attribute"
                />
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose?.();
                setContent("");
                setTriggerText("");
                setMedia(defaultMedia);
              }}
              variant="tertiary"
            >
              Cancel
            </Button>
            <Button
              isLoading={addNewReply.isLoading}
              isDisabled={triggerText === ""}
              onClick={() => {
                onClose?.();
                addNewReply.mutate({
                  flow_id: flowId,
                  text: content,
                  trigger_text: triggerText,
                  media:
                    defaultMedia.url === media.url
                      ? []
                      : [
                          {
                            type: "image",
                            link: media.url,
                            path: `${media.bucket}|${media.key}`,
                          },
                        ],
                });
                clear();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
};
