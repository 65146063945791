import React from "react";
import { useStoreRehydrated } from "easy-peasy";
import { useOutlet } from "react-router-dom";
import { Login } from "../pages/Login";
import { AuthContext } from "../store/authContext";


export const AuthenticatedRoute = () => {
  const { isAuthenticated } = React.useContext(AuthContext)
  const outlet = useOutlet();
  const isStoreLoaded = useStoreRehydrated();

  return isStoreLoaded ? isAuthenticated ? outlet : <Login /> : <div>Loading...</div>;
};
