import { useInfiniteQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";
import { RemoteMedia } from "../../api/flow";
import { MediaType } from "../../models";

interface QuickReplyMedia extends RemoteMedia {
  type: MediaType;
  path?: string;
}

export interface QuickReply {
  flow_id: string;
  // "Text to trigger quick reply"
  trigger_text: string;
  // "Content that expands",
  text: string;
  media: QuickReplyMedia[];
}

export const useQuickReplies = (
  flowId: string,
  query: string = "",
  startsWith: boolean = false,
  limit: number = 20
) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchQuickReplies = async ({ pageParam = 0 }) => {
    const res = await fetchWithAuth<{
      quick_replies: QuickReply[];
      last_document_index?: number;
    }>({
      url: `${config.url}/v2/support/${flowId}/quickreplies`,
      config: {
        params: {
          query,
          starts_with: startsWith,
          pagination: JSON.stringify({
            skip: pageParam,
            size: limit,
          }),
        },
      },
    });

    return res;
  };

  return useInfiniteQuery(
    ["quick_replies", flowId, startsWith],
    fetchQuickReplies,
    {
      // getPreviousPageParam: (firstPage) => firstPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.last_document_index ?? undefined,
      keepPreviousData: false,
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    }
  );
};
