import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Stack,
  StackProps,
  Text,
  Heading,
  HStack,
  Box,
  ButtonGroup,
  useToast,
  CircularProgress,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useSupportTicket } from "../../../data/support/useSupportTicket";
import { getFileType, isUserInSession } from "../../../utils";
import { Button } from "../../../components/Button";
import { FiMessageSquare } from "react-icons/fi";
import { EmojiInput } from "../../../components/Input";
import {
  AttachmentIcon,
  CloseIcon,
  DocumentIcon,
  EmojiIcon,
  MediaIcon,
  SendIcon,
  VideoIcon,
} from "../../../constants/icons";
import { FileInputButton } from "../../../components/Input/File";
import { LuSquareSlash } from "react-icons/lu";
import { RemoteMedia } from "../../../api/flow";
import {
  ReplyMessage,
  useSendMessage,
} from "../../../mutations/support/useSendMessage";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { updateQuery } from "../../../api/support";
import { SupportUser } from "../../../models/support";
import { TextField } from "../../../components/TextField";
import { useAgentAssignment } from "../../../mutations/support/useAgentAssignment";
import { useFeatureFlags } from "../../../data/flags/useFeatureFlags";
import { AGENT_INTERVENTION, DISABLE_RESOLVE_ACTION } from "../../../constants";
import { DashboardTemplate } from "../../../models";
import { SelectTemplate } from "../Invite";
import { useStoreActions } from "../../../hooks/store";
import { useUploadFile } from "../../../mutations/root/useUploadFile";
import { QuickReplyInChat } from "./QuickReplies";

interface ChatFooterProps extends StackProps {
  flowId: string;

  sessionId: string;
}

export const ChatFooter = forwardRef<HTMLDivElement, ChatFooterProps>(
  ({ flowId, sessionId, ...props }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const { 
      user: { email: userMail, username: userName },
      getAuthToken } = useAuthToken();
    const toast = useToast();
    const { isLoading, data: user, error } = useSupportTicket(sessionId);
    const { data: flags } = useFeatureFlags(flowId);
    const handleAgentAssignment = useAgentAssignment({
      flowId,
      refetchKey: ["support_ticket", sessionId],
    });
    const uploadFileMutation = useUploadFile("", "error sending attachment");

    const sendMessageMutation = useSendMessage({
      flowId,
      sessionId,
      refetchKey: ["support_ticket", sessionId],
    });

    const clearTemplate = useStoreActions((actions) => actions.clearTemplate);
    const [showTemplatesIndex, setShowTemplatesIndex] = useState(-1);
    const [showQuickReplies, setShowQuickReplies] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [closeTicket, setCloseTicket] = useState<boolean>(false);
    const [currentSupportUser, setCurrentSupportUser] =
      useState<SupportUser | null>(null);
    const [template, setTemplate] = useState<DashboardTemplate | null>(null);
    const [attachment, setAttachment] = useState<
      { type: "file"; file: File } | RemoteMedia
    >(null);

    const isFeatureEnabled = (feature: string) => {
      console.log(flags);

      return (flags?.flags ?? []).includes(feature);
    };

    /**
     * generates message payload to be sent to the backend
     * @returns {Promise<ReplyMessage>}
     */
    const getMessage = async (): Promise<ReplyMessage> => {
      if (template) {
        return { type: "template", ...template };
      }

      if (attachment) {
        if (attachment.type === "file") {
          let attachmentType = getFileType(attachment.file);

          // upload attachment
          let response = await uploadFileMutation.mutateAsync(attachment.file);

          if (attachmentType === "audio")
            return {
              type: "audio",
              link: response.url,
            };

          return {
            type: attachmentType,
            caption: message,
            link: response.url,
          };
        } else {
          return { ...attachment, caption: message };
        }
      }
      return message;
    };

    const resetInputs = () => {
      setMessage("");
      // reset the height of the input
      if (inputRef && inputRef.current) {
        inputRef.current.style.height = "auto";
      }
      // remove attachments (if any)
      setAttachment(null);
      setTemplate(null);
      setShowTemplatesIndex(-1);
      clearTemplate();
    };

    // handle enter key press
    const _handleKeyDown = (key: string, allowNewLine: boolean) => {
      // when shift key is pressed allow new line
      if (allowNewLine) return;
      if (key === "Enter" && !sendMessageMutation.isLoading) sendReply();
    };

    // removes the current agent assigned and removes the query from the agent's list
    const closeSupportQuery = async () => {
      try {
        const response = await updateQuery({
          flowId,
          source: user.source,
          userMail,
          conversationId: user.conversation_id,
          token: await getAuthToken(),
          close: true,
        });

        if (response && response.status === "success") {
          setCurrentSupportUser(null);
          toast({
            status: "success",
            description: "Support query closed successfully",
            duration: 900,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    // send reply to the user
    const sendReply = () => {
      // Feature flag: Agent Intervention
      if (isFeatureEnabled(AGENT_INTERVENTION)) {
        // if the user is not assigned to any agent, assign the current agent to the user
        if (!currentSupportUser)
          handleAgentAssignment.mutate({
            agentOrTeam: {
              name: userName,
              email: userMail,
              admin: false,
              groups: [],
            },
            sessionId,
          });
      }

      getMessage().then((messageToSend) => {
        if (messageToSend === "") return;
        sendMessageMutation.mutate({
          message: messageToSend,
          onSuccess: () => {
            resetInputs();
            if (closeTicket) closeSupportQuery();
          },
        });
      });
    };

    useEffect(() => {
      setCurrentSupportUser(user?.agent);
    }, [user]);

    if (isLoading) return <></>;

    if (user && isUserInSession(user.last_user_message)) {
      return (
        <Stack ref={ref} px={4} py={2} {...props}>
          <QuickReplyInChat
            searchQuery={message.split("/")?.[1]}
            onSelected={(reply) => {
              if (reply.media) setAttachment(reply.media[0]);

              setMessage(reply.text);
              setShowQuickReplies((prev) => !prev);
              inputRef?.current?.focus();
            }}
            flowId={flowId}
            hidden={!showQuickReplies}
          />
          <Stack className="bg-white border rounded-md p-2 shadow-sm">
            <TextField
              ref={inputRef}
              className="px-1 border-none"
              value={message}
              onChange={(event) => {
                setShowQuickReplies(event.currentTarget.value.startsWith("/"));
                setMessage(event.currentTarget.value);
              }}
              bg="transparent !important"
              placeholder="Type or press / for quick replies"
              variant="unstyled"
              onKeyDown={(event) => _handleKeyDown(event.key, event.shiftKey)}
            />
            {attachment && (
              <HStack>
                <FileChip
                  isLoading={uploadFileMutation.isLoading}
                  file={attachment}
                  description="Quick Reply"
                  onClose={() => setAttachment(null)}
                />
              </HStack>
            )}
            <HStack justify="space-between">
              <ButtonGroup>
                <EmojiInput
                  variant="tertiary"
                  closeOnSelect={false}
                  popoverProps={{ offset: [0, 64] }}
                  onChange={(selectedEmoji) => {
                    const inputElement = inputRef.current;
                    if (inputElement) {
                      const start = inputElement.selectionStart;
                      const end = inputElement.selectionEnd;

                      // inserts the emoji at the cursor position
                      setMessage(
                        (previous) =>
                          previous.substring(0, start) +
                          selectedEmoji +
                          previous.substring(end)
                      );
                    }
                  }}
                  onPopoverClose={() => {
                    // Move the cursor to the end of the added text
                    const newCursorPosition = message.length;
                    inputRef?.current?.setSelectionRange(
                      newCursorPosition,
                      newCursorPosition
                    );
                    inputRef?.current?.focus();
                  }}
                >
                  <EmojiIcon size={18} />
                </EmojiInput>
                <FileInputButton
                  fileType="image/* video/* audio/* pdf/* doc/* xls/* ppt/*"
                  variant="tertiary"
                  onFileUpload={(file) => {
                    // check if size is less than 2MB
                    if (file.size > 2 * 1024 * 1024)
                      return toast({
                        title: "File size too large",
                        description: "File size should be less than 2MB",
                        status: "error",
                        isClosable: true,
                        position: "bottom-right",
                      });

                    setAttachment({ type: "file", file });
                  }}
                >
                  <AttachmentIcon size={18} />
                </FileInputButton>
                <Button
                  variant="tertiary"
                  aria-label={"quick_replies"}
                  onClick={() => setShowQuickReplies((prev) => !prev)}
                >
                  <LuSquareSlash size={18} />
                </Button>
              </ButtonGroup>
              <ButtonGroup gap={3} alignItems="center">
                <Button
                  variant="secondary"
                  height={6}
                  isDisabled={
                    currentSupportUser === null ||
                    isFeatureEnabled(DISABLE_RESOLVE_ACTION)
                  }
                  onClick={() => closeSupportQuery()}
                >
                  Resolve
                </Button>
                <Switch
                  id="send-and-close"
                  size="sm"
                  hidden={isFeatureEnabled(DISABLE_RESOLVE_ACTION)}
                  colorScheme="pink"
                  isChecked={closeTicket}
                  onChange={(event) =>
                    setCloseTicket(event.currentTarget.checked)
                  }
                >
                  send and close
                </Switch>
                <Button
                  isLoading={sendMessageMutation.isLoading}
                  isDisabled={message.length === 0}
                  variant="tertiary"
                  aria-label={"quick_replies"}
                  onClick={() => sendReply()}
                >
                  <SendIcon size={18} />
                </Button>
              </ButtonGroup>
            </HStack>
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack borderTopWidth="thin" ref={ref} bg="white" p={4} {...props}>
        <Accordion
          allowToggle
          index={showTemplatesIndex}
          onChange={(e) => setShowTemplatesIndex(e as number)}
        >
          <AccordionItem border="none">
            <AccordionButton px={0} py={2} as={HStack} spacing={12}>
              <Box flex={1}>
                <Heading size="sm">Session Expired</Heading>
                <Text className="text-gray-600">
                  Until you receive a message from the customer, WhatsApp allows
                  only template messages to be sent in these chats.
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box position="relative" maxH="60vh" overflowY="scroll">
                <SelectTemplate
                  navigation={false}
                  flowId={flowId}
                  sourceId={user.source}
                  onTemplateSelected={(template) => setTemplate(template)}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <ButtonGroup>
          <Button
            flex={1}
            variant="secondary"
            isDisabled={
              currentSupportUser === null ||
              isFeatureEnabled(DISABLE_RESOLVE_ACTION)
            }
            onClick={() => closeSupportQuery()}
          >
            Resolve
          </Button>
          {showTemplatesIndex === 0 ? (
            <Button
              flex={1}
              isDisabled={template === null}
              leftIcon={<FiMessageSquare />}
              onClick={() => sendReply()}
              isLoading={sendMessageMutation.isLoading}
            >
              Send Template
            </Button>
          ) : (
            <Button
              leftIcon={<FiMessageSquare />}
              onClick={() => setShowTemplatesIndex(0)}
              flex={1}
            >
              Select Template to send
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    );
  }
);

/**
 * component to render files selected by user. the file is removed when the user clicks on the close button
 * @param {File} file
 * @param {Function} onClose
 * @returns {JSX.Element}
 */
interface FileChipProps extends StackProps {
  isLoading?: boolean;
  file: { type: "file"; file: File } | RemoteMedia;
  description?: string;
  onClose?: () => void;
}

const FileChip = ({
  file,
  description,
  onClose,
  isLoading = false,
  ...props
}: FileChipProps) => {
  const attachmentType =
    file.type === "file" ? getFileType(file.file) : file.type;

  return (
    <HStack
      bg="gray.200"
      borderWidth="thin"
      borderRadius="md"
      p={2}
      spacing={2}
      align="center"
      {...props}
    >
      {isLoading ? (
        <CircularProgress isIndeterminate />
      ) : attachmentType === "image" ? (
        <MediaIcon size={24} />
      ) : attachmentType === "video" ? (
        <VideoIcon size={24} />
      ) : (
        <DocumentIcon size={24} />
      )}
      <Text maxW="24" isTruncated textOverflow="ellipsis">
        {file.type === "file" ? file.file.name : description ?? file.type}
      </Text>
      <CloseIcon cursor="pointer" size={18} onClick={() => onClose?.()} />
    </HStack>
  );
};
