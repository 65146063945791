import axios from "axios";
import config from "../config";
import { IFlowRequest } from "../models";

const apiPrefix = `${config.url}/v2/events`;

interface Event {
  uid: string;
  event: string;
  data: {};
}

export interface DashboardEvent {
  uid: string;
  flow_id: string;
  event:
    | "QUERY_CLOSED"
    | "MICROFLOW_STARTED"
    | "MICROFLOW_TRIGGERED"
    | "AGENT_ASSIGNED";

  conversation_oid?: string;
  message_oid?: string;
  data: { [key: string]: any };
  timestamp: number;
}

interface IEventRequest extends IFlowRequest {
  event: Event;
}

export const saveEvent = async ({ flowId, event, token }: IEventRequest) => {
  if (apiPrefix.includes("localhost")) {
    console.log("[EVENT] logging local events to console");
    return console.log(event);
  }

  const response = await axios.post(`${apiPrefix}/new/${flowId}`, event, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getDashboardEvents = async (request: {
  token: string;
  flow_id?: string;
  conversation_ids?: string[];
}): Promise<DashboardEvent[]> => {
  let url = `${apiPrefix}/dashboard`;

  if (request.flow_id) url = `${url}?flow_id=${request.flow_id}`;
  else if (request.conversation_ids) {
    let conversation_ids = ``;
    request.conversation_ids.map(
      (id) => (conversation_ids += `conversation_ids=${id}&`)
    );
    url = `${url}?${conversation_ids}`;
  } else throw "Request can't be processed";

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${request.token}`,
    },
  });
  return response.data;
};
