import React, { useContext } from "react";
import {
  Avatar,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  IconButton,
  Tooltip,
  StackProps,
} from "@chakra-ui/react";
import GeneratedAvatar from "boring-avatars";

import { LogoutIcon } from "../constants/icons";
import { Button } from "./Button";
import { getUserInfo } from "../utils/generate";
import { colorSystem } from "../theme";
import config from "../config";
import { AuthContext } from "../store/authContext";

interface UserMenuProps extends StackProps {
  variant?: "default" | "dock";
}

export const UserMenu = ({ variant = "default", ...props }: UserMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    user: { username: userName, email: userMail, redirect: redirect },
    logout,
  } = useContext(AuthContext)



  const signOut = async () => {
    // logout({ returnTo: config.domain + "/login" });
    // localStorage.removeItem("recruiter");
    // localStorage.removeItem("recruiterId");
    // // redirect to assesshub dashboard
    // window.location.replace("https://admin.myassesshub.com/#/bot-api");

    // remove from localstorage
    // localStorage.removeItem('user')
    // localStorage.removeItem('token')

    localStorage.clear();

    // remove from context
    logout()

    // redirect
    if (redirect) {
      // admin url
      let urlToRedirect = window.location.href.includes('localhost') ? "http://localhost:3001/#/bot-api" : window.location.href.includes('dashboard.assesshub.com') ? "https://admin.myassesshub.com/#/bot-api" : "/"
      window.location.replace(urlToRedirect)
    } else {
      // NOTE: there is not need to redirect, on user update, it will refresh page
      // login screen
      // window.location.replace("/");
    }
  };

  const { hoverGrey } = colorSystem;
  const userInfo: string = userName ?? userMail;

  return (
    <HStack
      justify={variant === "dock" ? "center" : "start"}
      w="full"
      py={4}
      px={2}
      borderTopWidth="thin"
      {...props}
    >
      <Box hidden={variant === "dock"}>
        <GeneratedAvatar
          variant="beam"
          square={false}
          size={24}
          name={userInfo}
        />
      </Box>
      <Box hidden={variant === "dock"} w={36}>
        <Tooltip label={userInfo}>
          <Text fontSize="1rem" fontWeight="600" isTruncated>
            {userInfo}
          </Text>
        </Tooltip>
        <Text fontSize="0.75rem">{getUserInfo(userMail)}</Text>
      </Box>
      <IconButton
        aria-label={""}
        icon={<LogoutIcon />}
        onClick={onOpen}
        size="sm"
        variant="outline"
        _hover={{ bg: hoverGrey }}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you wanna log out?</ModalBody>
          <ModalFooter>
            <Button variant="tertiary" mr={3} onClick={signOut}>
              Yes
            </Button>
            <Button onClick={onClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
