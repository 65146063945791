import React, { useState, useEffect, forwardRef, useRef } from "react";
import {
  Stack,
  HStack,
  Input,
  Text,
  StackProps,
  useToast,
} from "@chakra-ui/react";
import * as xlsx from "xlsx";

import { Button } from "../Button";
import { UploadIcon, CloseIcon } from "../../constants/icons";
import { parseCSV } from "../../utils";

interface CSVInputProps extends Omit<StackProps, "onChange"> {
  onChange?: (file: File) => void;

  // callback to parse the data
  onParse?: (data: any[], clearFile?: () => void) => void;
}

export const CSVInput = forwardRef<HTMLDivElement, CSVInputProps>(
  ({ onChange, onParse, ...props }, ref) => {
    const toast = useToast();
    const inputRef = useRef(null);
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const extractFromCSV = async (file: File) => {
      if (!file) return;

      const type = file?.type ?? "";
      const reader = new FileReader();

      reader.onload = (evt) => {
        if (type === "text/csv") {
          let data = evt.target.result;
          let parseResult = parseCSV(`${data}`);
          onParse?.(parseResult.data, () => setFile(null));
        } else {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = xlsx.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = xlsx.utils.sheet_to_csv(ws, {
            rawNumbers: true,
          });
          /* Update state */
          let parseResult = parseCSV(data);
          onParse?.(parseResult.data, () => setFile(null));
        }
      };
      reader.readAsBinaryString(file);
    };

    const handleDrag = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const selectedFile: File = e.dataTransfer.files[0];
        if (selectedFile.type === "text/csv") return setFile(selectedFile);
        toast({ title: "File type not supported", status: "warning" });
      }
    };

    useEffect(() => {
      const extractContents = () => {
        if (file) return extractFromCSV(file);
        return onParse?.([], () => setFile(null));
      };

      extractContents();
    }, [file]);

    return (
      <Stack ref={ref} {...props}>
        <label
          className={`flex justify-center w-full transition appearance-none cursor-pointer border-dashed border p-4 ${
            dragActive ? "border-blue-300" : ""
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <span className="flex flex-col items-center space-x-2 space-y-2">
            <UploadIcon size={24} />
            <span className="font-medium text-gray-600">
              Drop your file here or &nbsp;
              <span className="text-[#6941C6] underline">browse file</span>
            </span>
            <span className="text-xs text-gray-400">
              Only CSV/XLSX supported
            </span>
          </span>

          <Input
            ref={inputRef}
            className="hidden"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(event) => {
              const files = event.currentTarget.files;
              if (files && files.length > 0) {
                setFile(files[0]);
                onChange?.(files[0]);
              }
            }}
          />
        </label>

        {file && (
          <HStack justify="space-between" align="center">
            <span>
              <Text maxW="72" isTruncated>
                {file.name}
              </Text>
              <Text fontSize="xs" className="text-gray-600">
                ({(file.size / 1024).toPrecision(2)} KB)
              </Text>
            </span>
            <Button
              size="xs"
              leftIcon={<CloseIcon />}
              variant="tertiary"
              onClick={(e) => {
                setFile(null);
                // also clear the input
                if (inputRef.current) {
                  inputRef.current.value = null;
                }
              }}
            >
              Remove
            </Button>
          </HStack>
        )}
      </Stack>
    );
  }
);
