import React, { forwardRef } from "react";
import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { colorSystem } from "../theme";
import Progress from "./Feedback/Progress";

interface AppbarProps extends BoxProps {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  variant?: "sticky" | "default";
  placement?: "top" | "bottom";
  showHeader?: boolean;
  boxProps?: BoxProps;
}

export const Appbar = forwardRef<HTMLDivElement, AppbarProps>(
  (
    {
      variant = "default",
      isLoading = false,
      placement = "top",
      showHeader = true,
      boxProps,
      ...props
    },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        className="min-w-full"
        position={variant === "default" ? "relative" : "sticky"}
        {...(variant === "sticky" && placement === "top"
          ? {
              top: 0,
              zIndex: 999,
            }
          : {
              bottom: 0,
              left: 0,
              right: 0,
            })}
        {...boxProps}
      >
        <Box bg="white" px={4} {...props}>
          {showHeader && (
            <Stack py={4} direction="row" align="center" spacing={4}>
              {props.leading}
              <Box flex={1}>
                {props.title && (
                  <Text
                    fontSize="1.15rem"
                    fontWeight="600"
                    color={colorSystem["textPrimary"]}
                  >
                    {props.title}
                  </Text>
                )}
                {props.subtitle && (
                  <Text fontSize="sm" color="blackAlpha.700">
                    {props.subtitle}
                  </Text>
                )}
              </Box>
              {props.trailing}
            </Stack>
          )}
          {props.children}
        </Box>
        {isLoading && <Progress size="xs" />}
      </Box>
    );
  }
);
