import React, { forwardRef } from "react";
import { AspectRatio, Box, BoxProps } from "@chakra-ui/react";
import MediaPlyr from "../MediaPlyr";
import { theme } from "../../theme";
import { APITypes } from "plyr-react";
import { WhatsappMessageText } from ".";

interface VideoChatMessageProps extends BoxProps {
  message: UserMediaMessage;
}

export const VideoChatMessage = forwardRef<
  HTMLDivElement,
  VideoChatMessageProps
>(({ message, ...props }, boxRef) => {
  const ref = React.useRef<APITypes>(null);

  return (
    <Box
      w="72"
      ref={boxRef}
      className="overflow-hidden rounded-md"
      sx={{
        "--plyr-audio-controls-background": theme.colors.gray[50],
        "--plyr-color-main": "#714cd3",
        "--plyr-menu-radius": "10px",
      }}
      {...props}
    >
      <AspectRatio ratio={16 / 9}>
        <MediaPlyr
          mediaId={message.url}
          ref={ref}
          source={{
            type: "video" as const,
            sources: [
              {
                type: "video/mp4",
                src: message.url,
              },
            ],
          }}
          options={{
            controls: [
              "play-large",
              "play",
              "progress",
              "current-time",
              "fullscreen",
            ],
            hideControls: true,
            tooltips: {
              seek: false,
            },
          }}
        />
      </AspectRatio>
      {message.caption && <WhatsappMessageText text={message.caption}/>}
    </Box>
  );
});
