import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Text,
  ButtonGroup,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Grid,
  Box,
  Button,
} from "@chakra-ui/react";


import BarChart from "../components/Charts/Bar";
import LineChart from "../components/Charts/Line";
import PieChart from "../components/Charts/Pie";
import Card from "../components/Card";
import { fetchDhwaniAnalytics } from "../api/root";
import { ExportIcon } from "../constants/icons";
import {
  getTimestampData,
  getCampaignAnalytics,
  formatDashboardDate,
  excelExport,
} from "../utils/helpers";
import { DatePicker } from "../components/Input";
import { Appbar } from "../components/Appbar";
import { useAuthToken } from "../hooks/useAuthToken";

const DhwaniAnalytics = () => {
  const { botId, id } = useParams();
  const { 
    user: { email: userMail, username: userName },
    getAuthToken } = useAuthToken();
  const flowId = id ?? botId;
  const [faqData, setfaqData] = useState({ Loading: 100 });
  const [notificationData, setnotificationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [campaignData, setcampaignData] = useState([]);
  const [queryStats, setQueryStats] = useState({});
  const [timeDelta, setfilterLevel] = useState(7);
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [unansweredQueryList, setUnansweredQueryList] = useState([]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      setLoading(true);
      const token = localStorage.getItem('token')

      const data = await fetchDhwaniAnalytics({
        flowId,
        token,
        delta: timeDelta,
      }).catch((_) => setLoading(false));
      setLoading(false);

      if (data) {
        if (data["Top Queries"]) {
          setfaqData(data["Top Queries"]);
        }
        if (data["Query stats"]) {
          setQueryStats(data["Query stats"] ?? {});
        }
        if (data["Notification Analytics"]) {
          setnotificationData(data["Notification Analytics"] ?? {});
        }
        if (data["Campaign Type Analytics"]) {
          setcampaignData(data["Campaign Type Analytics"] ?? {});
        }
        if (data["List of unanswered questions"]) {
          setUnansweredQueryList(data["List of unanswered questions"] ?? {});
        }
        if (data["From"]) {
          setstartTime(formatDashboardDate(data["From"]) ?? "");
        }
        if (data["To"]) {
          setendTime(formatDashboardDate(data["To"]) ?? "");
        }
      }
    };

    fetchAnalytics();
  }, [timeDelta]);

  const DateFilter = () => {
    return (
      <ButtonGroup
        size="sm"
        colorScheme="pink"
        alignItems="center"
        isAttached
        variant="outline"
      >
        <Button onClick={() => setfilterLevel(7)} isActive={timeDelta == 7}>
          This week
        </Button>
        <Button
          onClick={() => setfilterLevel(30)}
          borderRightRadius={0}
          isActive={timeDelta == 30}
        >
          This month
        </Button>
        <DatePicker
          variant="secondary"
          title="Custom"
          date={timeDelta}
          onDateClear={() => setfilterLevel(7)}
          onDateSelected={(dateRange) => setfilterLevel(dateRange)}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        />
      </ButtonGroup>
    );
  };

  const timestampData = getTimestampData(notificationData);
  const campaignAnalytics = getCampaignAnalytics(campaignData);

  const exportDhwaniAnalytics = () => {
    let notificationExport = [];
    let queriesExport = [];
    let campaignExport = [];

    setLoading(true);

    Object.keys(timestampData).map((query) => {
      notificationExport.push({
        Date: query,
        ...timestampData[query],
      });
    });

    Object.keys(faqData).map((query) => {
      queriesExport.push({
        Query: query,
        Count: faqData[query],
      });
    });

    Object.keys(campaignAnalytics).map((query) => {
      campaignExport.push({
        Type: query,
        Count: campaignAnalytics[query],
      });
    });

    let pattern = new RegExp(
      `(${["workhack.io", "assesshub.com"].join("|")})`,
      "i"
    );

    let dataToExport = [
      { sheetName: "Notifications", json: notificationExport },
      { sheetName: "Queries", json: queriesExport },
      { sheetName: "Campaigns", json: campaignExport },
    ];

    if (userMail.match(pattern)) {
      dataToExport.push({
        sheetName: "Unanswered Questions",
        json: unansweredQueryList.map((query) => ({
          Query: query,
        })),
      });
    }

    excelExport("Dhwani Analytics", dataToExport);

    setLoading(false);
  };

  return (
    <Box h="100vh" overflowY="scroll">
      <Appbar
        isLoading={loading}
        showHeader={false}
        variant="sticky"
        borderBottomWidth="thin"
      >
        <Stack py={4} align="center" direction="row" justify="space-between">
          <DateFilter />
          <Button
            colorScheme="pink"
            leftIcon={<ExportIcon />}
            isLoading={loading}
            onClick={() => exportDhwaniAnalytics()}
            size="sm"
          >
            Export
          </Button>
        </Stack>
      </Appbar>
      <Grid
        p={4}
        templateColumns="repeat(4, 1fr)"
        rowGap={4}
        columnGap={4}
        templateAreas='"asked asked asked queries"
        "campaign_stats notifications notifications notifications";'
      >
        <Card bg="white" gridArea="asked">
          <Text my={2} fontSize="md" fontWeight="bold">
            Top Queries
          </Text>
          <BarChart
            data={{
              labels: Object.keys(faqData).map((key) => key),
              datasets: [
                {
                  data: Object.keys(faqData).map((key) => faqData[key]),
                  borderColor: "#CE92D8",
                  backgroundColor: "rgba(206, 146, 216, 0.5)",
                },
              ],
            }}
          />
        </Card>

        <Card bg="white" gridArea="notifications">
          <Text my={2} fontSize="md" fontWeight="bold">
            Notifications
          </Text>
          <LineChart
            data={{
              labels: Object.keys(timestampData),
              datasets: [
                {
                  label: "Delivered",
                  data: Object.keys(timestampData).map(
                    (key) => timestampData[key]["Delivered"]
                  ),
                  borderColor: "#CE92D8",
                  backgroundColor: "rgba(206, 146, 216, 0.5)",
                },
                {
                  label: "Failed",
                  data: Object.keys(timestampData).map(
                    (key) => timestampData[key]["Failed"]
                  ),
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
              ],
            }}
          />
          <StatGroup mt={6}>
            <Stat>
              <StatLabel>Total Sent</StatLabel>
              <StatNumber>{getCount(timestampData, "Sent")}</StatNumber>
              <StatHelpText>{`${startTime} - ${endTime}`}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Total Delivered</StatLabel>
              <StatNumber>{getCount(timestampData, "Delivered")}</StatNumber>
              <StatHelpText>{`${startTime} - ${endTime}`}</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Total Failed</StatLabel>
              <StatNumber>{getCount(timestampData, "Failed")}</StatNumber>
              <StatHelpText>{`${startTime} - ${endTime}`}</StatHelpText>
            </Stat>
          </StatGroup>
        </Card>

        <Card bg="white" gridArea="campaign_stats">
          <Text my={2} fontSize="md" fontWeight="bold">
            Campaigns
          </Text>
          <PieChart
            data={{
              labels: Object.keys(campaignAnalytics),
              datasets: [
                {
                  label: "Delivered",
                  data: Object.keys(campaignAnalytics).map(
                    (key) => campaignAnalytics[key]
                  ),
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
          />
        </Card>

        <Card bg="white" gridArea="queries">
          <StatGroup as={Stack} spacing={6} my={4}>
            {Object.keys(queryStats).length > 0 &&
              Object.keys(queryStats).map((key) => {
                return (
                  <Stat>
                    <StatLabel>{key}</StatLabel>
                    <StatNumber>{queryStats[key]}</StatNumber>
                  </Stat>
                );
              })}
          </StatGroup>
        </Card>
      </Grid>
    </Box>
  );
};

export default DhwaniAnalytics;

const getCount = (data = {}, objectKey = "") => {
  let count = 0;
  Object.keys(data).map((key) => (count += data[key][objectKey]));
  return count;
};
