import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

import { useAuthToken } from "../../hooks/useAuthToken";
import { QuickReply } from "../../data/support/useQuickReplies";
import config from "../../config";

interface NewQuickReply {
  // query key to refetch after mutation is completed
  refetchKey: string[];

  flowId: string;
}

export const useNewQuickReply = ({ flowId, refetchKey }: NewQuickReply) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["new", "quick_reply", flowId],
    mutationFn: async (quickReply: QuickReply) => {
      const token = localStorage.getItem('token')

      const response = await axios.post<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/quickreplies/new`,
        {
          trigger_text: quickReply.trigger_text,
          text: quickReply.text,
          media: quickReply.media,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({ title: "New quick reply added", status: "success" });
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to add new quick reply",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
