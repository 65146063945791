import axios from "axios";
import config from "../config";

import { FilterGroupQuery } from "../components/Filter/models";
import {
  PaginationConfig,
  DashboardTemplate,
  InvitationUser,
  CampaignType,
  CampaignUnion,
  IFlowRequest,
} from "../models";

const apiRoot = "v2/invite";

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type NumberRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export type Month = NumberRange<1, 13>;
export type Days = NumberRange<1, 32>;
export type WeekDays = NumberRange<0, 7>;
export type Hours = NumberRange<0, 24>;
export type Minutes = NumberRange<0, 60>;
export interface RepeatTiming {
  /**
  values     range	        description
  ---
  years	      ∞	            One or more years in YYYY format.
  months	    1 - 12	      One or more months, where January is 1 and December is 12.
  days	      1 - 31	      One or more month days, from 1 to 31.
  weekdays	  0 - 6	        One or more weekdays, where Sunday is 0, and Saturday is 6
  hours	      0 - 23	      One or more hours in 24-hour time, from 0 to 23.
  minutes	    0 - 59	      One or more minutes, from 0 to 59.
  */

  years: number[];
  months?: Month[];
  days?: Days[];
  weekdays?: WeekDays[];
  hours?: Hours[];
  minutes?: Minutes[];
}

interface UnifiedInviteAPIRequest extends IFlowRequest {
  // "Existing or New Users"
  users: InvitationUser[] | FilterGroupQuery;
  // "Template to use"
  template: DashboardTemplate;
  // "Whether or not send template messages"
  send_templates: boolean;
  // Treat duplicates as new users? Whether or not invite existing users again
  invite_duplicates: boolean;
  // 0                for instant invite
  // timestamp        to schedule and just run once
  // RepeatTiming     if wants to repeat
  scheduled: number | RepeatTiming; //
  // Metadata to pass
  data: {};
  // Name of the micro flow to send
  micro_flow?: string;
  // end timing if any
  end_timing?: RepeatTiming
}

interface IInviteRequest extends IFlowRequest {
  type: CampaignType;
  pagination: PaginationConfig;
}

export const unifiedInviteAPI = async (request: UnifiedInviteAPIRequest) => {
  const response = await axios.post(
    `${config.url}/${apiRoot}/${request.flowId}`,
    {
      users: request.users,
      template: request.template,
      send_templates: request.send_templates,
      invite_duplicates: request.invite_duplicates,
      scheduled: request.scheduled,
      data: request.data,
      micro_flow: request.micro_flow,
      end_timing: request.end_timing
    },
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
    }
  );

  return response.data;
};

export const getInviteStatus = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get<{ [status: string]: number }>(
    `${config.url}/${apiRoot}/status/${flowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const retryInviteCampaign = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.post<{ campaign_id: string }>(
    `${config.url}/${apiRoot}/retry/${flowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const getInvites = async ({
  flowId,
  type,
  pagination,
  token,
}: IInviteRequest) => {
  const response = await axios.post<CampaignUnion[]>(
    `${config.url}/${apiRoot}/campaigns/${flowId}`,
    {
      type,
      pagination,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
