import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Text, Heading, Image } from "@chakra-ui/react";
import { useNavigate, useRouteError } from "react-router-dom";
import { withScope } from "@sentry/react";

import emptyImageSrc from "../img/empty.svg";
import { Button } from "../components/Button";

const isRouteNotFoundError = (error: any) => {
  if (error && error.status === 404) {
    return true;
  }
};

export const ErrorBoundary = () => {
  const [error, setError] = useState<{ title: string; description: string }>({
    title: "Huston, we have a problem!",
    description: "Something went wrong and it's not your fault.",
  });

  const routeError = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRouteNotFoundError(routeError)) {
      setError({
        title: "Page not found",
        description: "Sorry, we couldn't find the page you're looking for.",
      });
    } else {
      withScope((scope) => {
        scope.setExtra("error", routeError);
      });
    }
  }, [routeError]);

  return (
    <Container h="100vh" maxW="container.lg">
      <Stack spacing={8} h="full" justify="center" align="center">
        <Image src={emptyImageSrc} />
        <Box textAlign="center">
          <Heading size="md">{error.title}</Heading>
          <Text className="text-base text-[#1D2939]">{error.description}</Text>
        </Box>
        <Button onClick={() => navigate("/")}>Go back home</Button>
      </Stack>
    </Container>
  );
};
