import React from "react";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Card, AreaChart } from "@tremor/react";

import { AnalyticsDataFailed, AnalyticsDataLoading, formatDate } from ".";
import { ReadReceiptStat, useReadReceipts } from "../../data";

const parseReadReceipts = (
  readReceipts: ReadReceiptStat[]
): ReadReceiptStat[] => {
  if (!readReceipts) return [];

  if (readReceipts.length === 0) return [];

  return readReceipts.map(({ Read, Timestamp }) => ({
    type: "data",
    Read: Read,
    Timestamp: formatDate(Timestamp),
  }));
};

interface ReadReceiptsProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  range: { start_date: number; end_date: number } | string;
}

export const ReadReceipts = ({
  flowId,
  range,
  ...props
}: ReadReceiptsProps) => {
  const { refetch, isLoading, data } = useReadReceipts(flowId, range);

  if (isLoading) {
    return <AnalyticsDataLoading title="Total Invites Opened" />;
  }

  if (data && !Array.isArray(data)) {
    if (data.type === "error") {
      return (
        <AnalyticsDataFailed
          title="Total Invites Opened"
          errorMessage={"Something went wrong while getting total invites opened"}
          onRetry={() => refetch()}
        />
      );
    }

    return <AnalyticsDataLoading title="Total Invites Opened" />;
  }

  if (data && Array.isArray(data))
    return (
      <Card {...props}>
        <Stat>
          <StatLabel>Total Invites Opened</StatLabel>
          <StatNumber>
            {(data?.reduce((sum, a) => sum + a.Read, 0) ?? 0).toLocaleString()}
          </StatNumber>
        </Stat>
        <AreaChart
          className="h-48 mt-4"
          data={parseReadReceipts(data)}
          index="Timestamp"
          categories={["Read"]}
          colors={["purple"]}
          noDataText={
            isLoading
              ? "Please wait while we load read receipts"
              : "No read receipts in this time range"
          }
        />
      </Card>
    );
};
