import React, { Fragment, useEffect } from "react";
import {
  Box,
  StackProps,
  Stack,
  HStack,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import {
  QuickReply,
  useQuickReplies,
} from "../../../data/support/useQuickReplies";
import { WhatsappMessageText } from "../../../components/ChatMessage";
import { useInView } from "react-intersection-observer";
import useDebounce from "../../../hooks/useDebounce";

interface QuickReplyInChatProps extends StackProps {
  flowId: string;
  searchQuery?: string;
  onSelected?: (selectedReply: QuickReply) => void;
}

export const QuickReplyInChat = ({
  flowId,
  onSelected,
  searchQuery = "",
  ...props
}: QuickReplyInChatProps) => {
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { data, hasNextPage, fetchNextPage, status, refetch } = useQuickReplies(
    flowId,
    debouncedSearchQuery,
    true
  );

  const { ref: listRef, inView: isInView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isInView) {
      fetchNextPage();
    }
  }, [isInView]);

  useEffect(() => {
    if (status !== "loading") {
      refetch();
    }
  }, [debouncedSearchQuery]);

  return (
    <Stack
      spacing={0}
      className="bg-white border rounded-md shadow-sm max-h-48 overflow-y-scroll"
      {...props}
    >
      {data && data.pages && data.pages[0].quick_replies.length === 0 && (
        <Text textAlign="center">No quick replies</Text>
      )}
      {data &&
        data.pages &&
        data.pages.map((currentPage, idx) => (
          <Fragment key={idx}>
            {currentPage.quick_replies.map((reply, idx) => (
              <HStack
                key={`${reply.trigger_text}`}
                className="px-2 py-1 border-b hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelected?.(reply)}
              >
                <Text className="w-36 font-bold">/{reply.trigger_text}</Text>
                <Box flex={1}>
                  <WhatsappMessageText text={reply.text} />
                </Box>
                <Box className="px-2 rounded-full border text-xs">
                  {reply.media && reply.media.length === 0 ? "Text" : "Media"}
                </Box>
              </HStack>
            ))}
          </Fragment>
        ))}
      {hasNextPage && (
        <Stack py={2} w="full" align="center" ref={listRef}>
          <CircularProgress color="pink.400" isIndeterminate />
        </Stack>
      )}
    </Stack>
  );
};
