import React from "react";
import Avatar from "boring-avatars";
import { capitlizeFirst } from "../utils/helpers";
import { BoxProps, Stack, Text, Tooltip } from "@chakra-ui/react";

interface AvatarTileProps extends BoxProps {
  name?: string;
  subtitle?: string;
  size?: number;
  square?: boolean;
  showText?: boolean;
  showTooltip?: boolean;
}

export const AvatarTile = ({
  name = "",
  subtitle = "",
  square = false,
  showText = true,
  showTooltip = true,
  size = 24,
  ...props
}: AvatarTileProps) => {
  return (
    <Stack direction="row" align="flex-start" {...props}>
      <div className="avatar-image">
        <Avatar variant="beam" square={square} size={size} name={name} />
      </div>
      {showText && (
        <Stack direction="column" spacing="0">
          <Tooltip label={showTooltip && capitlizeFirst(name)}>
            <Text
              noOfLines={2}
              fontSize={props.fontSize}
              fontWeight={props.fontWeight}
            >
              {capitlizeFirst(name)}
            </Text>
          </Tooltip>
          {subtitle.length !== 0 && (
            // TODO: truncate this bruh
            <Text textColor="blackAlpha.700">{subtitle}</Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
