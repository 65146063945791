import { Circle, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ActiveTagProps } from "./props";

export const ActiveTag = ({ variant = "tag", ...props }: ActiveTagProps) => {
  const defaultStyle =
    props.operation === "+"
      ? {
          bg: "green.50",
          borderColor: "green.200",
          boxShadow: "2px 1px 6px -1px rgba(173,255,195,0.75);",
        }
      : {
          bg: "red.50",
          borderColor: "red.200",
          boxShadow: "2px 1px 6px -1px rgba(255,173,192,0.75);",
        };

  return (
    <Stack
      align="center"
      direction="row"
      px={2}
      py={1}
      borderWidth="thin"
      borderRadius={variant === "tag" ? "sm" : "full"}
      role="group"
      zIndex={999}
      cursor="pointer"
      onClick={() =>
        props.operation === "+" ? props.onExclude?.() : props.onRemove?.()
      }
      {...props}
      {...defaultStyle}
    >
      {variant === "status" && (
        <Circle
          size="2"
          bg={props.operation === "+" ? "green.200" : "red.200"}
        />
      )}
      <Text w="max-content">{props.tag}</Text>
      <Text
        bg={props.operation === "+" ? "green.100" : "red.100"}
        px={2}
        borderRadius="full"
      >
        {props.count}
      </Text>
    </Stack>
  );
};
