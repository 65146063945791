import React, { forwardRef, Fragment } from "react";
import { StackProps, Stack } from "@chakra-ui/react";
import { useUserNotes } from "../../data/user/useUserNotes";
import ForeverProgress from "../Feedback/Progress";
import { UserNoteTile } from "./UserNoteTile";

interface UserNoteListProps extends StackProps {
  flowId: string;
  userId: string;
}

export const UserNoteList = forwardRef<HTMLDivElement, UserNoteListProps>(
  ({ flowId, userId, ...props }, ref) => {
    const { isLoading, isError, data } = useUserNotes(flowId, userId);

    if (isLoading) {
      return <ForeverProgress />;
    }

    return (
      <Stack ref={ref} {...props}>
        {data &&
          data.pages &&
          data.pages.map((currentUserNoteList, idx) => (
            <Fragment key={`${currentUserNoteList.last_document_index}_${idx}`}>
              {currentUserNoteList.notes.map((note, idx) => (
                <UserNoteTile key={`${note._id}_${idx}`} note={note} />
              ))}
            </Fragment>
          ))}
      </Stack>
    );
  }
);
