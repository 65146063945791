import React from "react";
import {
  Box,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  SimpleGrid,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Appbar } from "../../../components/Appbar";
import { InviteList } from "./InviteList";
import { CronEventsList } from "./CronEventsList";

export const History = () => {
  const { id: flowId } = useParams();

  return (
    <Tabs h="100%" size="md" colorScheme="pink">
      <Container p={0} maxW="full" minH="100vh">
        <Appbar
          showHeader={false}
          as={"header"}
          variant="sticky"
          borderBottomWidth="thin"
          boxShadow="sm"
        >
          <Stack direction="column-reverse" h="64px">
            <Box>
              <TabList borderBottom="none">
                <Tab>Invitations</Tab>
                <Tab>Reminders</Tab>
                <Tab>Automations</Tab>
              </TabList>
            </Box>
          </Stack>
        </Appbar>

        <SimpleGrid
          px={8}
          rowGap={4}
          columnGap={10}
          gridTemplateColumns="minmax(0,2.5fr) minmax(0,1fr)"
        >
          <VStack>
            <TabPanels>
              <TabPanel py={8} as={VStack} spacing={4} w="100%">
                <InviteList flowId={flowId} type="Invitation" />
              </TabPanel>
              <TabPanel py={8} as={VStack} spacing={4} w="100%">
                <InviteList flowId={flowId} type="Reminder" />
              </TabPanel>
              <TabPanel py={8} as={VStack} spacing={4} w="100%">
                <CronEventsList flowId={flowId} />
              </TabPanel>
            </TabPanels>
          </VStack>
          <VStack
            align="start"
            alignSelf="flex-start"
            w="100%"
            py={8}
            // minH="calc(100vh - 8rem)"
            // overflow="auto"
            // top="8rem"
            // position="sticky"
          ></VStack>
        </SimpleGrid>
      </Container>
    </Tabs>
  );
};
