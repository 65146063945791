import { useDashboardFlowConfig } from "../data/flow/useDashboardFlow";

export interface FlowInfo {
  id: string;
  name: string;
  attributes: {
    [attributeKey: string]: string;
  };
}

export const useFlowInfo = (
  flowId: string,
  refreshCache: boolean = false
): FlowInfo => {
  const { data: flowConfig } = useDashboardFlowConfig(flowId);

  if (flowConfig) {
    return {
      id: flowId,
      name: flowConfig.bot_name ?? flowId,
      attributes: flowConfig?.dashboard?.summary ?? {},
    };
  }

  return {
    id: flowId,
    name: flowId,
    attributes: {},
  };
};
