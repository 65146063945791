import React, { forwardRef, useState } from "react";
import {
  Stack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { CSVInput } from "../../../../../components/Input/Csv";
import { ExportIcon } from "../../../../../constants/icons";
import { SupportUser } from "../../../../../models/support";
import { generateCSVMapping } from "../../../../../utils";
import { useNewSupportUsers } from "../../../../../mutations/support/useNewSupportUsers";
import { Button } from "../../../../../components/Button";

/**
 * helper function to get the support users from the csv
 * @param csvData
 * @returns array of support users
 */
const getSupportUsersFromCSV = (
  csvData: {
    [key: string]: any;
  }[]
): SupportUser[] => {
  let supportUsers: SupportUser[] = [];
  let headerMappingKeys = {
    Name: `(${["name", "employee"].join("|")})`,
    Email: `(${["mail", "email"].join("|")})`,
    Team: `(${["team", "group"].join("|")})`,
  };
  let headers = generateCSVMapping(csvData, headerMappingKeys);

  csvData.map((eachRow) => {
    const userEmail = eachRow?.[headers["Email"] ?? ""] ?? "";
    const userGroup = eachRow?.[headers["Team"] ?? ""] ?? "";

    if (userEmail)
      supportUsers.push({
        name: eachRow?.[headers["Name"] ?? ""] ?? "",
        admin: false,
        email: eachRow?.[headers["Email"] ?? ""] ?? "",
        groups:
          userGroup === ""
            ? []
            : typeof userGroup === "string"
            ? userGroup.split(",")
            : userGroup,
      });
  });

  return supportUsers;
};

interface NewSupportUserProps {
  flowId: string;

  isOpen: boolean;

  onClose?: () => void;
}

export const NewSupportUser = forwardRef<HTMLDivElement, NewSupportUserProps>(
  ({ flowId, isOpen, onClose }, _) => {
    const toast = useToast();
    const [csvData, setCsvData] = useState<SupportUser[]>([]);

    const addNewSupportUsers = useNewSupportUsers({
      flowId,
      refetchKey: ["support", "users", flowId],
    });

    return (
      <Portal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottomWidth="thin" boxShadow="sm">
              <Heading size="sm">Import Users</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Stack} py={4}>
              <Button
                onClick={() => {
                  window.open("/assets/sample_teams.csv");
                }}
                leftIcon={<ExportIcon />}
                variant="tertiary"
              >
                Download Sample CSV
              </Button>
              <CSVInput
                onParse={(csvRecords) => {
                  if (csvRecords.length === 0) return setCsvData([]);
                  const parsedRecords = getSupportUsersFromCSV(csvRecords);
                  if (csvRecords.length !== 0 && parsedRecords.length === 0)
                    return toast({
                      title: "No Users Detected",
                      description: "Please check the CSV file and try again",
                      status: "warning",
                    });

                  setCsvData(parsedRecords);
                }}
              />
            </ModalBody>
            <ModalFooter hidden={csvData.length === 0} pt={0} pb={4}>
              <Button
                isLoading={addNewSupportUsers.isLoading}
                isDisabled={csvData.length === 0}
                onClick={() => {
                  addNewSupportUsers.mutate(csvData);
                  onClose?.();
                }}
              >
                {csvData.length === 0
                  ? "Import users"
                  : `Import ${csvData.length} users`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
    );
  }
);
