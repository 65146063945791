import React, { forwardRef } from "react";

import { Box, BoxProps } from "@chakra-ui/react";
import { APITypes } from "plyr-react";

import MediaPlyr from "../MediaPlyr";
import { theme } from "../../theme";
import { WhatsappMessageText } from ".";

interface AudioChatMessageProps extends BoxProps {
  message: UserMediaMessage;
}

export const AudioChatMessage = forwardRef<
  HTMLDivElement,
  AudioChatMessageProps
>(({ message, ...props }, _) => {
  const ref = React.useRef<APITypes>(null);

  return (
    <Box
      w="72"
      className="overflow-hidden rounded-md"
      sx={{
        "--plyr-audio-controls-background": theme.colors.gray[50],
        "--plyr-color-main": "#714cd3",
        "--plyr-menu-radius": "10px",
      }}
    >
      <MediaPlyr
        mediaId={message.url}
        ref={ref}
        source={{
          type: "audio" as const,
          sources: [
            {
              type: "audio/wav",
              src: message.url,
            },
          ],
        }}
        options={{
          controls: ["play-large", "play", "progress", "current-time"],
          hideControls: true,
          tooltips: {
            seek: false,
          },
        }}
      />
      {message.caption && <WhatsappMessageText text={message.caption}/>}
    </Box>
  );
});
