import React, { forwardRef } from "react";
import {
  AspectRatio,
  Box,
  BoxProps,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { WhatsappMessageText } from ".";

interface ImageChatMessageProps extends BoxProps {
  message: UserMediaMessage;
}

export const ImageChatMessage = forwardRef<
  HTMLDivElement,
  ImageChatMessageProps
>(({ message, ...props }, ref) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      w="72"
      ref={ref}
      _hover={{
        cursor: "pointer",
      }}
      onClick={onOpen}
      {...props}
    >
      <AspectRatio ratio={1}>
        <Image
          borderRadius="md"
          src={message.url}
          alt={message.caption ?? "user_message"}
          fallbackSrc="https://via.placeholder.com/150"
        />
      </AspectRatio>
      {message.caption && <WhatsappMessageText text={message.caption}/>}
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.800"
          backdropFilter="auto"
          backdropBlur="6px"
        />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={Stack} pb={6}>
            <Image
              borderRadius="md"
              maxH="50vh"
              fit="cover"
              alignSelf="center"
              fallbackSrc='https://via.placeholder.com/150'
              src={message.url}
              alt={message.caption ?? "user_message"}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
});
