import axios from "axios";
import config from "../../config";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";

interface BlockUserProps {
  flowId: string;

  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useBlockUser = ({ flowId, refetchKey }: BlockUserProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    user: { email: userMail },
    getAuthToken,
  } = useAuthToken();

  return useMutation({
    mutationKey: ["block", flowId],
    mutationFn: async (sessionId: string) => {
      const token = localStorage.getItem('token')

      const response = await axios.post<{
        status: "success" | "failed";
        blocked: boolean;
      }>(
        `${config.url}/v2/support/${flowId}/block`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "dashboard-user-id": userMail,
          },
          params: {
            session_id: sessionId,
          },
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({
          title: response.data.blocked ? "User blocked" : "User Unblocked",
          status: "success",
        });
      }

      return response.data;
    },

    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to block user",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
