import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { defaultChartOptions } from "../../utils/constants";
import { Skeleton } from "@chakra-ui/react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, showLegend = false, loading = false }) => {
  return (
    <Skeleton isLoaded={!loading}>
      <Line
        options={{
          ...defaultChartOptions,
          plugins: {
            ...defaultChartOptions["plugins"],
            legend: {
              display: showLegend,
              position: "top",
            },
          },
        }}
        data={data}
      />
    </Skeleton>
  );
};

export default LineChart;
