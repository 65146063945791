import React from "react";
import {
  Button as ChakraButton,
  ButtonProps,
  Tooltip,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export interface WHButtonProps extends ButtonProps {
  variant?: "primary" | "secondary" | "tertiary" | "danger";
  action?: "default" | "delete";
  isBuzzer?: boolean;
  tooltip?: string;
  askConfirmation?: boolean;
  confirmText?: string;
  confirmButton?: string;
  cancelButton?: string;
  confirmAction?: "default" | "delete";
}

const chakraButtonProps = {
  primary: {
    colorScheme: "pink",
    variant: "solid",
    boxShadow: "sm",
  },
  secondary: {
    colorScheme: "pink",
    variant: "outline",
    boxShadow: "sm",
    borderWidth: "thin",
  },
  tertiary: {
    variant: "ghost",
    colorScheme: "pink",
  },
  danger: {
    variant: "outline",
    colorScheme: "red",
  }
};

const Button = ({
  size = "sm",
  variant = "primary",
  askConfirmation = false,
  confirmText = "Confirm",
  confirmButton = "Yes",
  cancelButton = "No",
  confirmAction = "default",
  action = "default",
  ...rest
}: WHButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const buzzerStyle = {
    primary: {
      boxShadow: "0px 5px 0px #73087D",
      border: "1px solid #73087D",
    },
    secondary: {
      boxShadow: "0px 5px 0px #CBD5E0",
      border: "1px solid #CBD5E0",
    },
  };

  let buttonStyle = chakraButtonProps[variant];

  if (rest.isBuzzer) buttonStyle = { ...buttonStyle, ...buzzerStyle[variant] };

  return (
    <>
      <Tooltip label={rest.tooltip}>
        <ChakraButton
          minW="max-content"
          size={size}
          {...rest}
          {...buttonStyle}
          colorScheme={action === "default" ? "pink" : "red"}
          onClick={(e) => {
            if (!askConfirmation) {
              return rest.onClick?.(e);
            }

            onOpen();
          }}
        >
          {rest.children}
        </ChakraButton>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{confirmText}</ModalBody>
          <ModalFooter>
            <Button variant="tertiary" onClick={() => onClose()}>
              {cancelButton}
            </Button>
            <Button
              isLoading={rest.isLoading}
              action={confirmAction}
              onClick={(e) => {
                rest.onClick(e);
                onClose();
              }}
            >
              {confirmButton}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { Button, chakraButtonProps };
