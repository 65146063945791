import React, { forwardRef, useState } from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  BoxProps,
  StackProps,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useUserSummary } from "../data/user/useUserData";

import { Transcript } from "../pages/Flow/Support/Transcript";
import { AvatarTile } from "../components/AvatarTile";
import ForeverProgress from "../components/Feedback/Progress";
import CandidateSummaryText from "../components/CandidateSummaryText";

import { productBackgroundNeutral } from "../theme";
import { isUrl } from "../utils/helpers";

interface UserProfileProps extends StackProps {
  flowId?: string;
  sessionId?: string;
  userId?: string;
  onClose?: () => void;
}

interface UserSummaryTabProps extends BoxProps {
  selected?: boolean;
  tabName: string;
}

const defaultTabs = ["Basic details", "Documents", "Transcript"];

const UserSummaryTab = forwardRef<HTMLDivElement, UserSummaryTabProps>(
  ({ selected = false, tabName, ...props }, ref) => (
    <Box
      ref={ref}
      px={2}
      py={1}
      bg={selected && "gray.100"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        bg: "gray.100",
      }}
      {...props}
    >
      <Text fontSize="sm" fontWeight="medium">
        {tabName}
      </Text>
    </Box>
  )
);

export const UserSummary = (props: UserProfileProps) => {
  const {
    id: locationFlowId,
    sessionId: locationSessionId,
    userId: locationUserId,
  } = useParams();

  const flowId = locationFlowId ?? props.flowId;
  const userId = props.userId ?? locationUserId;
  const sessionId = props.sessionId ?? locationSessionId;

  const [currentTab, setCurrentTab] = useState(0);

  const { isLoading, data: userProfile } = useUserSummary({
    flowId,
    sessionId,
    userId,
  });

  if (isLoading) {
    return (
      <Container maxW="container.xl">
        <ForeverProgress />
      </Container>
    );
  }

  const renderAttributes = (
    attributes: { [key: string]: string },
    showDocuments: boolean = false
  ) => {
    return (
      <Stack className="px-8 py-6 w-full rounded-lg overflow-y-scroll">
        {Object.keys(attributes).map((key, index) => {
          if (isUrl(attributes[key]) === showDocuments)
            return (
              <Stack
                px={4}
                py={2}
                key={`${index}_summary_${key}`}
                justify="space-between"
                borderRadius="md"
                borderWidth="thin"
              >
                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                  {key}
                </Text>
                <CandidateSummaryText
                  fontSize="lg"
                  fontWeight="medium"
                  text={attributes[key]}
                />
              </Stack>
            );
        })}
      </Stack>
    );
  };

  if (userProfile) {
    return (
      <Box
        className="w-full h-full flex overflow-hidden"
        bg={productBackgroundNeutral}
        {...props}
      >
        <Box className="flex-shrink-0 overflow-x-hidden w-64">
          <Stack
            className="min-h-0 dark h-full"
            spacing={0}
            bg={productBackgroundNeutral}
            borderRightWidth="thin"
          >
            <AvatarTile
              p={4}
              size={30}
              fontWeight="medium"
              fontSize="md"
              name={userProfile.table?.Name ?? ""}
              subtitle={userProfile.table?.["Phone Number"] ?? ""}
            />
            <Stack spacing={0} p={4}>
              {defaultTabs.map((tabName, index) => (
                <UserSummaryTab
                  key={`${tabName}_${index}_tab`}
                  tabName={tabName}
                  selected={currentTab === index}
                  onClick={() => setCurrentTab(index)}
                />
              ))}
              {userProfile.test_results &&
                Object.keys(userProfile.test_results).length !== 0 && (
                  <UserSummaryTab
                    tabName={"Test Results"}
                    selected={currentTab === 3}
                    onClick={() => setCurrentTab(3)}
                  />
                )}
            </Stack>
          </Stack>
        </Box>

        <Box className="relative flex-col flex flex-1 h-full overflow-y-auto">
          {currentTab === 0 && renderAttributes(userProfile.summary)}
          {currentTab === 1 && renderAttributes(userProfile.summary, true)}
          {currentTab === 2 && (
            <Transcript
              key={`transcript-${sessionId}`}
              flex={1}
              direction="column-reverse"
              className="p-4 w-full rounded-lg overflow-y-scroll"
              flowId={flowId}
              userId={userId}
              sessionId={sessionId}
            />
          )}
          {currentTab === 3 &&
            userProfile.test_results &&
            renderAttributes(userProfile.test_results)}
        </Box>
      </Box>
    );
  }
};
