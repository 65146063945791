import { Stack, Text, Portal, Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface ToolbarComponentProps extends BoxProps {
  title: string;
  icon?: React.ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  toolbarRef?: React.RefObject<HTMLElement | null>;
}

export const ToolbarComponent = ({
  title,
  ...props
}: ToolbarComponentProps) => {
  const activeToolbarContainerProps = props.isActive
    ? { borderWidth: "thin", borderRadius: "md", px: 4, py: 2 }
    : {};

  const activeToolbarProps = props.isActive
    ? {
        bg: "pink.500",
        color: "white",
        border: "none",
      }
    : { bg: "white", ...props };

  return (
    <Stack
      cursor="pointer"
      px={4}
      align="center"
      justify="center"
      borderWidth="thin"
      borderColor="#ccc"
      borderRadius="xl"
      boxShadow="lg"
      {...activeToolbarProps}
    >
      <Stack direction="row" align="center">
        {props.icon}
        <Text fontSize="sm" color={props.disabled && "InactiveCaptionText"}>
          {title}
        </Text>
      </Stack>
      <Portal containerRef={props.toolbarRef}>
        <Box {...activeToolbarContainerProps}>{props.children}</Box>
      </Portal>
    </Stack>
  );
};
