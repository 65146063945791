import React from "react";
import { ConnectButton } from "360dialog-connect-button";
import { saveEvent } from "../../../api/events";
import axios from "axios";
import { useAuthToken } from "../../../hooks/useAuthToken";

const AddNumber = ({ flowId }) => {
  const { getAuthToken } = useAuthToken();
  const dialog360Url = `https://hub.360dialog.io/api/v2`;
  const partnerId = process.env.REACT_APP_PARTNER_ID;

  const generateApiKeys = (partnerId: string, channels: Array<string>) => {
    channels.forEach(async (channel) => {
      const response = await axios
        .post(
          `${dialog360Url}/partners/${partnerId}/channels/${channel}/api_keys`
        )
        .then(async (res) => {
          console.log(`generate api key response for ${channel}`, res);
          const token = localStorage.getItem('token')
          saveEvent({
            flowId,
            token,
            event: {
              data: {
                event_type: "client_number_api_generation",
                channel_id: channel,
                api_key_data: res.data,
              },
              event: "client_number_dialog360_add",
              uid: partnerId,
            },
          });
        });

      return response;
    });
  };

  const handleCallback = async (callbackObject) => {
    try {
      if (callbackObject.client) {
        console.log("client ID: " + callbackObject.client);
        console.log("channel IDs: " + callbackObject.channels);
        const token = localStorage.getItem('token')

        saveEvent({
          flowId,
          token,
          event: {
            data: {
              event_type: "client_number_signup_dialog360",
              client_id: callbackObject.client,
              channels: callbackObject.channels,
            },
            event: "client_number_dialog360_add",
            uid: partnerId,
          },
        });

        generateApiKeys(callbackObject.client, callbackObject.channels);
      }
    } catch (error) {
      console.log("Error encountered", error);
    }
  };

  return (
    <div>
      <ConnectButton
        className="px-4 py-2 border-2 border-gray-200 rounded-lg bg-[#714CD3] text-white text-base font-bold hover:border-white"
        partnerId={partnerId}
        callback={handleCallback}
        queryParameters={{
          redirect_url: `https://dashboard-staging.workhack.io/dashboard/${flowId}/new`,
        }}
        label="Connect"
      />
    </div>
  );
};

export default AddNumber;
