import React, { useEffect, useState } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { TextField } from "../../../components/TextField";
import { PromptMessage } from ".";

export const PromptMessageTurn = ({
  message,
  ...props
}: {
  message: PromptMessage;
  onChange?: (message: PromptMessage) => void;
  onRemove?: () => void;
}) => {
  const [promptMessage, setPromptMessage] = useState<PromptMessage>(message);
  const [content, setContent] = useState("");
  const [thoughts, setThoughts] = useState("");

  useEffect(() => {
    // when component is mounted and if the message.type == "thoughts", extract the thoughts and content
    if (promptMessage.type === "thoughts") {
      // "You thinks: {}\nYou asks: {}"
      const separation = promptMessage.content.split("\n");
      if (separation.length === 1) return;

      const [thoughts, content] = separation;
      console.log({ thoughts, content });

      setThoughts(thoughts.split("You thinks: ")?.[1] ?? "");
      setContent(content.split("You asks: ")?.[1] ?? "");
    }
  }, []);

  useEffect(() => {
    props.onChange?.(promptMessage);
  }, [promptMessage]);

  useEffect(() => {
    if (promptMessage.type === "thoughts") {
      setPromptMessage({
        ...promptMessage,
        content: `You thinks: ${thoughts}\nYou asks: ${content}`,
      });
    }
  }, [content, thoughts]);

  // bot thinks: {}\nbot asks: {}
  if (promptMessage.type === "thoughts")
    return (
      <VStack spacing={0}>
        <HStack w="full" align="center" borderTopWidth="thin" bg={"gray.100"}>
          <Text
            w="3rem"
            px={2}
            fontSize="xl"
            textAlign="center"
            fontWeight="bold"
            borderRightWidth="thin"
          >
            🤔
          </Text>
          <TextField
            flex={1}
            value={thoughts}
            variant="unstyled"
            onChange={(event) => setThoughts(event.target.value)}
            size="sm"
            bg="transparent !important"
            border="none"
            fontStyle="italic"
            placeholder="thinking..."
          />
        </HStack>
        <HStack w="full" align="center" borderTopWidth="thin">
          <Text
            w="3rem"
            px={2}
            fontSize="xl"
            textAlign="center"
            fontWeight="bold"
            borderRightWidth="thin"
          >
            🔮
          </Text>
          <TextField
            flex={1}
            value={content}
            variant="unstyled"
            onChange={(event) => setContent(event.target.value)}
            size="sm"
            bg="transparent !important"
            border="none"
          />
        </HStack>
      </VStack>
    );

  return (
    <HStack
      w="full"
      // role="group"
      align="center"
      borderTopWidth="thin"
    >
      <Text
        w="3rem"
        px={2}
        fontSize="xl"
        textAlign="center"
        fontWeight="bold"
        borderRightWidth="thin"
      >
        {promptMessage.role === "user" ? "👨🏻‍🦲" : "🔮"}
      </Text>
      <TextField
        flex={1}
        value={promptMessage.content}
        variant="unstyled"
        onChange={(event) =>
          setPromptMessage({ ...promptMessage, content: event.target.value })
        }
        size="sm"
        bg="transparent !important"
        border="none"
      />
    </HStack>
  );
};
