import React, { useState, useEffect } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import workhackLogo from "../img/workhack_logo.svg";
import { useStoreState, useStoreActions } from "../hooks/store";
import { getBotsAssigned } from "../api/root";
import { SearchIcon } from "../constants/icons";
import { Appbar } from "../components/Appbar";
import { UserMenu } from "../components/UserMenu";
import { OrgFlow } from "../models";
import { colorSystem } from "../theme";
import { FlowRecordTile } from "../components/FlowRecord";
import { AuthContext } from "../store/authContext";
const { innerShadow } = colorSystem;

const BOT_SEARCH_INPUT_KEY = "bot_search_input";

export const Home = () => {
  const { user } = React.useContext(AuthContext);
  const redirect = useNavigate();
  const cachedFlows = useStoreState((state) => state.flows);
  const cachedInput = useStoreState(
    (state) => state.inputs[BOT_SEARCH_INPUT_KEY]
  );

  const cacheUpdatedFlows = useStoreActions((actions) => actions.cacheFlows);
  const cacheInputs = useStoreActions((actions) => actions.cacheInputs);
  const clearCachedFlows = useStoreActions((actions) => actions.clearFlows);

  const [search, setsearch] = useState("");
  const [bots, setbots] = useState<OrgFlow[]>([]);

  const searchResult = bots.filter((bot) =>
    bot.bot_name.toLowerCase().includes(search.toLowerCase())
  );

  const getBotsFromAPI = async (token: string) => {
    let data = await getBotsAssigned({
      email: user.email,
      name: user.username,
      token,
    }).catch((err) => {
      console.log(err);
      clearCachedFlows();
    });

    if (data) {
      setbots(data);
      cacheUpdatedFlows(data);
    }
  };

  useEffect(() => {
    /**
     * checks if there are cached bots, if not fetch from api
     * if there are cached bots, restore them and then fetch from api to update the cache
     */
    const fetchBots = async () => {
      const token = localStorage.getItem('token')

      if (Object.keys(cachedFlows).length != 0) {
        console.log("restoring cached bots from local storage");
        Object.keys(cachedFlows).map((flowId) =>
          setbots((state) => [...state, cachedFlows[flowId]])
        );

        // refresh bots from api
        return getBotsFromAPI(token);
      }

      return getBotsFromAPI(token);
    };

    const restoreStoredInput = () => {
      // restores the cached input
      if (cachedInput) setsearch(cachedInput);
    };

    restoreStoredInput();
    fetchBots();
  }, []);

  return (
    <SimpleGrid templateColumns="16rem auto">
      <Stack
        h="100vh"
        position="sticky"
        top={0}
        bottom={0}
        as="aside"
        justify="space-between"
        bg="gray.200"
        boxShadow={innerShadow}
        borderRightWidth="thin"
      >
        <Stack p={4} direction="row" align="center">
          <Image src={workhackLogo} />
          <Text color="pink.500" fontWeight="medium" fontSize="lg">
            Dashboard
          </Text>
        </Stack>

        <UserMenu />
      </Stack>
      <Stack spacing={0} maxH="100vh">
        <Appbar
          variant="sticky"
          px={8}
          py={4}
          m={0}
          showHeader={false}
          background="rgba(255,255,255,.8)"
          backdropFilter="blur(24px)"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              value={search}
              placeholder="Search here.."
              onChange={(event) => {
                setsearch(event.currentTarget.value);
                cacheInputs({
                  key: BOT_SEARCH_INPUT_KEY,
                  input: event.currentTarget.value,
                });
              }}
            />
          </InputGroup>
        </Appbar>
        <Stack flex={1} overflowY="scroll" bg="white">
          <SimpleGrid
            p={6}
            templateColumns="repeat(3, 1fr)"
            rowGap="8"
            columnGap={8}
          >
            {searchResult.map((botInfo) => (
              <FlowRecordTile
                key={botInfo.bot_id}
                name={botInfo.bot_name}
                type={botInfo.bot_type}
                onClick={() => redirect(`/dashboard/${botInfo.bot_id}/`)}
              />
            ))}
          </SimpleGrid>
        </Stack>
      </Stack>
    </SimpleGrid>
  );
};
