import { Box, BoxProps } from "@chakra-ui/react";
import React, { CSSProperties } from "react";

interface CardProps extends BoxProps {
  onHover?: CSSProperties;
}

const Card = ({ children, boxShadow = "sm", ...rest }: CardProps) => {
  return (
    <Box
      p={4}
      gridArea={rest.gridArea}
      border="1px solid"
      borderColor="gray.200"
      boxShadow={boxShadow}
      borderRadius="md"
      _hover={rest.onHover}
      style={rest.style}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
