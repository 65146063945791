import React, { useEffect } from "react";
import { APITypes, PlyrInstance, PlyrProps, usePlyr } from "plyr-react";
import { useStoreState, useStoreActions } from "../hooks/store";

/* This code defines a custom React component called `CustomPlyrInstance` that uses the
`React.forwardRef` function to forward a ref to a child component. The component takes in two props:
`source` and `options`, which are used to configure the Plyr instance. */

interface MediaPlyrProps extends PlyrProps {
  mediaId: string;
}

const MediaPlyr = React.forwardRef<APITypes, MediaPlyrProps>((props, ref) => {
  const { source, options = null } = props;
  const raptorRef = usePlyr(ref, { options, source });

  const currentPlayingMedia = useStoreState(
    (store) => store.currentPlayingMedia
  );
  const updateCurrentPlayingMedia = useStoreActions(
    (actions) => actions.updateCurrentPlayingMedia
  );

  const fetchPlyrInstance = (): PlyrInstance | null => {
    const { current } = ref as React.MutableRefObject<APITypes>;
    if (current.plyr.source === null) return;

    /* This code is accessing the Plyr instance API and adding event listeners to it. */
    const api = current as { plyr: PlyrInstance };
    return api.plyr;
  };

  useEffect(() => {
    const plyrInstance = fetchPlyrInstance();
    if (plyrInstance) {
      plyrInstance.on("play", () => {
        updateCurrentPlayingMedia(props.mediaId);
      });
    }
  });

  useEffect(() => {
    if (currentPlayingMedia === props.mediaId) {
      return;
    } else {
      const plyrInstance = fetchPlyrInstance();
      if (plyrInstance) {
        // pause the previous playing media
        plyrInstance.pause();
      }
    }
  }, [currentPlayingMedia]);

  return (
    <video
      ref={raptorRef as React.MutableRefObject<HTMLVideoElement>}
      className="plyr-react plyr"
    />
  );
});

export default MediaPlyr;
