import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

interface UserAttributes {
  first_name: string;
  last_name: string;
  phone_number: string;
  client_id: string;
  created_at: number;
  modified_at: number;
  org_id: string;
  user_data: {
    [attributeKey: string]: any;
  };
}

export const useUserAttributes = (orgId: string, conversationId: string) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchUserAttributes = async () => {
    const res = await fetchWithAuth<UserAttributes>({
      url: `${config.url}/api/v1/users/${orgId}/${conversationId}`,
    });

    return res;
  };

  return useQuery(
    ["user_attributes", orgId, conversationId],
    fetchUserAttributes,
    {
      refetchInterval: 1000 * 30 * 60, // 30 mins
    }
  );
};
