import axios from "axios";
import config from "../../config";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../hooks/useAuthToken";
import { MutationInput } from "../../models";

export interface AttributeFilter {
  attribute_key: string;
  attribute_value: any;
  operation: "in" | "nin";
}

export const useFilterAttributes = ({
  flowId,
  refetchKey = [],
}: MutationInput) => {
  const queryClient = useQueryClient();
  const { user, getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["filter", "user_attributes"],
    mutationFn: async (filters: AttributeFilter[]) => {
      const token = localStorage.getItem('token')

      const response = await axios.post<string[]>(
        `${config.url}/v2/attributes/filter/${flowId}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Email": user.email,
            "dashboard-user-id": user.email,
            "user-id": user.email,
          },
        }
      );

      return response.data;
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
