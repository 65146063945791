import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";
import { sendMessage } from "../../api/support";

interface SendMessageProps {
  flowId: string;
  // query key to refetch after mutation is completed
  refetchKey: string[];

  // id of the session
  sessionId: string;
}

export type ReplyMessage = string | FlowRemoteMessage | FlowRemoteAudioMessage | {};

export const useSendMessage = ({
  flowId,
  refetchKey,
  sessionId,
}: SendMessageProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    getAuthToken,
    user: { email: userMail },
  } = useAuthToken();

  return useMutation({
    mutationKey: ["support", "send_message", flowId, sessionId],
    mutationFn: async ({
      message,
      step = "",
      onSuccess,
    }: {
      // text to reply
      message: ReplyMessage;
      // micro flow step
      step?: string;
      // onSuccess
      onSuccess?: () => void;
    }) => {
      if (message === "") return;

      const token = localStorage.getItem('token')

      const response = await sendMessage({
        flowId,
        userMail,
        message,
        step,
        conversationOId: sessionId,
        token: token,
      });

      if (response.status === "success") onSuccess?.();

      return response;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to send message",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
