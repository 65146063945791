import { parse as CSVParser, unparse as ConvertCSV } from "papaparse";
import { IntentUnion } from "../models";
import { InvitationUser } from "../models/property";
import { getCSVHeaders } from "./helpers";
import { uploadKeys } from "./constants";
import { AthenaFact } from "../api/flow";

export const parseCSV = (result: string) => {
  const results = CSVParser(result, { header: true, skipEmptyLines: true }); // object with { data, errors, meta }
  return results;
};

export const generateCSVExport = ({
  records,
  filename = "export.csv",
  onExportError,
  onExportSuccess,
}: {
  records: any[];
  filename?: string;
  onExportError?: (error: any) => void;
  onExportSuccess?: () => void;
}) => {
  try {
    console.log(`exporting ${records.length} users`);

    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    var csv = ConvertCSV(records, { header: true });
    const blobFile = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blobFile);
    a.href = url;
    a.download = filename;
    a.click();
    onExportSuccess?.();
    return window.URL.revokeObjectURL(url);
  } catch (error) {
    onExportError?.(error);
  }
};

/**
 * generates a mapping of the csv headers to the keys
 * @param rows
 * @param keys
 * @returns
 */
export const generateCSVMapping = (
  rows: { [key: string]: string }[],
  keys: { [header: string]: string } = uploadKeys
) => {
  const headers = getCSVHeaders(rows);
  let columns = {};

  Object.keys(keys).map((col) => {
    headers.map((csvCol) => {
      let pattern = new RegExp(keys[col], "i");
      if (csvCol.match(pattern)) {
        console.log(`matched ${csvCol}`);
        if (!columns[col]) columns[col] = csvCol;
      }
    });
  });

  return columns;
};

export const generateInviteUsers = (
  csvUsers: any[],
  keysToInclude?: { [key: string]: string }
): InvitationUser[] => {
  let headers = generateCSVMapping(csvUsers);
  let inviteUsers: InvitationUser[] = [];

  let defaultKeysToInclude = {
    id: "Id",
    email: "Email",
    company: "Company",
    state: "State",
    city: "City",
  };

  if (keysToInclude)
    defaultKeysToInclude = { ...defaultKeysToInclude, ...keysToInclude };

  csvUsers.map((each) => {
    let inviteUser: InvitationUser = {
      name: each?.[headers["Name"] ?? ""] ?? "",
      conversation_id: each?.[headers["Phonenumber"] ?? ""] ?? "",
      data: each,
    };

    Object.keys(defaultKeysToInclude).map((key) => {
      let keyToInclude = defaultKeysToInclude[key];
      keyToInclude = each?.[headers[defaultKeysToInclude[key]]] ?? "";
      if (keyToInclude) inviteUser.data[key] = keyToInclude;
    });

    inviteUsers.push(inviteUser);
  });

  console.log(inviteUsers);
  return inviteUsers;
};

/**
 * helper function to get the attribute keys from the csv
 * @param attributes - object with attribute title as key and attribute key as value
 * returns array of attribute keys to be included from the csv
 */
export const getAttributeKeysFromCSV = (attributes: {
  [attributeTitle: string]: string;
}) => {
  return Object.entries(attributes).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
};

export const replaceAttributes = (
  stringToFormat: string,
  attributes: { [attributeKey: string]: string }
) => {
  console.log(stringToFormat, attributes);

  return stringToFormat.replace(/\{([^{}]+)\}/g, (match, key) => {
    return `{${attributes[key] ?? key}}` || match;
  });
};

export const generateTimestamp = (date: string, time: string = "00:00") => {
  /**
   * @param date: string - date in YYYY-MM-DD format
   * @param time: string - time in HH:MM format
   * @returns timestamp in seconds
   * 0 if error
   */

  try {
    const [hh, mm] = time.split(":");
    const selectedDate = new Date(date);

    selectedDate.setHours(parseInt(hh));
    selectedDate.setMinutes(parseInt(mm));

    return selectedDate.getTime() / 1000;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

/**
 * @param intents - list of intents
 * @param path - path to access the intents
 * @returns list of intents at the given path
 */
export const getIntentsByPath = (
  intents: IntentUnion[],
  path: string
): IntentUnion[] => {
  if (path === "") return intents;

  const pathParts = path.split(".");
  let pathIntents: IntentUnion | IntentUnion[];

  for (let i = 0; i < pathParts.length; i++) {
    let key = pathParts[i];

    if (i === 0) {
      pathIntents = intents[parseInt(key)];
      continue;
    }

    if (pathIntents) {
      if (Array.isArray(pathIntents)) {
        pathIntents = pathIntents[parseInt(key)];
        continue;
      } else {
        if (pathIntents.type === "branch") {
          pathIntents = pathIntents.intents[key];
          continue;
        }

        throw new Error("Invalid path provided");
      }
    }
  }

  if (Array.isArray(pathIntents)) return pathIntents;
  else return [pathIntents];
};

/**
 * @param intents - original list of intents
 * @param path - path to access the intents
 * @returns updated list of intents
 */
export const updateIntentsByPath = (
  // originalIntents: IntentUnion[],
  intents: IntentUnion[],
  path: string,
  updateRequest: IntentUnion[]
) => {
  if (path === "") return updateRequest;

  const pathParts = path.split(".");
  let updatedIntents = [...intents];
  let pathIntents: IntentUnion | IntentUnion[] = updatedIntents;

  for (let i = 0; i < pathParts.length; i++) {
    let key = pathParts[i];

    if (i === 0) {
      pathIntents = updatedIntents[parseInt(key)];
      continue;
    }

    if (pathIntents) {
      if (Array.isArray(pathIntents)) {
        pathIntents = pathIntents[parseInt(key)];
        continue;
      } else {
        if (pathIntents.type === "branch") {
          pathIntents = pathIntents.intents[key];
          continue;
        }

        throw new Error("Invalid path provided");
      }
    }
  }

  if (Array.isArray(pathIntents)) {
    // we have to replace the entire array, so we need length of the parent array
    pathIntents.splice(0, pathIntents.length, ...updateRequest);
  }

  return updatedIntents;
};

/**
 * updates the branch in the path
 * @param path - path to access the intents
 * @param newBranch - new branch to be added to the path
 * @returns - updated path
 */
export const updateBranchInPath = (path: string, newBranch: string) => {
  const lastIndex = path.lastIndexOf(".");
  if (lastIndex !== -1) {
    const newPath = path.substring(0, lastIndex + 1) + newBranch;
    return newPath;
  }
  return path;
};

export const getRowsFromFacts = (facts: AthenaFact[]) => {
  // const header = "Questions,Answer,Media\n";
  const header = ["Questions", "Answer", "Media", "Actions"];
  const rows = facts.map((fact) => {
    const questions = fact.questions.join("\n");
    const actions = (fact.data?.followup_buttons ?? []).join("\n");
    const answer = fact.answer;
    let remote_media_attachment = "";

    if (
      fact.remote_media_attachment &&
      fact.remote_media_attachment.length !== 0
    )
      remote_media_attachment = fact.remote_media_attachment[0].link;

    return [questions, answer, remote_media_attachment, actions];
  });
  rows.unshift(header);

  return rows;
};

type AttachmentType = "image" | "document" | "video" | "audio";

export const getFileType = (attachment: File): AttachmentType => {
  let attachmentType: AttachmentType = "document";

  switch (attachment.type.split("/")[0]) {
    // check if attachment is image
    case "image":
      attachmentType = "image";
      break;
    // check if attachment is video
    case "video":
      attachmentType = "video";
      break;
    // check if attachment is audio
    case "audio":
      attachmentType = "audio";
      break;
    // check if attachment is document
    case "application":
      attachmentType = "document";
      break;
    default:
      attachmentType = "document";
      break;
  }

  return attachmentType;
};

/**
 * @desc checks if user is in session
 * @param last_user_message
 * @returns boolean - true if user is in session, false otherwise
 */
export const isUserInSession = (last_user_message?: number) => {
  if (last_user_message) {
    let now = Date.now() / 1000;
    let diff = now - last_user_message;
    return diff < 86400;
  }

  return false;
};

export const isDashboardUserOnline = (last_online?: number) => {
  if (last_online) {
    let now = Date.now() / 1000;
    let diff = now - last_online;
    return diff < 300;
  }

  return false;
};

export const getWhatsappFailedMessage = (
  data: { [key: string]: any },
  defaultErrorMessage: string = "Server Unaccessible"
) => {
  if (data["failed_data"]) {
    const error: { code?: number; title?: string } = data["failed_data"];
    return error.title ?? defaultErrorMessage;
  }

  if (data["data"] && data["data"]["failed_data"]) {
    const error: { code?: number; title?: string }[] =
      data["data"]["failed_data"];
    return error[0]
      ? error[0].title ?? defaultErrorMessage
      : defaultErrorMessage;
  }

  if (data["message_response"] && data["message_response"]["meta"]) {
    const error: {
      meta: { success?: boolean; developer_message?: string };
      errors?: { code?: number; title?: string }[];
    } = data["message_response"];
    return (
      error.meta.developer_message ??
      (error.errors
        ? error.errors?.[0].title ?? defaultErrorMessage
        : defaultErrorMessage)
    );
  }

  if (data["error"] && Array.isArray(data["error"])) {
    const error: {
      response: {
        code?: number;
        message?: string;
        meta?: { developer_message?: string };
        errors?: {details?: string}[]
      };
    }[] = data["error"];

    return error[0]
      ? error[0].response?.message ??
          error[0].response?.meta?.developer_message ??
          error[0].response?.errors?.[0]?.details ??
          defaultErrorMessage
      : defaultErrorMessage;
  }

  return defaultErrorMessage;
};
