import React, { forwardRef } from "react";
import {
  HStack,
  Stack,
  Text,
  Image,
  StackProps,
  ButtonGroup,
} from "@chakra-ui/react";
import { useRemoveQuickReply } from "../../../../../mutations/support/useRemoveQuickReply";
import { QuickReply } from "../../../../../data/support/useQuickReplies";
import { Button } from "../../../../../components/Button";
import { CloseIcon, EditIcon } from "../../../../../constants/icons";
import { WhatsappMessageText } from "../../../../../components/ChatMessage";

interface QuickReplyTileProps extends StackProps {
  reply: QuickReply;
  onEdit?: () => void;
}

export const QuickReplyTile = forwardRef<HTMLDivElement, QuickReplyTileProps>(
  ({ reply, onEdit, ...props }, ref) => {
    const removeReply = useRemoveQuickReply({
      flowId: reply.flow_id,
      refetchKey: ["quick_replies", reply.flow_id],
    });
    return (
      <HStack
        ref={ref}
        align="start"
        className="px-4 py-2 border-b"
        key={reply.trigger_text}
        role="group"
        {...props}
      >
        <Text className="w-48 font-bold break-normal">
          /{reply.trigger_text}
        </Text>
        <Stack flex={1}>
          <WhatsappMessageText text={reply.text} />
          {reply.media &&
            reply.media.length !== 0 &&
            reply.media.map((media) => {
              if (media.type === "image") {
                return <Image borderRadius="sm" w={24} src={media.link} />;
              }

              return <></>;
            })}
        </Stack>
        <ButtonGroup>
          <Button
            visibility="hidden"
            aria-label={"edit"}
            leftIcon={<EditIcon />}
            size="xs"
            variant="tertiary"
            _groupHover={{ visibility: "visible" }}
            onClick={() => onEdit?.()}
          >
            Edit
          </Button>
          <Button
            askConfirmation
            visibility="hidden"
            confirmText="Are you sure you want to delete this?"
            aria-label={"remove"}
            leftIcon={<CloseIcon />}
            size="xs"
            variant="tertiary"
            action="delete"
            onClick={() => removeReply.mutate(reply.trigger_text)}
            confirmAction="delete"
            confirmButton="Delete"
            _groupHover={{ visibility: "visible" }}
          >
            Delete
          </Button>
        </ButtonGroup>
      </HStack>
    );
  }
);
