import React, { useEffect, useState } from "react";
import { Stack, Box, Text, IconButton } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { ChatBubble } from "../components/ChatBubble";
import { Button } from "./Button";
import { CheckIcon } from "../constants/icons";
import { TextField } from "./TextField";
import { SelectAttributes } from "./Input";

type IntentMessageProps = {
  sender?: "user" | "bot";
  message: string;
  canEdit?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  isAIMessage?: boolean;
  buttons?: string[];
  showEmptyMessage?: boolean;
  onChange?: (message: string) => void;
  onSave?: (message: string) => void;
  setIsEditing?: (isEditing: boolean) => void;
  onButtonClick?: (button: string) => void;
};

export const IntentMessage = ({
  message = "",
  sender = "bot",
  canEdit = false,
  isLoading = false,
  isAIMessage = false,
  showEmptyMessage = false,
  buttons = [],
  ...props
}: IntentMessageProps) => {
  const { botId, id } = useParams();

  const flowId = botId ?? id;

  const [input, setInput] = useState(message);

  const _handleKeyDown = (key: string, shiftKey: boolean) => {
    if (key === "Enter" && !shiftKey) {
      props.onSave?.(input);
    }
  };

  useEffect(() => {
    props.onChange?.(input);
  }, [input]);

  if (isLoading) return <Box className="dot-flashing" />;

  return props.isEditing ? (
    <Stack direction={sender === "bot" ? "row" : "row-reverse"} align="center">
      <ChatBubble
        onClick={() => canEdit && props.setIsEditing?.(true)}
        sender={sender}
        bg="white"
        color="black"
        borderWidth="thin"
        borderColor={sender === "bot" ? "pink.600" : "gray.400"}
        w="100%"
        maxW="100%"
        _before={null}
        _after={null}
        shape="bubble"
      >
        <TextField
          p={2}
          variant="unstyled"
          resize="none"
          value={input}
          onChange={(event) => setInput(event.currentTarget.value)}
          onKeyDown={(event) => _handleKeyDown(event.key, event.shiftKey)}
          size="lg"
          borderRadius="lg"
          bg="transparent !important"
          border="none"
          borderWidth="unset"
          w="100%"
        />
        {input.includes("@") && !input.includes("@ ") && (
          <SelectAttributes
            className={`h-[30vh] z-10 absolute rounded-md p-2 bg-white shadow-lg w-72 overflow-y-scroll`}
            flowId={flowId}
            query={input.slice(input.indexOf("@") + 1)}
            onAttributeSelect={(attribute, isExternal) =>
              setInput(
                (intentBody) =>
                  intentBody.substring(0, intentBody.indexOf("@")) +
                  `{${isExternal ? `attributes.${attribute}` : attribute}}`
              )
            }
            title="Choose attribute"
          />
        )}
      </ChatBubble>
      {props.isEditing && (
        <IconButton
          icon={<CheckIcon />}
          aria-label={"save the reply"}
          variant="ghost"
          size="sm"
          onClick={() => props.onSave?.(input)}
        />
      )}
    </Stack>
  ) : (
    <Stack w="full">
      <ChatBubble
        onClick={() => canEdit && props.setIsEditing?.(true)}
        sender={sender}
        bg={isAIMessage ? "pink.600" : null}
        color={isAIMessage ? "pink.50" : null}
        shape="bubble"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.12);"
      >
        <Text
          fontSize="md"
          dangerouslySetInnerHTML={{
            __html: (showEmptyMessage
              ? message
              : message === ""
              ? "hmm"
              : message
            )
              .replace(/\n\n/g, "\n")
              .replace(/\n/g, "<br/><br/>"),
          }}
        />
      </ChatBubble>
      {buttons.length !== 0 && (
        <Stack direction="row">
          {buttons.map((button) => (
            <Button
              isBuzzer
              variant="secondary"
              onClick={() => props.onButtonClick?.(button)}
            >
              {button}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
