import React, { forwardRef, useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Portal,
  Box,
  Stack,
  Text,
  Image,
  useDisclosure,
  Checkbox,
  HStack,
} from "@chakra-ui/react";

import emptyImageSrc from "../../img/empty.svg";
import { Button, WHButtonProps as ButtonProps } from "../Button";
import { TextField } from "../TextField";

interface DropdownProps extends Omit<ButtonProps, "onChange"> {
  multiSelect?: boolean;

  // available options
  options?: string[];

  // default selected options
  defaultSelected?: string[];

  // helper text to show when no options are available
  helperText?: string;

  // closes the dropdown when an option is clicked
  closeOnSelect?: boolean;

  // callback when an option is clicked
  onOptionClick?: (option: string) => void;

  // callback when options are changed
  onChange?: (options: string[]) => void;

  renderOptionTrailing?: (option: string, index: number) => React.ReactNode;
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      options = [],
      defaultSelected = [],
      helperText = "No options available",
      closeOnSelect = false,
      multiSelect = true,
      onOptionClick,
      onChange,
      renderOptionTrailing,
      ...props
    },
    ref
  ) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [selectedOptions, setSelectedOptions] =
      useState<string[]>(defaultSelected);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleOptionClick = (option?: string) => {
      if (!option) return;
      onOptionClick?.(option);

      if (!multiSelect) {
        setSelectedOptions([option]);
        if (closeOnSelect) onClose();
        return;
      }

      if (selectedOptions.includes(option)) {
        // remove option from selected options
        setSelectedOptions((prev) => prev.filter((o) => o !== option));
      } else {
        // add option to selected options
        setSelectedOptions((prev) => [...prev, option]);
      }

      if (closeOnSelect) onClose();
    };

    useEffect(() => {
      onChange?.(selectedOptions);
    }, [selectedOptions]);

    useEffect(() => {
      setSelectedOptions(defaultSelected);
    }, [defaultSelected]);

    return (
      <Popover
        isLazy
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          setSearchQuery("");
          onClose?.();
        }}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Box ref={ref} maxW="min-content">
            <Button {...props}></Button>
          </Box>
        </PopoverTrigger>

        <Portal>
          <PopoverContent border="none">
            <PopoverBody
              as={Stack}
              spacing={0}
              className="bg-white border border-[#F2F4F7] rounded-md"
              boxShadow="0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)"
            >
              <TextField
                minH={8}
                autoFocus
                bg="transparent !important"
                variant="unstyled"
                placeholder="Search..."
                border="none"
                value={searchQuery}
                hidden={options.length === 0}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Stack
                spacing={0}
                h="56"
                justify="center"
                align="center"
                hidden={options.length !== 0}
              >
                <Image src={emptyImageSrc} />
                <Text className="text-base text-[#1D2939]">{helperText}</Text>
              </Stack>
              <Stack
                hidden={options.length === 0}
                spacing={0}
                className="border-t h-64 overflow-y-scroll"
              >
                {options
                  .filter((option) =>
                    option.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((option, index) => (
                    <Checkbox
                      className="w-full p-2 rounded hover:bg-[#F2F4F7] hover:cursor-pointer"
                      isChecked={selectedOptions.includes(option)}
                      key={`select_${option}_${index}`}
                      onChange={(e) => handleOptionClick(option)}
                    >
                      <HStack minW={64}>
                        <p className="flex-1 text-sm">{option}</p>
                        {renderOptionTrailing?.(option, index)}
                      </HStack>
                    </Checkbox>
                  ))}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
