import React, { forwardRef, useRef } from "react";
import { HStack, Textarea, TextareaProps } from "@chakra-ui/react";
import { useAutosizeTextArea } from "../hooks/useAutosizeTextArea";

interface TextFieldProps extends TextareaProps {
  // TODO: add custom validations
  // validations?: {
  //   required?: boolean;
  //   minLength?: number;
  //   maxLength?: number;
  //   allowNewLine?: boolean;
  // };
  // component to be rendered on the right side of the text field

  trailing?: React.ReactNode;
}

export const TextField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
  ({ value, ...props }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const textInputRef =
      (ref as React.RefObject<HTMLTextAreaElement>) ?? inputRef;

    useAutosizeTextArea(textInputRef.current, value);

    return (
      <HStack flex={props.flex} spacing={0} hidden={props.hidden}>
        <Textarea
          px={2}
          ref={textInputRef}
          rows={1}
          resize="none"
          borderWidth="thin"
          {...props}
          value={value}
        />
        {props.trailing}
      </HStack>
    );
  }
);
