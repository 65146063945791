import React, { forwardRef, useState, useEffect } from "react";
import {
  Button as ChakraButton,
  Stack,
  StackProps,
  Input,
  InputGroup,
  InputRightElement,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  InputLeftElement,
  ButtonGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { FilterQuery } from "../../../components/Filter/models";
import {
  ChevronDownIcon,
  CloseIcon,
  SearchIcon,
} from "../../../constants/icons";
import { BsSortDownAlt } from "react-icons/bs";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { SortGroup } from "../../../api/users";

interface ChatListFilterProps extends Omit<StackProps, "onChange"> {
  onChange?: (filters: FilterQuery[]) => void;
  onSortChange?: (sortGroup: SortGroup[]) => void;
}

const AGENT_FILTER_KEY = "data.support_query.agent.email";
const STATUS_FILTER_KEY = "data.support_query.status";

export const ChatListFilters = forwardRef<HTMLDivElement, ChatListFilterProps>(
  ({ onChange, onSortChange, ...props }, ref) => {
    const {
      user: { email: currentSupportAgentId },
    } = useAuthToken();

    const [filters, setFilters] = useState<FilterQuery[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string>("All");
    const [agentFilter, setAgentFilter] = useState<"me" | "all">("all");
    const [sortFilter, setSortFilter] = useState<"desc" | "asc">("desc");

    // helper function to update array
    const updateFilters = (
      updatedFilters: FilterQuery[],
      filterKey: string = ""
    ) => {
      let filterKeys = [];
      let finalFilters: FilterQuery[] = [];

      if (updatedFilters.length === 0 && filterKey) filterKeys.push(filterKey);
      else updatedFilters.map((filter) => filterKeys.push(filter.key));

      if (filterKeys.length === 0) return;

      const hasKeyFilter = filters.filter((filter) =>
        filterKeys.includes(filter.key)
      );

      // No Filters with existing keys
      if (hasKeyFilter.length === 0)
        finalFilters = [...filters, ...updatedFilters];
      else {
        const removedFilters = filters.filter(
          (filter) => !filterKeys.includes(filter.key)
        );
        finalFilters = [...removedFilters, ...updatedFilters];
      }

      // remove existing
      setFilters(
        finalFilters.filter((filter) => {
          if (filter.value) {
            return filter.value.length !== 0;
          } else return false;
        })
      );
    };

    useEffect(() => {
      const getData = setTimeout(() => {
        let searchValue = `${searchQuery}`;
        searchValue.trim();

        const searchKey = "conversation_id";
        let searchFilter: FilterQuery = {
          key: searchKey,
          operation: "regex",
          value: searchValue,
        };

        const isPhoneNumber = /\d+/.test(searchValue);

        if (isPhoneNumber) {
          let hasMultipleNumbers = searchValue.split(/[\s,]+/);
          // if space or comma is detected
          if (hasMultipleNumbers.length > 1)
            searchFilter = {
              ...searchFilter,
              operation: "in",
              value: hasMultipleNumbers,
            };
        }

        updateFilters([searchFilter], searchKey);
      }, 500);

      return () => clearTimeout(getData);
    }, [searchQuery]);

    useEffect(() => {
      if (statusFilter === "All") {
        setFilters(
          filters.filter((filter) => filter.key !== STATUS_FILTER_KEY)
        );
      } else {
        updateFilters(
          [
            {
              key: STATUS_FILTER_KEY,
              operation: "in",
              value: [statusFilter.toLowerCase()],
            },
          ],
          STATUS_FILTER_KEY
        );
      }
    }, [statusFilter]);

    useEffect(() => {
      if (agentFilter === "all") {
        setFilters(filters.filter((filter) => filter.key !== AGENT_FILTER_KEY));
      } else {
        updateFilters(
          [
            {
              key: AGENT_FILTER_KEY,
              operation: "in",
              value: [currentSupportAgentId],
            },
          ],
          AGENT_FILTER_KEY
        );
      }
    }, [agentFilter]);

    useEffect(() => {
      onChange?.(filters);

      return () => onChange?.([]);
    }, [filters]);

    return (
      <Stack
        p={4}
        spacing={4}
        pos="sticky"
        zIndex={1000}
        borderBottomWidth="thin"
        ref={ref}
        {...props}
      >
        <InputGroup size="sm" maxW="full">
          <InputLeftElement children={<SearchIcon />} />
          <Input
            flex={1}
            placeholder="Search for people or conversations .."
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
          />
          <InputRightElement>
            {searchQuery.length !== 0 && (
              <CloseIcon onClick={() => setSearchQuery("")} />
            )}
          </InputRightElement>
        </InputGroup>
        <HStack justify="space-between">
          <ButtonGroup>
            <Menu>
              <MenuButton
                w="max-content"
                as={ChakraButton}
                size="sm"
                rightIcon={<ChevronDownIcon />}
              >
                {statusFilter}
              </MenuButton>
              <MenuList boxShadow="lg">
                <MenuOptionGroup
                  defaultValue="asc"
                  title="Assigned to"
                  type="radio"
                  onChange={(status) =>
                    typeof status === "string"
                      ? setAgentFilter(status === "me" ? "me" : "all")
                      : {}
                  }
                >
                  <MenuItemOption value="me">Me</MenuItemOption>
                  <MenuItemOption value="all">Everyone</MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup
                  title="Status"
                  type="radio"
                  defaultValue={"All"}
                  onChange={(status) =>
                    typeof status === "string" ? setStatusFilter(status) : {}
                  }
                >
                  {["All", "Open", "Pending", "Closed"].map((status, idx) => (
                    <MenuItemOption key={idx} value={status}>
                      {status}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            {/* <IconButton
          size="sm"
          aria-label={"advanced-filters"}
          icon={<TbAdjustmentsHorizontal />}
          onClick={() => onOpen()}
        /> */}
          </ButtonGroup>
          <Menu>
            <MenuButton
              as={ChakraButton}
              size="sm"
              variant="unstyled"
              rightIcon={<BsSortDownAlt />}
            >
              {sortFilter === "desc" ? "Newest" : "Oldest"}
            </MenuButton>
            <MenuList boxShadow="lg">
              <MenuOptionGroup
                type="radio"
                defaultValue={"Newest"}
                onChange={(sort) => {
                  const sortOrder = sort === "Newest" ? "desc" : "asc";
                  setSortFilter(sortOrder);
                  onSortChange?.([
                    { key: "last_user_message", order: sortOrder },
                    { key: "last_message", order: sortOrder },
                  ]);
                }}
              >
                {["Newest", "Oldest"].map((order, idx) => (
                  <MenuItemOption key={idx} value={order}>
                    {order}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </HStack>
      </Stack>
    );
  }
);
