import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { Card, BarList } from "@tremor/react";
import { useTopQueries } from "../../data/analytics/useTopQueries";
import { AnalyticsDataFailed, AnalyticsDataLoading } from ".";

interface TopQueryProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
}

export const TopQueries = ({ flowId, ...props }: TopQueryProps) => {
  const { refetch, isLoading, data, error } = useTopQueries(flowId);

  if (isLoading) {
    return <AnalyticsDataLoading title="Top Queries" />;
  }

  if (error) {
    return (
      <AnalyticsDataFailed
        title="Top Queries"
        errorMessage={"Something went wrong while fetching users data"}
        onRetry={() => refetch()}
      />
    );
  }

  if (data)
    return (
      <Card {...props}>
        <Stack pb={8}>
          <Text flexGrow={1} fontWeight={700}>
            Top Queries
          </Text>
          <BarList
            className="h-48 mt-4"
            data={data.top_queries.map((topQueryStat) => ({
              name: topQueryStat.question,
              value: topQueryStat.count,
              color: "purple"
            }))}
          />
        </Stack>
      </Card>
    );
};
