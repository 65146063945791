import React, { useEffect, useState } from "react";
import { SimpleGrid, Stack } from "@chakra-ui/react";
import { LineChart, Card, Metric, Subtitle, Title } from "@tremor/react";
import { useNavigate, useParams } from "react-router-dom";

import { Appbar } from "../../../components/Appbar";
import {
  getMessageSummary,
  getMessageReplySummary,
  MessageBasicSummary,
  MessageReplySummary,
  AnalyticsConfig,
  getAnalyticsConfig,
  getFlowDrop,
  FlowDropTurn,
} from "../../../api/analytics";
import { Button } from "../../../components/Button";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { getUsersCount } from "../../../api/users";

export const Metrics = () => {
  const { getAuthToken } = useAuthToken();
  const { botId, id } = useParams();
  const navigate = useNavigate();

  const flowId = botId ?? id;

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [config, setConfig] = useState<AnalyticsConfig>(null);
  const [messageData, setMessageData] = useState<MessageBasicSummary>(null);
  const [replyData, setReplyData] = useState<{
    [campaignId: string]: MessageReplySummary;
  }>(null);

  const [messageDensity, setMessageDensity] = useState<number>(0);
  const [replyRate, setReplyRate] = useState<number>(0);
  const [flowDropRate, setFlowDropRate] = useState<FlowDropTurn[]>([]);

  const generateMessageDensity = () => {
    if (!messageData) return;
    const userMessageLength = messageData.text_length_stats?.["user"]?.sum ?? 0;
    const botMessageLength = messageData.text_length_stats?.["bot"]?.sum ?? 0;

    if (userMessageLength === 0 && botMessageLength === 0)
      return setMessageDensity(0);

    let density =
      (userMessageLength / (userMessageLength + botMessageLength)) * 100;
    setMessageDensity(parseFloat(density.toFixed(2)));
  };

  const generateReplyRate = () => {
    if (!replyData) return;

    let currentReplyRate = 0;
    let campaignCount = 0;

    Object.keys(replyData).map((campaignId) => {
      let replyStats = replyData[campaignId];
      let campaignStats = config?.campaigns?.[campaignId] ?? { count: 1 };
      currentReplyRate +=
        (replyStats.reply_time_stats.count / campaignStats.count) * 100;
      campaignCount += 1;
    });

    setReplyRate(parseFloat((currentReplyRate / campaignCount).toFixed(2)));
  };

  const getChartsData = () => {
    let data = flowDropRate
      .filter((dropRate) => dropRate._id <= 20)
      .map((dropRate) => ({
        Turns: dropRate._id,
        Users: dropRate.user,
      }));

    return data;
  };

  useEffect(() => {
    if (["arvind-corp", "ARVIND_RETAIL"].includes(flowId)) {
      navigate(`/dashboard/${flowId}/analytics/dhwani`, { replace: true });
    }

    if (flowId.toLowerCase().includes("hypd")) {
      navigate(`/dashboard/${flowId}/analytics/hypd`, { replace: true });
    }
  }, [flowId]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchAnalyticsConfig = async (token: string) => {
      setLoading(true);
      const configData = await getAnalyticsConfig({
        flowId,
        token,
        abortController: abortController,
      });
      setLoading(false);

      if (configData) setConfig(configData);
    };

    const fetchFlowDrop = async (token: string) => {
      setLoading(true);
      const result = await getFlowDrop({ flowId, token, abortController });
      setLoading(false);

      if (result.length !== 0) {
        for (let index = result.length - 1; index > 0; index--) {
          const element = result[index - 1];
          result[index - 1] = {
            ...result[index - 1],
            user: element.user + result[index].user,
          };
        }

        setFlowDropRate(result);
      }
    };

    const fetchTotalUsers = async (token: string) => {
      const count = await getUsersCount({
        flowId,
        token,
        abortController,
      }).catch((_) => {
        setCount(0);
      });

      if (count) {
        setCount(count?.["count"] ?? 0);
      } else setCount(0);
    };

    const fetchAnalyticsInfo = async () => {
      const token = localStorage.getItem('token')
      Promise.all([
        fetchAnalyticsConfig(token),
        fetchFlowDrop(token),
        fetchTotalUsers(token),
      ]);
    };

    fetchAnalyticsInfo();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const fetchMessageData = async () => {
      if (!config) return;
      setLoading(true);
      const stream = await getMessageSummary({
        flowId,
        from: config.first_message_timestamp,
      });

      if (!stream) return setLoading(false);

      const reader = stream.getReader();

      let doc = await reader.read();

      while (!doc.done) {
        const data = doc.value;
        if (data) {
          setMessageData(data as MessageBasicSummary);
        }
        doc = await reader.read();
      }
      setLoading(false);
    };

    const fetchMessageRepliesData = async (campaignId: string) => {
      setLoading(true);

      const stream = await getMessageReplySummary({
        flowId,
        campaignId,
      });

      if (!stream) return setLoading(false);

      const reader = stream.getReader();
      let doc = await reader.read();

      while (!doc.done) {
        const data = doc.value;
        if (data) {
          setReplyData((replyData) => ({
            ...replyData,
            [campaignId]: data as MessageReplySummary,
          }));
        }
        doc = await reader.read();
      }

      setLoading(false);
    };

    const fetchFlowReplyRate = async () => {
      if (!config) return;

      let promises: Promise<void>[] = [];

      Object.keys(config.campaigns).map((campaignId, idx) => {
        promises.push(fetchMessageRepliesData(campaignId));
      });

      await Promise.all(promises);
    };

    fetchMessageData();
    fetchFlowReplyRate();
  }, [config]);

  useEffect(() => {
    generateMessageDensity();
  }, [messageData]);

  useEffect(() => {
    generateReplyRate();
  }, [replyData]);

  return (
    <Stack className="max-h-screen overflow-y-scroll">
      <Appbar
        px={8}
        title="Analytics"
        isLoading={loading}
        borderBottomWidth="thin"
        boxShadow="sm"
        trailing={<Button visibility="hidden">New</Button>}
        variant="sticky"
      />
      <SimpleGrid
        py={4}
        px={8}
        gridGap={4}
        templateColumns="repeat(3, minmax(0, 1fr))"
      >
        <Card key={"message_density"}>
          <Subtitle>Message Density </Subtitle>
          <Metric>{messageDensity}%</Metric>
        </Card>
        <Card key={"reply_rate"}>
          <Subtitle>Reply Rate</Subtitle>
          <Metric>{replyRate}%</Metric>
        </Card>
        <Card key={"total_users_count"}>
          <Subtitle>Total Unique Users</Subtitle>
          <Metric>{count.toLocaleString()}</Metric>
        </Card>
      </SimpleGrid>
      <Stack py={4} px={8}>
        <Card>
          <Title>User Retention</Title>
          <LineChart
            className="mt-6 w-full h-64"
            data={getChartsData()}
            index="Turns"
            colors={["purple"]}
            categories={["Users"]}
            noDataText={
              loading
                ? "Please wait while we load user retention"
                : "No data available"
            }
          />
          <Subtitle className="self-center text-center">
            {!loading && "No of turns"}
          </Subtitle>
        </Card>
      </Stack>
    </Stack>
  );
};
