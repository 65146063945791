import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";

import { FlowDropTurn } from "../../api/analytics";
import config from "../../config";

export const useDropOff = (
  flowId: string,
  dateRange: { start_date: number; end_date: number }
) => {
  const fetchWithAuth = useFetchWithAuth();

  const rangeKey =
    typeof dateRange === "string" ? dateRange : `${dateRange.start_date}-${dateRange.end_date}`;

  const fetchDropOff = async () => {
    const res = await fetchWithAuth<FlowDropTurn[]>({
      url: `${
        config.url
      }/v2/analytics/dropoff/${flowId}?date_range=${JSON.stringify(dateRange)}`,
    });

    return res;
  };

  return useQuery(["dropoff", flowId, rangeKey], fetchDropOff, {
    refetchInterval: 1000 * 30 * 60, // 30 mins
  });
};
