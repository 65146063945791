"use client";

import { Progress, ProgressProps } from "@chakra-ui/react";
import React, { useState, useEffect, forwardRef } from "react";

const ForeverProgress = forwardRef<HTMLDivElement, ProgressProps>(
  (props, ref) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setProgress((currentProgress) => {
          let progressIncrement = 0.8;
          if (currentProgress > 80) {
            progressIncrement = (100 - currentProgress) / 500;
          } else if (currentProgress > 60) {
            progressIncrement = (100 - currentProgress) / 250;
          } else if (currentProgress > 40) {
            progressIncrement = 0.4;
          }
          return currentProgress + progressIncrement;
        });
      }, 50);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return <Progress ref={ref} value={progress} {...props} />;
  }
);

export default ForeverProgress;
