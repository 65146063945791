import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  HStack,
  SimpleGrid,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { ConversationComponent, PromptMessage } from ".";
import { TextField } from "../../../components/TextField";
import { Button } from "../../../components/Button";
import { AddIcon, CloseIcon } from "../../../constants/icons";
import { PromptMessageTurn } from "./Example";
import { colorSystem } from "../../../theme";

export const RenderConversationComponent = ({
  component,
  ...props
}: {
  component: ConversationComponent;
  onChange?: (component: ConversationComponent) => void;
  onRemove?: () => void;
}) => {
  const [conversationComponent, setConversationComponent] =
    useState<ConversationComponent>(component);

  useEffect(() => {
    props.onChange?.(conversationComponent);
  }, [conversationComponent]);

  return (
    <Box px={4}>
      <HStack justify="space-between">
        <Input
          w="32"
          size="xs"
          placeholder="Component Name"
          bg={`${colorSystem["inputGrey"]} !important`}
          value={conversationComponent.name}
          onChange={(event) =>
            setConversationComponent({
              ...conversationComponent,
              name: event.target.value,
            })
          }
        />
        <IconButton
          size="xs"
          aria-label="remove component"
          icon={<CloseIcon />}
          bg="transparent !important"
          borderWidth="thin"
          onClick={() => props.onRemove?.()}
        />
      </HStack>
      <Stack
        px={4}
        py={8}
        bg="#EDEBFE"
        columnGap={8}
        borderRadius="lg"
        boxShadow="md"
        borderWidth="thin"
        borderColor="gray.400"
        spacing={2}
      >
        <TextField
          size="sm"
          value={conversationComponent.goal}
          placeholder={"Goal: convince users to buy your product"}
          onChange={(event) =>
            setConversationComponent({
              ...conversationComponent,
              goal: event.target.value,
            })
          }
          bg="white !important"
          borderColor="blackAlpha.100"
        />
        <SimpleGrid
          columnGap={1}
          templateColumns="repeat(3, 1fr)"
          borderRadius="md"
        >
          {conversationComponent.examples.map((example, exampleIndex) => {
            return (
              <Stack
                key={`example${exampleIndex}`}
                spacing={0}
                borderWidth="thin"
                borderRadius="md"
                bg="white"
              >
                <TextField
                  size="sm"
                  value={example.context}
                  variant="unstyled"
                  placeholder={
                    exampleIndex === 0
                      ? "Context: What the user is thinking"
                      : ""
                  }
                  onChange={(event) => {
                    setConversationComponent((prevState) => ({
                      ...prevState,
                      examples: prevState.examples.map(
                        (example, prevExampleIndex) => {
                          if (exampleIndex === prevExampleIndex) {
                            example.context = event.target.value;
                          }

                          return example;
                        }
                      ),
                    }));
                  }}
                  bg="transparent !important"
                  border="none"
                />
                {example.turns.map((message, turnIndex) => (
                  <PromptMessageTurn
                    key={`example${exampleIndex}turn${turnIndex}}`}
                    message={message}
                    onChange={(message) => {
                      // updates the message
                      setConversationComponent((prevState) => ({
                        ...prevState,
                        examples: prevState.examples.map(
                          (example, prevExampleIndex) => {
                            if (exampleIndex === prevExampleIndex)
                              example.turns[turnIndex] = message;

                            return example;
                          }
                        ),
                      }));
                    }}
                    onRemove={() => {
                      // removes the message
                      setConversationComponent((prevState) => ({
                        ...prevState,
                        examples: prevState.examples.map(
                          (example, prevExampleIndex) => {
                            if (exampleIndex === prevExampleIndex) {
                              example.turns.splice(turnIndex, 1);
                            }

                            return example;
                          }
                        ),
                      }));
                    }}
                  />
                ))}

                <Button
                  py={4}
                  size="xs"
                  variant="tertiary"
                  bg="purple.50"
                  leftIcon={<AddIcon />}
                  onClick={() => {
                    // adds a new message to the example
                    setConversationComponent((prevState) => ({
                      ...prevState,
                      examples: prevState.examples.map(
                        (example, prevExampleIndex) => {
                          if (exampleIndex === prevExampleIndex) {
                            let newTurns: PromptMessage[] = [
                              { type: "message", role: "user", content: "" },
                              {
                                type: "thoughts",
                                role: "assistant",
                                content: "",
                              },
                            ];

                            example.turns = example.turns.concat(newTurns);
                            console.log(
                              `updated turns to ${example.turns.length}`
                            );
                          }

                          return example;
                        }
                      ),
                    }));
                  }}
                >
                  example
                </Button>
              </Stack>
            );
          })}
        </SimpleGrid>
        <TextField
          size="sm"
          value={conversationComponent.extract_instruction}
          placeholder={"Extract: What you need to extract from these turns"}
          onChange={(event) =>
            setConversationComponent({
              ...conversationComponent,
              extract_instruction: event.target.value,
            })
          }
          bg="white !important"
          borderColor="blackAlpha.100"
        />
      </Stack>
    </Box>
  );
};
