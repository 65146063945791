import React, { useState } from "react";
import { StackProps, Stack, ButtonGroup, HStack, Text } from "@chakra-ui/react";
import { getTimeAgo } from "../InfiniteTable";
import { useAuthToken } from "../../hooks/useAuthToken";
import { UserNote } from "../../data/user/useUserNotes";
import { Button } from "../Button";
import { useDeleteUserNote } from "../../mutations/users/useDeleteNote";
import { useUpdateNote } from "../../mutations/users/useUpdateNote";
import { TextField } from "../TextField";

interface UserNoteTileProps extends StackProps {
  note: UserNote;
}

export const UserNoteTile = ({ note, ...props }: UserNoteTileProps) => {
  const [text, setText] = useState(note.text);
  const [isEditing, setIsEditing] = useState(false);
  const {
    user: { email: currentUserMail },
  } = useAuthToken();

  const deleteNote = useDeleteUserNote({
    flowId: note.flow_id,
    refetchKey: ["user", "notes"],
  });

  const editNote = useUpdateNote(["user", "notes"]);

  const updateNote = () => {
    editNote.mutate({
      updatedText: text,
      noteId: note._id,
      flowId: note.flow_id,
    });
    setIsEditing(false);
    setText("");
  };

  // handle enter key press
  const _handleKeyDown = (key: string, allowNewLine: boolean) => {
    // when shift key is pressed allow new line
    if (allowNewLine) return;
    if (key === "Enter" && !editNote.isLoading) updateNote();
  };

  return (
    <Stack w="full" spacing={1} {...props}>
      <HStack justify="space-between">
        <Text className="text-sm font-bold">{note.author}</Text>
        <Text color="gray.500" fontSize="xs">
          {getTimeAgo(note.timestamp, true)}
        </Text>
      </HStack>
      {isEditing ? (
        <TextField
          size="sm"
          minH={24}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(event) => _handleKeyDown(event.key, event.shiftKey)}
          placeholder="add a note"
        />
      ) : (
        <Text>{note.text}</Text>
      )}
      {isEditing ? (
        <ButtonGroup colorScheme="pink">
          <Button
            isLoading={editNote.isLoading}
            variant="tertiary"
            size="xs"
            isDisabled={text === note.text}
            onClick={(e) => updateNote()}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            size="xs"
            onClick={(e) => setIsEditing(false)}
          >
            Cancel
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          colorScheme="pink"
          visibility={note.author === currentUserMail ? "visible" : "hidden"}
        >
          <Button
            variant="tertiary"
            size="xs"
            onClick={(e) => {
              setIsEditing(true);
              setText(note.text);
            }}
          >
            Edit
          </Button>
          <Button
            askConfirmation
            confirmText="Are you sure you want to delete this?"
            aria-label={"remove"}
            size="xs"
            confirmAction="delete"
            confirmButton="Delete"
            isLoading={deleteNote.isLoading}
            action="delete"
            variant="tertiary"
            onClick={() => deleteNote.mutate(note._id)}
          >
            Delete
          </Button>
        </ButtonGroup>
      )}
    </Stack>
  );
};
