import React from "react";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Card, AreaChart } from "@tremor/react";

import { AnalyticsDataFailed, AnalyticsDataLoading, formatDate } from ".";
import { UserStat, useLiveUsers } from "../../data";

const parseLiveUsers = (liveUsers: UserStat[]): UserStat[] => {
  if (!liveUsers) return [];

  if (liveUsers.length === 0) return [];

  return liveUsers.map(({ Users, Timestamp }) => ({
    type: "data",
    Users: Users,
    Timestamp: formatDate(Timestamp),
  }));
};

interface LiveUserProps extends React.HTMLAttributes<HTMLDivElement> {
  flowId: string;
  
  // Range 
  range: { start_date: number; end_date: number } | string;
}

export const LiveUsers = ({ flowId, range, ...props }: LiveUserProps) => {
  const { refetch, isLoading, data } = useLiveUsers(flowId, range);

  if (isLoading) {
    return <AnalyticsDataLoading title="Total New Users" />;
  }

  if (data && !Array.isArray(data)) {
    if (data.type === "error") {
      return (
        <AnalyticsDataFailed
          title="Total New Users"
          errorMessage={"Something went wrong while fetching users data"}
          onRetry={() => refetch()}
        />
      );
    }

    return <AnalyticsDataLoading title="Total New Users" />;
  }

  if (data && Array.isArray(data))
    return (
      <Card {...props}>
        <Stat>
          <StatLabel>Total New Users</StatLabel>
          <StatNumber>
            {(data?.reduce((sum, a) => sum + a.Users, 0) ?? 0).toLocaleString()}
          </StatNumber>
        </Stat>
        <AreaChart
          className="h-48 mt-4"
          data={parseLiveUsers(data)}
          index="Timestamp"
          categories={["Users"]}
          colors={["purple"]}
          noDataText={
            isLoading
              ? "Please wait while we users in this time range"
              : "No users in this time range"
          }
        />
      </Card>
    );
};
