import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverFooter,
  useDisclosure,
  Box,
  Stack,
  BoxProps,
  Tooltip,
  Portal,
} from "@chakra-ui/react";
import { DayPicker, DateRange } from "react-day-picker";
import { formatDashboardDate } from "../../utils/helpers";
import { DateIcon } from "../../constants/icons";
import { Button } from "../Button";
import "react-day-picker/dist/style.css";

interface DatePickerProps extends BoxProps {
  title?: string;
  date: DateRange;
  mode?: "single" | "range";
  variant?: "primary" | "secondary" | "tertiary";
  onDateChange?: (date: DateRange) => void;
  onDateSelected?: (date: DateRange) => void;
  onDateClear?: () => void;
  size?: "sm" | "md" | "lg" | "xs";
}

export const DatePicker = ({
  title = "select date",
  date,
  size = "sm",
  mode = "range",
  ...props
}: DatePickerProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedDay, setSelectedDay] = useState<DateRange | null>(date);

  useEffect(() => {
    props.onDateChange?.(selectedDay);
  }, [selectedDay]);

  const renderSelectedDate = () => {
    let fromFormatted = "";

    if (!selectedDay) return title;

    if (!selectedDay.from) return title;

    if (selectedDay.from) {
      fromFormatted = `${formatDashboardDate(selectedDay.from.toString())}`;
    }

    if (selectedDay.to) {
      let toFormatted = `${formatDashboardDate(selectedDay.to.toString())}`;
      return `${fromFormatted} - ${toFormatted}`;
    }

    return fromFormatted;
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      trigger="click"
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Stack spacing={0}>
          {props.children ? (
            <Box {...props} onClick={onOpen}>
              <Tooltip label={renderSelectedDate()}>{props.children}</Tooltip>
            </Box>
          ) : (
            <Button
              variant={props.variant}
              size={size}
              onClick={onOpen}
              leftIcon={<DateIcon />}
              style={props.style}
            >
              {renderSelectedDate()}
            </Button>
          )}
        </Stack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          className="border border-[#F2F4F7] rounded-md p-0"
          boxShadow="0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)"
          zIndex="9999"
        >
          <PopoverBody>
            {mode === "single" ? (
              <DayPicker
                mode="single"
                selected={selectedDay.from}
                onSelect={(range) => setSelectedDay({ from: range })}
              />
            ) : (
              <DayPicker
                mode="range"
                selected={selectedDay}
                onSelect={setSelectedDay}
              />
            )}
          </PopoverBody>
          <PopoverFooter>
            <Button
              onClick={() => {
                props.onDateClear?.();
                setSelectedDay({ from: null, to: null });
                onClose();
              }}
              variant="tertiary"
            >
              Clear
            </Button>
            <Button
              colorScheme="pink"
              onClick={() => {
                props.onDateSelected?.(selectedDay);
                onClose();
              }}
            >
              Apply
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
