import React, { useState } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Wrap,
  Image,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  AddIcon,
  SearchIcon,
  CloseIcon,
  ExportIcon,
} from "../../../../../constants/icons";
import { useSupportUsers } from "../../../../../data/support/useSupportUsers";
import { Button } from "../../../../../components/Button";
import { NewSupportUser } from "./NewSupportUser";
import StatusTag from "../../../../../components/Status";
import emptyImageSrc from "../../../../../img/empty.svg";

export const Members = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;

  const { isLoading, data: members } = useSupportUsers(flowId);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [searchQuery, setSearchQuery] = useState("");

  const filteredMembers = members
    ? members.filter((member) => {
        const filterList = [member.email];
        if (member.name) filterList.push(member.name);
        return filterList.some((question) =>
          question.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    : [];

  return (
    <Stack className="flex-1 px-8 py-4 h-screen">
      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="md">
            Teams
          </Heading>
          <Text>Manage your members</Text>
        </Box>
        <Button leftIcon={<AddIcon />} onClick={() => onOpen()}>
          Import Users
        </Button>
      </HStack>
      <Divider py={2} />
      <HStack pt={4} justify="space-between">
        <InputGroup size="sm" maxW="sm">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            placeholder="Search for users..."
          />
          <InputRightElement>
            {searchQuery.length !== 0 && (
              <CloseIcon onClick={() => setSearchQuery("")} />
            )}
          </InputRightElement>
        </InputGroup>
        <Button hidden leftIcon={<ExportIcon />} variant="tertiary">
          Export
        </Button>
      </HStack>
      <TableContainer
        boxShadow="sm"
        borderWidth="thin"
        borderRadius="md"
        maxH="xl"
        overflowY="scroll"
      >
        <Stack
          py={8}
          spacing={0}
          h="full"
          justify="center"
          align="center"
          hidden={filteredMembers.length !== 0}
        >
          <Image src={emptyImageSrc} />
          <Text className="text-base text-[#1D2939]">No members</Text>
        </Stack>
        <Table hidden={filteredMembers.length === 0} variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Team</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredMembers.map((member) => (
              <Tr key={member.email}>
                <Td>{member.name ?? member.email}</Td>
                <Td>{member.email}</Td>
                <Td>
                  <Wrap>
                    {member.groups.map((group, idx) => (
                      <StatusTag
                        key={`${member.email}_${group}_${idx}`}
                        w="min-content"
                        tag={group}
                      />
                    ))}
                  </Wrap>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <NewSupportUser flowId={flowId} isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};
