import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import { PaginationConfig } from "../../models";
import { SupportUser } from "../../models/support";
import config from "../../config";

export const useSupportUsers = (
  flowId: string,
  pagination?: PaginationConfig
) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchSupportUsers = async () => {
    const res = await fetchWithAuth<SupportUser[]>({
      url: `${config.url}/v2/support/${flowId}/users`,
      //   config: {
      //     params: {
      //       pagination: JSON.stringify(pagination),
      //     },
      //   },
    });

    return res;
  };

  return useQuery(["support", "users", flowId], fetchSupportUsers, {
    keepPreviousData: true,
    refetchInterval: 1000 * 30, // 30 seconds
  });
};
